import styles from "./style.module.scss";

const SettingArea = ({ settingEnum }) => {
  return (
    <div className={styles.settingContainer}>
      {settingEnum.map((item, index) => {
        return (
          <div key={index} className={styles.setting}>
            <div className={styles.label}>{item.label}</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SettingArea;
