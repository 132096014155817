import { useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetProject, apiGetProjectDetail, apiPostBuy } from "../service";

export const useGetProject = ({ options, params }) => {
  return useQuery([queryKey.PROJECT], () => apiGetProject(params).then((res) => res.data.data), {
    ...options,
  });
};

export const useGetProjectDetail = ({ options, params }) => {
  return useQuery(
    [queryKey.PROJECT_DETAIL],
    () => apiGetProjectDetail(params).then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const usePostBuy = () => {
  return useMutation(({ params }) => apiPostBuy({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
