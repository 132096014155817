import { InfiniteScroll } from "antd-mobile";
import InfiniteScrollContent from "../InfiniteScrollContent";
import ProjectCard from "./components/ProjectCard";
import styles from "./style.module.scss";
import "./style.scss";

const ProjectCards = ({
  data,
  describes,
  alreadyBuy,
  showReturn,
  showDivider,
  showFooterDivider,
  showViewDetail,
  clickDetail = () => {},
  loadMore = null,
  hasMore,
  style,
}) => {
  return (
    <div className={`${styles.projectContainer} project-container`}>
      {data &&
        data.map((item, index) => {
          return (
            <ProjectCard
              data={item}
              key={index}
              describes={describes}
              alreadyBuy={alreadyBuy}
              showReturn={showReturn}
              showDivider={showDivider}
              showFooterDivider={showFooterDivider}
              showViewDetail={showViewDetail}
              clickDetail={clickDetail}
              style={{ ...style }}
            />
          );
        })}
      {loadMore && (
        <InfiniteScroll threshold={20} loadMore={loadMore} hasMore={hasMore}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ProjectCards;
