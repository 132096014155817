import styles from "./style.module.scss";
import "./style.scss";

import { Card, Divider } from "antd";
import { RightOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMobile } from "../../../../context/MobileContext";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import CustomButton from "../../../CustomButton";
import ProjectCardDescribe from "../../../ProjectCardDescribe";
import Affiliate from "../Affiliate";
import Progress from "../Progress";
import TimerCountDown from "../TimerCountDown";

const ProjectCard = ({
  data,
  describes,
  alreadyBuy = false,
  showReturn = false,
  showDivider = false,
  showFooterDivider = false,
  showViewDetail = false,
  clickDetail = () => {},
  style = {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();

  const buttonEnum = {
    0: { key: "soldOut", disabled: true, text: t("project.card.soldOut") },
    1: { key: "buyNow", disabled: false, text: t("project.card.buyNow") },
  };

  const handleClick = () => {
    if (alreadyBuy) {
      clickDetail(data);
    } else {
      navigate(`/project/${data.id}`);
    }
  };

  const renderFooter = () => {
    if (alreadyBuy) {
      if (showReturn) {
        return (
          <div className={styles.returnFooter} onClick={handleClick}>
            <div className={styles.text}>
              {t("project.return", {
                amount:
                  moneyFormat(data?.buy_money) +
                  t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
              })}
            </div>
            <RightOutline className={styles.rightIcon} />
          </div>
        );
      } else {
        return (
          <div className={styles.footer} onClick={isMobile ? handleClick : null}>
            <div className={styles.text}>
              {t("project.detail.hint2", { day: data?.cycle / 24 })}
            </div>
            {isMobile ? (
              <RightOutline className={styles.rightIcon} />
            ) : (
              <CustomButton
                buttonStyle={`${styles.customButtonStyle} ${styles.viewDetail}`}
                onClick={handleClick}
              >
                {t("project.card.viewDetail")}
              </CustomButton>
            )}
          </div>
        );
      }
    } else {
      return (
        <>
          <div className={styles.buyFooter}>
            <div className={styles.action}>
              {data?.end === 1 && <TimerCountDown data={data} />}
              {showViewDetail && (
                <CustomButton
                  buttonStyle={`${styles.customButtonStyle} ${styles.viewDetail}`}
                  onClick={handleClick}
                  disabled={buttonEnum[data?.is_buy].disabled}
                >
                  {t("project.card.viewDetail")}
                </CustomButton>
              )}
              <CustomButton
                buttonStyle={`${styles.customButtonStyle} `}
                onClick={handleClick}
                disabled={buttonEnum[data?.is_buy].disabled}
              >
                {buttonEnum[data?.is_buy].text}
              </CustomButton>
            </div>
            <Progress data={data} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="preferred-project">
      <Card
        cover={<img alt={data.id} src={data.pictureimage} className="card-cover" />}
        style={{ ...style }}
      >
        <div className="name">{data.name}</div>
        <ProjectCardDescribe
          data={data}
          describes={describes}
          style={{ padding: isMobile ? "0 18px" : "0 10px" }}
          showDivider={showDivider}
          alreadyBuy={alreadyBuy}
          showReturn={showReturn}
        />
        {!alreadyBuy && <Affiliate data={data} />}
        <div className={styles.cardFooter}>
          {showFooterDivider && <Divider className={styles.divider} />}
          <div className={styles.footerContainer}>{renderFooter()}</div>
        </div>
      </Card>
    </div>
  );
};

export default ProjectCard;
