import { ProForm } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  SettingArea,
  toast,
} from "../../../../../../components";
import { useModal } from "../../../../../../context/ModalContext";
import { channelEnum } from "../../../../../../enumerations/channelEnum";
import { apiCodeEnum } from "../../../../../../enumerations/signInUpEnum";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import { checkInputHasNull, envChangeType, reset } from "../../../../../../plugins/utils";
import { usePostRecharge } from "../../../../hooks/index.hook";
import styles from "./style.module.scss";

const Recharge = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();
  const { openTipsModal } = useModal();
  const [disabled, setDisabled] = useState(true);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [targetChain, setTargetChain] = useState({});
  const [valueEnum, setValueEnum] = useState({});

  const { mutate: recharge, isLoading } = usePostRecharge();

  const settingEnum = [
    {
      label: t("label.limit"),
      value: t("wallet.recharge.limit", {
        min: moneyFormat(data?.min_money || 0),
        max: moneyFormat(9999999),
        currency: t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
      }),
    },
    {
      label: t("label.handlingFee"),
      value: `${0}%`,
    },
    {
      label: t("label.payable"),
      value: `${currentAmount} ${t(
        `currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`,
      )}`,
    },
    {
      label: t("label.conversionRate"),
      value: t("wallet.recharge.rate", {
        rate: targetChain?.ratio,
        currency: t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
        transformCurrency: targetChain?.name,
      }),
    },
    {
      label: `≈ ${targetChain?.name}`,
      value: moneyFormat(currentAmount * targetChain?.ratio, 6, 6),
    },
  ];

  const handleChangeInput = (value = 0) => {
    checkInputHasNull(formRef, setDisabled);
    setCurrentAmount(value);
  };

  const handleChangeGateway = (value) => {
    const targetChain = data.data.find((item) => item.name === value);
    setTargetChain(targetChain);
  };

  const handleFinish = () => {
    const params = formRef.current.getFieldsValue();
    const form = new FormData();
    form.append("cid", targetChain.cid);
    form.append("number", params.number);

    recharge(
      {
        params: form,
      },
      {
        onSuccess: (success) => {
          const { code, msg, data } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          reset(formRef);
          switch (data?.type) {
            case 1:
              navigate(`/deposit/${data?.sn}`, { state: data, replace: true });
              return;
            case 2:
              window.location.href = data?.url;
              return;
            default:
              return;
          }
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    data.data.map((item, index) => {
      setValueEnum((prev) => {
        return {
          ...prev,
          [item.name]: (
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <img src={channelEnum[item.name]?.src} alt="logo" width={20} />
              {item.name}
            </div>
          ),
        };
      });
      if (index === 0) {
        formRef.current.setFieldValue("gateway", item.name);
        setTargetChain(item);
      }
      return item;
    });
  }, [data]);

  return (
    <div className={styles.recharge}>
      <NormalTitle title={t("normal.recharge")} />
      <NormalContainer>
        <ProForm
          formRef={formRef}
          onFinish={handleFinish}
          submitter={{
            render: () => (
              <>
                <CustomButton
                  buttonStyle={`${styles.customButtonStyle}`}
                  type={"submit"}
                  disabled={disabled}
                  loading={isLoading}
                >
                  {t("normal.submit")}
                </CustomButton>
                <div
                  className={styles.hint}
                  dangerouslySetInnerHTML={{
                    __html: t("deposit.hint", {
                      currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
                      min: data?.min_money,
                    }),
                  }}
                />
              </>
            ),
          }}
          className="recharge-from"
        >
          <div className={styles.selectContainer}>
            <CustomInput
              type="select"
              name="gateway"
              label={t("label.gateway")}
              placeholder={t("normal.pleaseEnter", { label: t("label.withdrawAddress.currency") })}
              valueEnum={valueEnum}
              onChange={handleChangeGateway}
            />
            <div className={styles.extra}>{t("select.extra")}</div>
          </div>
          <CustomInput
            label={t("label.rechargeAmount")}
            name="number"
            placeholder={t("normal.pleaseEnter", { label: t("label.rechargeAmount") })}
            type="number"
            onChange={handleChangeInput}
            addonAfter={t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}
          />
          <SettingArea settingEnum={settingEnum} />
        </ProForm>
      </NormalContainer>
    </div>
  );
};

export default Recharge;
