import { ProForm } from "@ant-design/pro-components";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  toast,
} from "../../../../components";
import { useModal } from "../../../../context/ModalContext";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum";
import { usePostPaymentPassword } from "../../hooks/index.hook";
import styles from "./style.module.scss";

const PaymentPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();
  const { openTipsModal } = useModal();

  const { mutate: updatePaymentPassword, isLoading } = usePostPaymentPassword();

  const handleFinish = () => {
    const params = formRef.current.getFieldsValue();

    updatePaymentPassword(
      {
        ...params,
      },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          setTimeout(() => {
            localStorage.removeItem(localStorageKey.TOKEN);
            navigate("/");
          }, 2000);
        },
        onError: () => {},
      },
    );
  };

  return (
    <div className={styles.paymentPassword}>
      <NormalTitle title={t("label.paymentPassword")} />
      <NormalContainer>
        <ProForm
          formRef={formRef}
          submitter={{
            render: () => (
              <CustomButton
                buttonStyle={`${styles.customButtonStyle}`}
                type={"submit"}
                loading={isLoading}
              >
                {t("normal.confirmChanges")}
              </CustomButton>
            ),
          }}
          onFinish={handleFinish}
          className={styles.form}
        >
          <CustomInput
            name="original"
            label={t("user.paymentPassword.oldPassword")}
            placeholder={t("normal.pleaseEnter", { label: t("user.paymentPassword.oldPassword") })}
            type="password"
          />
          <CustomInput
            name="new"
            label={t("user.paymentPassword.newPassword")}
            placeholder={t("normal.pleaseEnter", { label: t("user.paymentPassword.newPassword") })}
            type="password"
          />
          <CustomInput
            name="confirm"
            label={t("user.paymentPassword.confirmPassword")}
            placeholder={t("normal.pleaseEnter", {
              label: t("user.paymentPassword.confirmPassword"),
            })}
            type="password"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("normal.paymentPassword.error")));
                },
              }),
            ]}
          />
        </ProForm>
      </NormalContainer>
    </div>
  );
};

export default PaymentPassword;
