import { InfiniteScroll } from "antd-mobile";
import { useTranslation } from "react-i18next";
import InfiniteScrollContent from "../../../InfiniteScrollContent";
import styles from "./style.module.scss";

const RecordCard = ({ localData, loadMore, hasMore, columns }) => {
  const { t } = useTranslation();

  const renderCard = (data, index) => {
    return columns.map((column, _index) => {
      return (
        <div key={_index} className={styles.row}>
          <div className={styles.label}>{t(column.title)}</div>
          <div className={styles.value}>
            {column.render
              ? column.render(data[column.dataIndex], data, index)
              : data[column.dataIndex]}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {localData.map((data, index) => {
        return (
          <div className={styles.singleData} key={index}>
            {renderCard(data, index)}
          </div>
        );
      })}
      <InfiniteScroll threshold={20} loadMore={loadMore} hasMore={hasMore}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </>
  );
};

export default RecordCard;
