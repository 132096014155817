import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomRadio } from "../../../../components";
import { Gift, Record } from "./components";
import styles from "./style.module.scss";

const GiftAndRecord = () => {
  const { t } = useTranslation();
  const items = [
    { value: "gift", text: t("normal.gift") },
    { value: "record", text: t("normal.record") },
  ];
  const [radioValue, setRadioValue] = useState(items[0].value);

  const renderContent = () => {
    switch (radioValue) {
      case "gift":
        return <Gift />;
      case "record":
        return <Record />;
      default:
        return <Gift />;
    }
  };

  return (
    <div className={styles.giftAndRecord}>
      <div className={styles.radio}>
        <CustomRadio
          items={items}
          defaultValue={items[0].value}
          value={radioValue}
          onClick={(e) => setRadioValue(e.target.value)}
        />
      </div>
      {renderContent()}
    </div>
  );
};

export default GiftAndRecord;
