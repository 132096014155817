import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomTable, NormalTitle, RecordCards } from "../../../../../../components";
import { useMobile } from "../../../../../../context/MobileContext";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import { envChangeType, formatDate } from "../../../../../../plugins/utils";
import styles from "./style.module.scss";

const Details = ({
  data,
  isDividend,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  item,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  const columns = [
    {
      title: t("label.txid").toUpperCase(),
      dataIndex: "sn",
      key: "sn",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("label.transacted").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => formatDate(dom),
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "money",
      dataIndex: "money",
      render: (dom) =>
        `+ ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
          dom,
        )}`,
    },
    // {
    //   title: t("label.postBalance").toUpperCase(),
    //   key: "money",
    //   dataIndex: "money",
    //   render: (dom) =>
    //     `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(dom)}`,
    // },
    {
      title: t("label.walletType").toUpperCase(),
      dataIndex: "memo",
      key: "memo",
    },
  ];

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <div className={styles.detail}>
      <NormalTitle title={t("nav.transactions")} />
      {isMobile ? (
        <RecordCards
          data={data}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          columns={columns}
        />
      ) : (
        <>
          <CustomTable columns={columns} data={data} setCurrentPage={setCurrentPage} />
        </>
      )}
    </div>
  );
};

export default Details;
