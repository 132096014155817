import styles from "./style.module.scss";

const TradingView = () => {
  return (
    <iframe
      title="tradingView"
      src={`https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en&random=${Math.random()}#%7B"symbols"%3A%5B%7B"proName"%3A"BITSTAMP%3ABTCUSD"%2C"title"%3A"Bitcoin"%7D%2C%7B"proName"%3A"BITSTAMP%3AETHUSD"%2C"title"%3A"Ethereum"%7D%2C%7B"proName"%3A"BITSTAMP%3ADOGEUSD"%2C"title"%3A"Dogecoin"%7D%2C%7B"proName"%3A"BITSTAMP%3ALTCUSD"%2C"title"%3A"Litecoin"%7D%2C%7B"proName"%3A"BITSTAMP%3ABCHUSD"%2C"title"%3A"Bitcoin%20Cash"%7D%2C%7B"proName"%3A"BITSTAMP%3AADAUSD"%2C"title"%3A"Cardano"%7D%2C%7B"proName"%3A"BITSTAMP%3AXRPUSD"%2C"title"%3A"XRP"%7D%5D%2C"showSymbolLogo"%3Atrue%2C"colorTheme"%3A"dark"%2C"isTransparent"%3Afalse%2C"displayMode"%3A"adaptive"%2C"width"%3A"100%25"%2C"height"%3A78%2C"utm_source"%3A"globalecrypto.com"%2C"utm_medium"%3A"widget_new"%2C"utm_campaign"%3A"ticker-tape"%2C"page-uri"%3A"ljmining.com%2F"%7D`}
      seamless="seamless"
      frameBorder="0"
      className={`${styles.iframe}`}
    />
  );
};

export default TradingView;
