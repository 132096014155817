import { ProForm } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomButton, CustomInput, NormalRadio, toast } from "../../../../components";
import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context/ModalContext";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum";
import { checkInputHasNull } from "../../../../plugins/utils";
import { usePostLogin } from "../../hooks/index.hook";
import { delCookie, getCookie, setCookie } from "../../utils/cookie";
import styles from "./style.module.scss";

const SignInForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();
  const { openTipsModal } = useModal();
  const { setToken } = useAuth();
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const { mutate: login } = usePostLogin();

  const handleFinish = () => {
    const params = formRef.current.getFieldsValue();
    const form = new FormData();
    form.append("mobile", params.mobile);
    form.append("account", params.mobile);
    form.append("password", params.password);

    login(
      { params: form },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          if (checked) {
            let accountInfo = params.mobile + "&" + params.password;
            setCookie("accountInfo", accountInfo);
          } else {
            delCookie("accountInfo");
          }
          toast({ content: msg, type: "success" });
          setToken(success?.data?.data?.userinfo.token);
          window.location.href = "/dashboard";
        },
        onError: (error) => {
          console.error(error);
        },
      },
    );
  };

  useEffect(() => {
    let accountInfo = getCookie("accountInfo");
    if (Boolean(accountInfo) === false) {
      setChecked(false);
    } else {
      let mobile = "";
      let password = "";
      let index = accountInfo.indexOf("&");
      mobile = accountInfo.substring(0, index);
      password = accountInfo.substring(index + 1);
      formRef.current.setFieldsValue({
        mobile,
        password,
      });
      setChecked(true);
    }
    checkInputHasNull(formRef, setDisabled);
  }, []);

  return (
    <ProForm
      formRef={formRef}
      submitter={{
        render: () => (
          <CustomButton
            buttonStyle={`${styles.customButtonStyle}`}
            type={"submit"}
            disabled={disabled}
          >
            {t("nav.signIn")}
          </CustomButton>
        ),
      }}
      onFinish={handleFinish}
      className={`${styles.form} sign-in-form`}
    >
      <CustomInput
        name="mobile"
        label={t("normal.username")}
        placeholder={t("normal.pleaseEnter.your", { label: t("normal.username") })}
        onChange={() => checkInputHasNull(formRef, setDisabled)}
      />
      <CustomInput
        name="password"
        label={t("normal.password")}
        placeholder={t("normal.pleaseEnter.your", { label: t("normal.password") })}
        onChange={() => checkInputHasNull(formRef, setDisabled)}
        type="password"
      />
      <div className={styles.additional}>
        <NormalRadio
          content={t("signin.remember")}
          customRadioContainerStyle={styles.customRadioContainerStyle}
          checked={checked}
          setChecked={setChecked}
        />
        <div className={styles.question}>
          {t("signin.question")}
          <span className={styles.registerNow} onClick={() => navigate("/signup")}>
            {t("signin.registerNow")}
          </span>
        </div>
      </div>
    </ProForm>
  );
};

export default SignInForm;
