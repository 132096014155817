import { Spin } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomRadio } from "../../../../components";
import { useMobile } from "../../../../context/MobileContext";
import { useGetMyIncome, useGetMyIncomeInfinite } from "../../../../hooks/user/user.hook";
import { getNextPageParam } from "../../../../plugins/utils";
import { Details } from "./components";
import styles from "./style.module.scss";

const MyIncome = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const items = [
    { value: "details", text: t("user.myIncome.details") },
    { value: "dividend", text: t("user.myIncome.dividend") },
  ];
  const itemEnum = {
    [items[0].value]: 0,
    [items[1].value]: 1,
  };
  const [radioValue, setRadioValue] = useState(items[0].value);
  const [item, setItem] = useState(itemEnum[items[0].value]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useGetMyIncome({
    options: { enabled: !isMobile },
    params: { item: 4, page: currentPage },
  });

  const {
    data: dataInfinite,
    isFetching: isFetchingDataInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMyIncomeInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      item: 4,
      page_size: 20,
    },
  });

  const renderContent = () => {
    switch (radioValue) {
      case "details":
        return (
          <Details
            data={isMobile ? dataInfinite : data}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            item={item}
            setCurrentPage={setCurrentPage}
          />
        );
      case "dividend":
        return (
          <Details
            data={isMobile ? dataInfinite : data}
            isDividend={true}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            item={item}
          />
        );
      default:
        return (
          <Details
            data={isMobile ? dataInfinite : data}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            item={item}
          />
        );
    }
  };

  const handleClickRadio = (e) => {
    setRadioValue(e.target.value);
    setItem(itemEnum[e.target.value]);
  };

  return (
    <div className={styles.myIncome}>
      <div className={styles.radio}>
        <CustomRadio
          items={items}
          defaultValue={items[0].value}
          value={radioValue}
          onClick={(e) => handleClickRadio(e)}
        />
      </div>
      <Spin spinning={isFetching || isFetchingDataInfinite}>{renderContent()}</Spin>
    </div>
  );
};

export default MyIncome;
