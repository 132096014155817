import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components";
import { useAuth } from "../../../../context/AuthContext";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const Slogan = ({ showButton = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin } = useAuth();

  return (
    <div className={styles.slogan}>
      <div className={styles.mainSlogan}>
        {t("home.slogan", {
          logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
          bonus: envChangeType("REACT_APP_PROMO_AMOUNT"),
        })}
      </div>
      <div
        className={styles.subSlogan}
        dangerouslySetInnerHTML={{
          __html: t("home.subSlogan", {
            logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
          }).toUpperCase(),
        }}
      />
      {!isLogin && showButton && (
        <div className={styles.buttonGroup}>
          <CustomButton
            buttonStyle={`${styles.customButtonStyle}`}
            onClick={() => (window.location.href = "/signup")}
          >
            {t("nav.signUp")}
          </CustomButton>
          <CustomButton
            buttonStyle={`${styles.customButtonStyle} ${styles.signIn}`}
            onClick={() => (window.location.href = "/signin")}
          >
            {t("nav.signIn")}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default Slogan;
