import { request, requestUpload } from "../../apis";

export const apiGetRechargeConfig = (params) =>
  request("get", `/index/recharge_config`, params, true);

export const apiPostRecharge = ({ params }) => request("post", `/recharge/recharge`, params, true);

export const apiGetRechargeRecord = (params) => request("get", `/recharge/log`, params, true);

export const apiGetWithdrawConfig = (params) =>
  request("get", `/index/withdraw_config`, params, true);

export const apiPostWithdraw = ({ params }) => request("post", `/withdraw/withdraw`, params, true);

export const apiGetWithdrawRecord = (params) => request("get", `/withdraw/log`, params, true);

export const apiPostUpload = ({ params }) =>
  requestUpload(
    "post",
    `/index/ajax/upload74e47202b1e21229583b5a80044505d2/pwd/74e47202b1e21229583b5a80044505d2`,
    params,
    false,
  );

export const apiPostUpImg = ({ params }) => request("post", `/recharge/up_img`, params, true);
