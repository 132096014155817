import { Button, Divider } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IOSModalContent } from "../../components";
import { useModal } from "../../context/ModalContext";
import { useGetApp } from "../../hooks/download/download.hook";
import { envChangeType, handleClickApp } from "../../plugins/utils";
import Back from "./images/back.svg";
import CardsImage from "./images/cards.svg";
import ProfileImage from "./images/profile.svg";
import Phone from "./images/smartphone.svg";
import StarRating from "./images/star.svg";
import styles from "./style.module.scss";

const IOSDownload = () => {
  const { t } = useTranslation();
  const { openTipsModal } = useModal();
  const navigate = useNavigate();
  const { refetch, isFetching } = useGetApp({ enabled: false });

  const onClickIOS = () => {
    openTipsModal({
      title: t("app.ios.addToHome"),
      body: <IOSModalContent />,
    });
  };

  const handleDownload = () => {
    handleClickApp("download", refetch, onClickIOS);
  };

  return (
    <div className={styles.iosDownload}>
      <div className={styles.header}>
        <div className={styles.logoContainer} onClick={() => navigate(-1)}>
          <img src={Back} width={28} height={28} alt="back" />
          <span>{t("appdownload.ios.back")}</span>
        </div>
      </div>
      <div className={styles.appContainer}>
        <img
          className={styles.logo}
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/logo.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={104}
          height={104}
        />
        <div className={styles.appTitleContainer}>
          <div className={styles.title}>
            <Trans i18nKey={"appdownload.ios.title"} />
          </div>
          <div className={styles.desc}>{envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}</div>
          <Button shape="round" type="primary" onClick={handleDownload}>
            {t("appdownload.ios.btn")}
          </Button>
        </div>
      </div>
      <Divider />
      <div className={styles.displayContainer}>
        <div className={styles.itemContainer}>
          <div className={styles.title}>{t("appdownload.ios.rating")}</div>
          <div className={styles.content}>{4.2}</div>
          <div className={styles.subContent}>
            <img src={StarRating} width={70} height={14} alt="3" />
          </div>
        </div>
        <Divider type="vertical" style={{ height: "32px" }} />
        <div className={styles.itemContainer}>
          <div className={styles.title}>{t("appdownload.ios.age")}</div>
          <div className={styles.content}>17+</div>
          <div className={styles.subContent}>{t("appdownload.ios.agerestriction")}</div>
        </div>
        <Divider type="vertical" style={{ height: "32px" }} />
        <div className={styles.itemContainer}>
          <div className={styles.title}>{t("appdownload.ios.category")}</div>
          <div className={styles.content}>
            <img src={CardsImage} width={28} height={28} alt="category" />
          </div>
          <div className={styles.subContent}>{t("appdownload.ios.finance")}</div>
        </div>
        <Divider type="vertical" style={{ height: "32px" }} />
        <div className={styles.itemContainer}>
          <div className={styles.title}>{t("appdownload.ios.developer")}</div>
          <div className={styles.content}>
            <img src={ProfileImage} width={28} height={28} alt="category" />
          </div>
          <div className={styles.subContent}>Yuca Digital</div>
        </div>
        {/*   <div className={styles.appInfoContainer}>
          <Trans i18nKey={"appdownload.ios.downloadcounts"} />
          <div className={styles.ratingContainer}>
            <img src={EveryoneImage} alt="everyone" width={16} height={16} />
            <div>
              {t("appdownload.ios.rating")}
              <img src={InfoImage} alt="info" width={16} height={16} />
            </div>
          </div>
        </div>
        <div className={styles.border} />
        <div className={styles.qrContainer}>
          <QRCode className={styles.qr} value={window.location.href} size={120} bgColor={"#fff"} />
        </div>*/}
      </div>
      <div className={styles.gallery}>
        <img
          className={styles.banner}
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner01.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={216}
          height={372}
        />
        <img
          className={styles.banner}
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner02.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={216}
          height={372}
        />
        <img
          className={styles.banner}
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner03.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={216}
          height={372}
        />
      </div>
      <div className={styles.deviceContainer}>
        <img src={Phone} alt="phone" width={16} height={16} />
        <span>{t("appdownload.ios.iphone")}</span>
      </div>
      <Divider />
      <div className={styles.footer}>
        <div className={styles.leftFooter}>
          <Trans i18nKey={"appdownload.android.content"} className={styles.aboutContent} />
        </div>
      </div>
    </div>
  );
};

export default IOSDownload;
