import React from "react";
import { useLanguage } from "../../../../context/LangContext.js";
import PopoverMenu from "../../../PopoverMenu/index.jsx";
import LangIconContainer from "../LangIconContainer/index.jsx";

function LangSelector({ children }) {
  const { changeLanguage, getLanguage, setShowPopover } = useLanguage();

  const langList = [
    {
      key: "en",
      icon: <LangIconContainer src="/images/utils/langIcons/en.png" alt="English" />,
      text: "English",
    },
    {
      key: "jp",
      icon: <LangIconContainer src="/images/utils/langIcons/jp.png" alt="にっぽんご" />,
      text: "にっぽんご",
    },
    {
      key: "kr",
      icon: <LangIconContainer src="/images/utils/langIcons/kr.png" alt="한국어" />,
      text: "한국어",
    },
    {
      key: "it",
      icon: <LangIconContainer src="/images/utils/langIcons/it.png" alt="Italiana" />,
      text: "Italiana",
    },
    {
      key: "fil",
      icon: <LangIconContainer src="/images/utils/langIcons/fil.png" alt="Pilipino" />,
      text: "Pilipino",
    },
    {
      key: "es",
      icon: <LangIconContainer src="/images/utils/langIcons/es.png" alt="Español" />,
      text: "Español",
    },
    {
      key: "pt",
      icon: <LangIconContainer src="/images/utils/langIcons/pt.png" alt="Português" />,
      text: "Português",
    },
    {
      key: "ru",
      icon: <LangIconContainer src="/images/utils/langIcons/ru.png" alt="Русский" />,
      text: "Русский",
    },
    {
      key: "de",
      icon: <LangIconContainer src="/images/utils/langIcons/de.png" alt="Deutsch" />,
      text: "Deutsch",
    },
    {
      key: "fr",
      icon: <LangIconContainer src="/images/utils/langIcons/fr.png" alt="Français" />,
      text: "Français",
    },
    {
      key: "nl",
      icon: <LangIconContainer src="/images/utils/langIcons/nl.png" alt="Nederlands" />,
      text: "Nederlands",
    },
    {
      key: "ro",
      icon: <LangIconContainer src="/images/utils/langIcons/ro.png" alt="română" />,
      text: "română",
    },
    {
      key: "pl",
      icon: <LangIconContainer src="/images/utils/langIcons/pl.png" alt="Polska" />,
      text: "Polska",
    },
    {
      key: "zh",
      icon: <LangIconContainer src="/images/utils/langIcons/zh.png" alt="简体中文" />,
      text: "简体中文",
    },
  ];

  return (
    <PopoverMenu
      mode="dark"
      actions={langList}
      placement="bottom-end"
      onAction={(node) => {
        if (node.key === getLanguage()) return;
        changeLanguage(node.key, true);
      }}
      setShowPopover={setShowPopover}
    >
      {children}
    </PopoverMenu>
  );
}

export default LangSelector;
