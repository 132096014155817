import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FillModal } from "../../../../components";
import TopArea from "../../../../components/TopArea";
import { useModal } from "../../../../context/ModalContext";
import { envChangeType, envType } from "../../../../plugins/utils";
import { useApiData } from "../../context/ApiDataContext";
import { useGetPreferProject } from "../../hooks/index.hook";
import Advantages from "../Advantages";
import Greener from "../Greener";
import Marquee from "../Marquee";
import Methods from "../Methods";
import Partners from "../Partners";
import PreferredProject from "../PreferredProject";
import Steps from "../Steps";
import TradingView from "../TradingView";
import Transaction from "../Transaction";
import VideoContainer from "../VideoContainer";
import styles from "./style.module.scss";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const HomeContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openSignInUp } = useModal();
  const { profile, popupData, data } = useApiData();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const { data: preferProjectData } = useGetPreferProject();

  const sectionEnum = [
    {
      id: "steps",
      show: true,
      title: null,
      subTitle: null,
      content: <Steps />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "greener",
      show: true,
      title: null,
      subTitle: null,
      content: <Greener />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "greener-contract",
      show: true,
      title: null,
      subTitle: null,
      content: <Greener imageLeft={true} id={"contract"} />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "prefer-project",
      show: true,
      title: null,
      subTitle: null,
      content: <PreferredProject data={preferProjectData} />,
      showMore: true,
      clickMore: () => navigate("/project"),
    },
    {
      id: "advantages",
      show: true,
      title: t("home.advantages"),
      subTitle: t("home.advantages.subtitle", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
      }),
      content: <Advantages />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "video",
      show: true,
      title: null,
      subTitle: null,
      content: <VideoContainer />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "transaction",
      show: true,
      title: t("home.transaction"),
      subTitle: null,
      content: <Transaction />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "methods",
      show: true,
      title: t("home.methods"),
      subTitle: t("home.methods.subtitle", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
      }),
      content: <Methods />,
      showMore: false,
      clickMore: () => {},
    },
    {
      id: "partners",
      show: envChangeType("REACT_APP_HOME_PARTNERS_SHOW", envType.BOOLEAN),
      title: t("home.partners"),
      subTitle: null,
      content: <Partners />,
      showMore: false,
      clickMore: () => {},
    },
  ];

  const renderSection = (section, index) => {
    return (
      <div className={styles.component} key={index} id={section.id}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{section.title}</div>
        </div>
        {section.subTitle && <div className={styles.subTitle}>{section.subTitle}</div>}
        {section.content}
      </div>
    );
  };

  // useEffect(() => {
  //   if (!isLogin) openSignInUpModal(signInUpEnum.SIGN_IN);
  // }, [isLogin]);

  useEffect(() => {
    if (!popupData || openSignInUp) return;
    setOpenModal(true);
    setModalData({
      title: popupData.title,
      isHtml: true,
      content: popupData.content,
      buttonText: t("message.table.detail.title"),
      onOk: () => (window.location.href = popupData.url),
    });
  }, [popupData, openSignInUp]);

  return (
    <div className={`${styles.home} home`} id="home">
      <div className={styles.marqueeArea}>
        <TopArea data={data} />
        <TradingView />
        <Marquee />
      </div>
      <div className={styles.container}>
        {sectionEnum.map((section, index) => {
          if (!section.show) return null;
          return renderSection(section, index);
        })}
      </div>
      <FillModal open={openModal} setOpen={setOpenModal} data={modalData} />
    </div>
  );
};

export default HomeContainer;
