import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTabs, FillModal, UserInfo } from "../../../../components";
import CsFloatButton from "../../../../components/CsFloatButton";
import { useLoading } from "../../../../context/LoadingContext";
import { useMobile } from "../../../../context/MobileContext";
import { useGetCs, useGetUser } from "../../../../hooks/user/user.hook";
import { useUser } from "../../context/UserContext";
import styles from "./style.module.scss";

const UserContainer = () => {
  const { items, activeTab } = useUser();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { setMainLoading } = useLoading();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const { data: userInfo, isFetching: isFetchingUserInfo } = useGetUser({
    enabled: !isMobile,
    staleTime: 60000,
  });
  const { data: cs, isFetching: isFetchingCs } = useGetCs({ enabled: !isMobile });

  const handleClickTab = (key) => {
    switch (key) {
      case "recharge":
        navigate(`/user/recharge/${key}?tab=${key}&&radioValue=${key}`);
        break;
      case "withdraw":
        navigate(`/user/withdraw/${key}?tab=${key}&&radioValue=${key}`);
        break;
      default:
        navigate(`/user/${key}?tab=${key}`);
        break;
    }
  };

  useEffect(() => {
    setMainLoading(isFetchingUserInfo || isFetchingCs);
  }, [isFetchingUserInfo, isFetchingCs]);

  return (
    <div className={styles.user}>
      {!isMobile && <UserInfo userInfo={userInfo} />}
      <div className={styles.main}>
        <CustomTabs
          defaultActiveKey={items[0].key}
          activeKey={activeTab}
          type="card"
          size="large"
          tabPosition="left"
          items={items}
          onTabClick={handleClickTab}
        />
      </div>
      <FillModal open={openModal} setOpen={setOpenModal} data={modalData} />
      {!isMobile && (
        <CsFloatButton
          setOpenModal={setOpenModal}
          setModalData={setModalData}
          userInfo={userInfo}
          cs={cs}
        />
      )}
    </div>
  );
};

export default UserContainer;
