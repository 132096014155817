import { useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetCaptcha, apiGetLoginCs, apiPostRegister } from "../service";

export const usePostRegister = () => {
  return useMutation(({ params }) => apiPostRegister({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetCaptcha = (options) => {
  return useQuery([queryKey.CAPTCHA], () => apiGetCaptcha().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetLoginCs = (options) => {
  return useQuery([queryKey.LOGIN_CS], () => apiGetLoginCs().then((res) => res.data.data), {
    ...options,
  });
};
