import { Col, Image, Row } from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./style.module.scss";
import "./style.scss";

const TableSwiper = ({ data }) => {
  const { t } = useTranslation();
  const refTable = useRef();

  return (
    data?.fundList.length > 0 && (
      <div className={styles.tableContainer}>
        <Row className={styles.label}>
          <Col span={8}>{t("normal.username")}</Col>
          <Col span={8}>{t("label.quantity")}</Col>
          <Col span={8}>{t("label.coin")}</Col>
        </Row>
        <div className={styles.contentContainer}>
          <Swiper
            ref={refTable}
            direction={"vertical"}
            slidesPerView={"auto"}
            freeMode={true}
            speed={4000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            allowTouchMove={false}
            pagination={false}
            loop={true}
            modules={[Autoplay]}
            className="custom-table-swiper"
          >
            {data?.fundList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Row className={styles.swiperSlide}>
                    <Col span={8}>
                      <div>{item?.username}</div>
                    </Col>
                    <Col span={8}>
                      <div>{item?.content}</div>
                    </Col>
                    <Col span={8}>
                      <Image src={item?.img} alt="img" preview={false} className={styles.img} />
                    </Col>
                  </Row>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    )
  );
};

export default TableSwiper;
