import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NormalTitle from "../../components/NormalTitle";
import { useLoading } from "../../context/LoadingContext";
import { envChangeType } from "../../plugins/utils";
import { SignInForm } from "./components";
import styles from "./style.module.scss";

const Signin = () => {
  const { t } = useTranslation();
  const { setMainLoading } = useLoading();

  useEffect(() => {
    setMainLoading(false);
  }, []);

  return (
    <div className={styles.signin}>
      <NormalTitle title={t("normal.signIn")} />
      <div
        className={styles.mainBg}
        style={{
          backgroundImage: `url('/images/${envChangeType(
            "REACT_APP_PRODUCTION_NAME_DEV",
          )}/signin/bg.png')`,
        }}
      >
        <div className={styles.main}>
          <div className={styles.form}>
            <div className={styles.title}>{t("normal.signIn")}</div>
            <SignInForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
