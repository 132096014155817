import { request } from "../../apis";

export const apiGetBankInfo = (params) => request("get", `/user_bank/bank_info`, params, true);

export const apiPostBankInfo = (params) => request("post", `/user_bank/up_bank_info`, params, true);

export const apiGetDigitalInfo = (params) =>
  request("get", `/user_bank/digital_wallet_info?`, params, true);

export const apiPostDigitalInfo = (params) =>
  request("post", `/user_bank/up_digital_wallet_info`, params, true);

export const apiGetGiftRecord = (params) => request("get", `/user/exchange_log`, params, true);

export const apiPostGift = (params) => request("post", `/user/exchange`, params, true);

export const apiPostSafe = (params) => request("post", `/user/up_user_pwd`, params, true);

export const apiPostPaymentPassword = (params) =>
  request("post", `/user/up_user_pay_pwd`, params, true);
