import { ProForm } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomButton,
  CustomInput,
  CustomTable,
  NormalContainer,
  NormalTitle,
  RecordCards,
} from "../../../../../../components";
import { useMobile } from "../../../../../../context/MobileContext";
import { useModal } from "../../../../../../context/ModalContext";
import { apiCodeEnum } from "../../../../../../enumerations/signInUpEnum";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import {
  envChangeType,
  formatDate,
  getNextPageParam,
  reset,
} from "../../../../../../plugins/utils";
import {
  useGetGiftRecord,
  useGetGiftRecordInfinite,
  usePostGift,
} from "../../../../hooks/index.hook";
import ResultPopup from "../ResultPopup";
import styles from "./style.module.scss";

const Gift = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { openTipsModal } = useModal();
  const { isMobile } = useMobile();
  const [visibleResultPopup, setVisibleResultPopup] = useState(false);
  const [timer, setTimer] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching, refetch } = useGetGiftRecord({
    options: { enabled: !isMobile },
    params: { page: currentPage },
  });
  const {
    data: dataInfinite,
    isFetching: isFetchingDataInfinite,
    refetch: refetchDataInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetGiftRecordInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      page_size: 20,
    },
  });
  const { mutate: postGift, isLoading } = usePostGift();

  const columns = [
    {
      title: t("label.receiveDate").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => formatDate(dom),
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "amount",
      dataIndex: "amount",
      render: (dom) =>
        `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(dom)}`,
    },
  ];

  const handleClosePopup = () => {
    setTimer(0);
    setVisibleResultPopup(false);
  };

  const handleFinish = () => {
    const params = formRef.current.getFieldsValue();

    postGift(
      {
        ...params,
      },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          setTimer(3);
          setVisibleResultPopup(true);
          if (isMobile) {
            refetchDataInfinite();
          } else {
            refetch();
          }
          // toast({ content: msg, type: "success" });
          reset(formRef);
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    let closeTimer = setTimeout(() => {
      handleClosePopup();
    }, 3200);
    if (!visibleResultPopup) {
      clearTimeout(closeTimer);
      return;
    }
    return () => {
      clearTimeout(closeTimer);
    };
  }, [visibleResultPopup]);

  useEffect(() => {
    if (!data && !dataInfinite) return;
    console.log("giftData", data);
    console.log("giftDataInfinite", dataInfinite);
  }, [data, dataInfinite]);

  return (
    <div className={styles.gift}>
      <NormalTitle title={t("normal.gift")} />
      <NormalContainer customNormalContainerStyle={styles.customNormalContainerStyle}>
        <ProForm
          formRef={formRef}
          submitter={{
            render: () => (
              <CustomButton
                buttonStyle={`${styles.customButtonStyle}`}
                type={"submit"}
                loading={isLoading}
              >
                {t("normal.exchange.upper")}
              </CustomButton>
            ),
          }}
          onFinish={handleFinish}
          className={styles.form}
        >
          <CustomInput
            name="batch"
            label={t("label.receiveCode")}
            placeholder={t("normal.pleaseEnter", { label: t("user.gift.code") })}
          />
        </ProForm>
      </NormalContainer>
      {isMobile ? (
        <RecordCards
          data={dataInfinite}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          columns={columns}
          loading={isFetchingDataInfinite}
        />
      ) : (
        <>
          <CustomTable
            columns={columns}
            data={data}
            setCurrentPage={setCurrentPage}
            loading={isFetching}
          />
        </>
      )}
      <ResultPopup
        visibleResultPopup={visibleResultPopup}
        setVisibleResultPopup={setVisibleResultPopup}
        timer={timer}
        setTimer={setTimer}
        onClose={handleClosePopup}
        amount={100}
      />
    </div>
  );
};

export default Gift;
