import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import MyProject from "../../MyProject";
import MyTeam from "../../MyTeam";
import { Recharge, Withdraw } from "../../Wallet/components";
import { GiftAndRecord, MyIncome, Safe, WithdrawAddress } from "../components";

const UserContext = createContext({
  items: [],
  activeTab: "",
});

export const UserContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();

  const items = [
    // { label: t("user.bankInfo"), key: "bank-info", children: <BankInfo /> },
    { label: t("nav.myProject"), key: "my-project", children: <MyProject /> },
    { label: t("user.myIncome"), key: "my-income", children: <MyIncome /> },
    { label: t("nav.myTeam"), key: "my-team", children: <MyTeam /> },
    { label: t("user.withdrawAddress"), key: "withdrawAddress", children: <WithdrawAddress /> },
    { label: t("normal.recharge"), key: "recharge", children: <Recharge /> },
    { label: t("normal.withdraw"), key: "withdraw", children: <Withdraw /> },
    { label: t("user.gift"), key: "gift", children: <GiftAndRecord /> },
    { label: t("user.safe"), key: "safe", children: <Safe /> },
  ];
  const [activeTab] = useState(params.get("tab") || items[0].key);

  return <UserContext.Provider value={{ items, activeTab }}>{children}</UserContext.Provider>;
};
export const useUser = () => {
  const Value = useContext(UserContext);
  return Value;
};
