import { Divider, Modal } from "antd";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import styles from "./style.module.scss";
import "./style.scss";

const CustomModal = ({ open, setOpen, props }) => {
  const { t } = useTranslation();

  const handleClickOk = () => {
    if (props?.onOk) {
      props?.onOk();
    }
    setOpen(false);
  };

  return (
    <Modal
      title={props?.title ? props?.title : t("normal.tips.upper")}
      open={open}
      onOk={handleClickOk}
      width={358}
      onCancel={() => setOpen(false)}
      closeIcon={null}
      wrapClassName="custom-modal"
      destroyOnClose={true}
      footer={[
        <div key="button" className={styles.footer}>
          <Divider className={styles.divider} />
          <div>
            <div className={styles.buttonGroup}>
              {props.needCancel && (
                <CustomButton
                  key="cancel"
                  buttonStyle={`${styles.customButtonStyle} ${styles.cancel}`}
                  onClick={() => setOpen(false)}
                >
                  {t("normal.cancel")}
                </CustomButton>
              )}
              <CustomButton
                key="submit"
                buttonStyle={`${styles.customButtonStyle}`}
                onClick={handleClickOk}
              >
                {t("normal.ok")}
              </CustomButton>
            </div>
          </div>
        </div>,
      ]}
    >
      {props.isHtml ? <div dangerouslySetInnerHTML={{ __html: props.body }} /> : props.body}
    </Modal>
  );
};

export default CustomModal;
