import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomRadio } from "../../../../components";
import { useMobile } from "../../../../context/MobileContext";
import { getNextPageParam } from "../../../../plugins/utils.js";
import typeEnum from "../../enumerations/typeEnum.js";
import { useGetTeamListDetailInfinite } from "../../hooks/index.hook";
import { Details } from "./components";
import styles from "./style.module.scss";

const TeamDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { state: targetKey } = useLocation();
  const [radioValue, setRadioValue] = useState(targetKey);

  const items = [
    { value: typeEnum.EQC, text: t("user.myTeam.detail.eqc") },
    { value: typeEnum.LV1, text: t("user.myTeam.detail.lv1") },
    { value: typeEnum.LV2, text: t("user.myTeam.detail.lv2") },
    { value: typeEnum.LV3, text: t("user.myTeam.detail.lv3") },
  ];

  const {
    data: detail,
    isFetching: isFetchingDetail,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetTeamListDetailInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      type: radioValue,
      page_size: 20,
    },
  });

  const renderContent = () => {
    return (
      <Details
        data={detail}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        radioValue={radioValue}
      />
    );
  };

  const handleClickRadio = (e) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    if (!isMobile) navigate("/commissions?tab=my-team");
  }, [isMobile]);

  return (
    <div className={styles.teamDetail}>
      <div className={styles.radio}>
        <CustomRadio items={items} value={radioValue} onClick={(e) => handleClickRadio(e)} />
      </div>
      <Spin spinning={isFetchingDetail}>{renderContent()}</Spin>
    </div>
  );
};

export default TeamDetail;
