import { ProForm } from "@ant-design/pro-components";
import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  toast,
} from "../../../../components/index.js";
import { useModal } from "../../../../context/ModalContext.js";
import { channelEnum } from "../../../../enumerations/channelEnum.js";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum.js";
import { useUser } from "../../context/UserContext.js";
import { useGetDigitalInfo, usePostDigitalInfo } from "../../hooks/index.hook.js";
import styles from "./style.module.scss";

const WithdrawAddress = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { activeTab, items } = useUser();
  const { openTipsModal } = useModal();
  const [valueEnum, setValueEnum] = useState({});

  const { mutate: updateDigitalInfo, isLoading } = usePostDigitalInfo();
  const {
    data: digitalInfo,
    refetch: refetchDigitalInfo,
    isFetching: isFetchingDigitalInfo,
  } = useGetDigitalInfo({
    enabled: true,
  });

  const handleChangeCurrency = (key) => {
    if (digitalInfo.wallet_address) {
      const targetAddress = digitalInfo?.wallet_address.filter(
        (item) => item.digital_chain === key,
      );
      formRef.current.setFieldsValue({
        digital_address: targetAddress.length > 0 ? targetAddress[0].digital_address : "",
      });
    }
  };

  const handleFinish = () => {
    const params = formRef.current.getFieldsValue();

    updateDigitalInfo(
      {
        ...params,
      },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          refetchDigitalInfo();
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    if (!digitalInfo || isFetchingDigitalInfo) return;
    console.log(digitalInfo);
    digitalInfo.chain.map((item, index) => {
      setValueEnum((prev) => {
        return {
          ...prev,
          [item.name]: (
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <img src={channelEnum[item.name]?.src} alt="logo" width={20} />
              {item.name}
            </div>
          ),
        };
      });
      if (index === 0) {
        if (digitalInfo.digital_chain && digitalInfo.digital_address) {
          formRef.current.setFieldValue("digital_chain", digitalInfo.digital_chain);
          handleChangeCurrency(digitalInfo.digital_chain);
        } else {
          formRef.current.setFieldValue("digital_chain", item.name);
        }
      }
      return item;
    });
  }, [digitalInfo, isFetchingDigitalInfo]);

  return (
    <Spin spinning={isFetchingDigitalInfo} className={styles.withdrawAddress}>
      <div className={styles.withdrawAddress}>
        <NormalTitle title={t("nav.walletAddress")} />
        <NormalContainer>
          <ProForm
            formRef={formRef}
            submitter={{
              render: () => (
                <CustomButton
                  buttonStyle={`${styles.customButtonStyle}`}
                  type={"submit"}
                  loading={isLoading}
                >
                  {t("normal.submit")}
                </CustomButton>
              ),
            }}
            onFinish={handleFinish}
            className={styles.form}
          >
            <CustomInput
              type="select"
              name="digital_chain"
              label={t("label.withdrawAddress.currency")}
              placeholder={t("normal.pleaseEnter", { label: t("label.withdrawAddress.currency") })}
              valueEnum={valueEnum}
              onChange={handleChangeCurrency}
            />
            <CustomInput
              name="digital_address"
              label={t("label.withdrawAddress.walletAddress")}
              placeholder={t("normal.pleaseEnter", {
                label: t("label.withdrawAddress.walletAddress"),
              })}
            />
          </ProForm>
        </NormalContainer>
      </div>
    </Spin>
  );
};

export default WithdrawAddress;
