import styles from "./style.module.scss";

const ShareTable = ({ data, columns, width }) => {
  const TYPE_ENUM = {
    label: { style: styles.label, showRender: false },
    cell: { style: styles.cell, showRender: true },
  };
  return (
    <div className={styles.shareTable}>
      <div className={styles.container} style={{ minWidth: `${width}px` }}>
        {data.map((item, index) => (
          <div key={index} className={styles.row}>
            {columns
              .filter((column) => !column?.hidden || column?.hidden === false)
              .map((column, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles.box} ${TYPE_ENUM[item.type].style}`}
                    style={{ width: column.width }}
                  >
                    {column.render && TYPE_ENUM[item.type].showRender
                      ? column.render(item[column.dataIndex], item)
                      : item[column.dataIndex]}
                  </div>
                );
              })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShareTable;
