import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../../../components";
import img1 from "../../images/work/img-1.png";
import img2 from "../../images/work/img-2.png";
import img3 from "../../images/work/img-3.png";
import img4 from "../../images/work/img-4.png";
import styles from "./style.module.scss";

const Work = () => {
  const { t } = useTranslation();

  const cardEnum = [
    { src: img1, title: "affiliate.work.card.title1", content: "affiliate.work.card.content1" },
    { src: img2, title: "affiliate.work.card.title2", content: "affiliate.work.card.content2" },
    { src: img3, title: "affiliate.work.card.title3", content: "affiliate.work.card.content3" },
    { src: img4, title: "affiliate.work.card.title4", content: "affiliate.work.card.content4" },
  ];
  return (
    <div className={styles.work}>
      <NormalTitle title={t("affiliate.work.title")} />
      <div className={styles.main}>
        {cardEnum.map((card, index) => {
          return (
            <div className={styles.card} key={index}>
              <img src={card.src} alt="img" width={120} />
              <div className={styles.title}>{t(card.title)}</div>
              <div className={styles.content}>{t(card.content)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Work;
