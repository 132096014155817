import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomCard, CustomTable, RecordCards } from "../../../../../../components";
import { useMobile } from "../../../../../../context/MobileContext";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import { envChangeType, getNextPageParam } from "../../../../../../plugins/utils";
import { useGetGiftRecord, useGetGiftRecordInfinite } from "../../../../hooks/index.hook";
import styles from "./style.module.scss";

const Record = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { data, isFetching } = useGetGiftRecord({ options: { enabled: !isMobile } });

  const {
    data: dataInfinite,
    isFetching: isFetchingDataInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetGiftRecordInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      page_size: 20,
    },
  });

  const columns = [
    {
      title: t("label.status"),
      key: "status",
      render: () => t("user.gift.used"),
    },
    {
      title: t("label.time"),
      dataIndex: "create_date",
      key: "create_date",
    },
    {
      title: t("label.amount"),
      dataIndex: "amount",
      key: "amount",
      render: (dom) =>
        "+" +
        moneyFormat(dom) +
        " " +
        t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
    },
    {
      title: t("label.remark"),
      key: "batch",
      dataIndex: "batch",
    },
  ];

  const renderCardContent = (data) => {
    return (
      <>
        <div className={styles.left}>
          <div className={styles.bold}>{t("user.gift.used")}</div>
          <div>{data?.create_date}</div>
        </div>
        <div className={styles.right}>
          <div className={styles.bold}>
            {"+" +
              moneyFormat(data?.amount) +
              " " +
              t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}
          </div>
          <div>{data?.batch}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    console.log("dataInfinite", dataInfinite);
  }, [dataInfinite]);

  return isMobile ? (
    <RecordCards
      data={dataInfinite}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      loading={isFetchingDataInfinite}
      content={renderCardContent}
    />
  ) : (
    <CustomCard title={t("normal.record")}>
      <CustomTable columns={columns} data={data} loading={isFetching} />
    </CustomCard>
  );
};

export default Record;
