import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localStorageKey from "../enumerations/localStorageKey";
import cn from "../locales/cn.json";
import de from "../locales/de.json";
import en from "../locales/en.json";
import es from "../locales/es.json";
import fil from "../locales/fil.json";
import fr from "../locales/fr.json";
import id from "../locales/id.json";
import it from "../locales/it.json";
import jp from "../locales/jp.json";
import kr from "../locales/kr.json";
import nl from "../locales/nl.json";
import pl from "../locales/pl.json";
import pt from "../locales/pt.json";
import ro from "../locales/ro.json";
import ru from "../locales/ru.json";
import vn from "../locales/vn.json";
import { envChangeType } from "./utils";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: { translation: en },
  zh: { translation: cn },
  pt: { translation: pt },
  vi: { translation: vn },
  jp: { translation: jp },
  id: { translation: id },
  ko: { translation: kr },
  es: { translation: es },
  ru: { translation: ru },
  de: { translation: de },
  fr: { translation: fr },
  nl: { translation: nl },
  ro: { translation: ro },
  pl: { translation: pl },
  it: { translation: it },
  fil: { translation: fil },
  kr: { translation: kr },
};
const nowLangProcess = () => {
  const lang =
    window?.localStorage?.getItem(localStorageKey.LANGUAGE) ||
    envChangeType("REACT_APP_DEFAULT_LANG");
  return lang.replace(/\"/gm, "").toLowerCase();
};
const nowLang = nowLangProcess();
const hasTranslation = Boolean(Object.keys(resources).includes(nowLang));
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: hasTranslation ? nowLang : envChangeType("REACT_APP_DEFAULT_LANG"),
    fallbackLng: nowLang,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
