import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useLoading } from "../../context/LoadingContext";
import { ActivityPanel, StatisticItem, VIPPanel, WelcomePanel } from "./components";
import styles from "./style.module.scss";

import { useMobile } from "../../context/MobileContext";
import { useGetUser } from "../../hooks/user/user.hook";
import { envChangeType } from "../../plugins/utils";

const mockStatistic = {
  balance: 1000,
  contract: 50,
  profit: 200,
  comission: 10.5,
  deposit: 100.32,
  withdraw: 200,
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { isLogin } = useAuth();
  const { isWidthMiddle } = useMobile();
  const { setMainLoading } = useLoading();

  const { data: userInfo, isFetching: isFetchingUserInfo } = useGetUser({
    enabled: true,
  });

  const statisticConfig = [
    {
      key: "money",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/wallet.svg`,
      title: "dashboard.statistic.balance",
      isTranslate: true,
      visible: true,
      isMoney: true,
      path: "/transactions?tab=my-income",
    },
    {
      key: "contract",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/wallet.svg`,
      title: "dashboard.statistic.contract",
      isTranslate: true,
      visible: true,
      isMoney: false,
      path: "/my-contract",
    },
    {
      key: "total_profit",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/profit.svg`,
      title: "dashboard.statistic.profit",
      isTranslate: true,
      visible: true,
      isMoney: true,
      path: "/earnings",
    },
    {
      key: "total_commission",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/money.svg`,
      title: "dashboard.statistic.commission",
      isTranslate: true,
      visible: true,
      isMoney: true,
      path: "/commissions?tab=my-team",
    },
    {
      key: "deposit_money",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/deposit.svg`,
      title: "dashboard.statistic.deposit",
      isTranslate: true,
      visible: true,
      isMoney: true,
      path: "/deposit",
    },
    {
      key: "withdraw_money",
      image: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/dashboard/withdraw.svg`,
      title: "dashboard.statistic.withdraw",
      isTranslate: true,
      visible: true,
      isMoney: true,
      path: "/withdraw",
    },
  ];

  const onViewStatistic = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!userInfo) return;
    console.log("userInfo", userInfo);
  }, [userInfo]);

  useEffect(() => {
    setMainLoading(isFetchingUserInfo);
  }, [isFetchingUserInfo]);

  useEffect(() => {
    if (!isLogin) window.location.href = "/signin";
  }, [isLogin]);

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.topContainer}>
        <Row gutter={[24, 24]}>
          {isWidthMiddle ? (
            <Col xs={24}>
              {
                <div className={styles.welcomeContainer}>
                  <WelcomePanel balance={userInfo?.money} account={userInfo?.mobile} />
                </div>
              }
            </Col>
          ) : null}
          <Col lg={18} md={14} sm={12} xs={24}>
            <div className={styles.statisticsContainer}>
              <Row gutter={[24, 24]}>
                {statisticConfig.map((_statistic) => {
                  return (
                    <Col lg={8} md={24} sm={24} xs={24} key={_statistic.key}>
                      <StatisticItem
                        {..._statistic}
                        data={userInfo && userInfo[_statistic.key]}
                        onViewAll={onViewStatistic}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col lg={6} md={10} sm={12} xs={24}>
            <div className={styles.sideContainer}>
              {isWidthMiddle ? null : (
                <WelcomePanel balance={userInfo?.money} account={userInfo?.mobile} />
              )}
              <ActivityPanel />
            </div>
          </Col>
        </Row>
      </div>
      <VIPPanel userInfo={userInfo} />
    </div>
  );
};

export default Dashboard;
