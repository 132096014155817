import styles from "./style.module.scss";
const READ_STATUS = {
  UNREAD: "0",
  READ: "1",
};

const ComputerButton = ({ record, onClickRead, onClickUnRead }) => {
  const renderBtn = () => {
    switch (record.read) {
      case READ_STATUS.READ:
        return (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonRead} onClick={onClickRead}>
              <img src={"/images/utils/monitor.png"} width={28} height={28} alt="read" />
            </div>
          </div>
        );

      case READ_STATUS.UNREAD:
        return (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonUnRead} onClick={onClickUnRead}>
              <img src={"/images/utils/monitor.png"} width={28} height={28} alt="unread" />
            </div>
          </div>
        );
      default:
        return (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonRead} onClick={onClickRead}>
              <img src={"/images/utils/monitor.png"} width={28} height={28} alt="read" />
            </div>
          </div>
        );
    }
  };
  return renderBtn();
};

export default ComputerButton;
