import { Row } from "antd";

import { useEffect, useState } from "react";
import CenterGroup from "./components/CenterGroup";
import LeftGroup from "./components/LeftGroup";
import RightGroup from "./components/RightGroup";
import styles from "./style.module.scss";
const Navigation = (params) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = document.getElementById("contentContainer").scrollTop; //滚动条滚动高度
      if (scrollTop > 0) {
        setIsShow(true);
      } else {
        setIsShow(false);
      }
    };
    // 监听
    window.addEventListener("scroll", handleScroll, true);
    // 销毁
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <div className={`${styles.navigation} ${isShow ? styles.styleBorderBottom : ""}`}>
      <Row className={styles.row}>
        <LeftGroup {...(params?.left ?? {})} goBack={params.goBack} span={0} />
        <CenterGroup {...(params?.center ?? {})} span={24} />
        <RightGroup {...(params?.right ?? {})} span={0} />
      </Row>
    </div>
  );
};

export default Navigation;
