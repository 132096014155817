import { useMutation } from "@tanstack/react-query";
import { apiPostLogin } from "../service";

export const usePostLogin = () => {
  return useMutation(({ params }) => apiPostLogin({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
