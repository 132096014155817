import React from "react";
import { AboutContainer } from "./components";
import { ApiDataContextProvider } from "./context/ApiDataContext";

const About = () => {
  return (
    <ApiDataContextProvider>
      <AboutContainer />
    </ApiDataContextProvider>
  );
};

export default About;
