const localStorageKey = {
  CURRENCY: "currency",
  TOKEN: "token",
  LANGUAGE: "lang",
  TRADE_TIMER: "trade-timer",
  INVITE_CODE: "invite-code",
  REMEMBER: "remember",
};

export default localStorageKey;
