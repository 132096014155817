import { useApiData } from "../../context/ApiDataContext";
import styles from "./style.module.scss";

const VideoContainer = () => {
  const { video } = useApiData();

  return (
    video?.length > 0 && (
      <video
        className={styles.video}
        autoPlay="autoplay"
        controls="controls"
        webkit-playsinline="true"
        playsInline={true}
        muted="muted"
      >
        <source src={video[0]} type="video/mp4" />
      </video>
    )
  );
};

export default VideoContainer;
