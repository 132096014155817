import { useTranslation } from "react-i18next";
import { NormalCards } from "../../../../components";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const Steps = () => {
  const { t } = useTranslation();
  const teamEnum = [
    {
      key: "step1",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/steps/step1.png`,
      title: t("home.steps.step1.title"),
      content: t("home.steps.step1.content"),
    },
    {
      key: "step2",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/steps/step2.png`,
      title: t("home.steps.step2.title"),
      content: t("home.steps.step2.content"),
    },
    {
      key: "step3",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/steps/step3.png`,
      title: t("home.steps.step3.title"),
      content: t("home.steps.step3.content"),
    },
    {
      key: "step4",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/steps/step4.png`,
      title: t("home.steps.step4.title"),
      content: t("home.steps.step4.content"),
    },
  ];

  return (
    <div className={`${styles.steps} steps`}>
      <NormalCards data={teamEnum} />
    </div>
  );
};

export default Steps;
