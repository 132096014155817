import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //ms time until subsequent request will be triggered again
      staleTime: 3000,
      //when request failed, by default will retry 3x with exponential backoff delay before returning error
      //turn off this option on dev mode to make quicker dev experience
      retry: false,
      //option to refetch data when windows on focus to keep showing latest data to user
      //also turn this off on dev env for simplicity
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ConfigProvider
      theme={{
        token: {
          screenXS: 480, // xs breakpoint
          screenXSMin: 480,
          screenXSMax: 511,
          screenSM: 512, // sm breakpoint
          screenSMMin: 512,
          screenSMMax: 767,
          screenMD: 768, // md breakpoint
          screenMDMin: 768,
          screenMDMax: 1079,
          screenLG: 1080, // lg breakpoint
          screenLGMin: 1080,
          screenLGMax: 1190,
          screenXL: 1200, // xl breakpoint
          screenXLMin: 1200,
          screenXLMax: 1599,
          screenXXL: 1600, // xxl breakpoint
          screenXXLMin: 1600,
        },
      }}
    >
      <App />
    </ConfigProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
