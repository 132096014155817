import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetBlog, apiGetBlogDetail } from "../service";

export const useGetBlog = ({ options, params }) => {
  return useQuery(
    [queryKey.BLOG, params],
    () =>
      apiGetBlog(params).then((res) => {
        const { data } = res.data;
        data.data = data.data.map((item) => {
          return { ...item, key: item.id };
        });
        return data;
      }),
    {
      ...options,
    },
  );
};

export const useGetBlogInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.BLOG, params],
    ({ pageParam = 1 }) =>
      apiGetBlog({ ...params, page: pageParam }).then((res) => {
        const { data } = res.data;
        // data.data = data.data.map((item) => {
        //   return { ...item, key: item.id };
        // });
        return { data: data.data, last_page: data.last_page };
      }),
    {
      ...options,
    },
  );
};

export const useGetBlogDetail = ({ options, params }) => {
  return useQuery(
    [queryKey.BLOG_DETAIL, params],
    () => apiGetBlogDetail(params).then((res) => res.data.data),
    {
      ...options,
    },
  );
};
