import { Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetMyIncome } from "../../../../hooks/user/user.hook";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";
import "./style.scss";

const ActivityPanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isFetching, isLoading } = useGetMyIncome({
    options: {},
    params: { item: 4, page_size: 0 },
  });

  const fakeData = [
    { memo: "Deposit Success", money: 100000 },
    { memo: "Deposit Success", money: 100 },
    { memo: "Test", money: 10 },
    { memo: "Register reward +10", money: 10 },
  ];

  return (
    <div className={`${styles.activityContainer} activity-container`}>
      <div className={styles.header}>
        <div className={styles.title}>{t("dashboard.side.activity")}</div>
        <div
          className={styles.buttonViewAll}
          onClick={() => navigate("/transactions?tab=my-income")}
        >
          {t("normal.viewall")}
        </div>
      </div>
      <Spin wrapperClassName={styles.spin} spinning={isFetching || isLoading}>
        <div className={styles.activityItemContainer}>
          {data?.data.map((_income, index) => {
            if (index >= 3) return null;
            return (
              <div className={styles.activityItem} key={index}>
                <img
                  src={`/images/${envChangeType(
                    "REACT_APP_PRODUCTION_NAME_DEV",
                  )}/dashboard/money.svg`}
                  alt={t("user.myIncome.revenue")}
                  width={20}
                  height={20}
                />
                <div className={styles.activityTitle}>{_income?.memo}</div>
                <div className={styles.activityContent}>
                  {`${t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)} `}
                  <div>{moneyFormat(_income?.money)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Spin>
    </div>
  );
};

export default ActivityPanel;
