import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { envChangeType } from "../../plugins/utils";
import styles from "./style.module.scss";
import "./style.scss";

const PrivacyAndTerms = ({ title, content, email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={`${styles.privacyAndTerms} privacy-and-terms`}>
      <div className={styles.title}>{t(title)}</div>
      <div className={styles.content}>
        <Trans
          i18nKey={content}
          values={{
            logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
            email: email,
          }}
        />
      </div>
      {/* <CustomButton buttonStyle={`${styles.customButtonStyle}`} onClick={() => navigate("/")}>
        {t("normal.accept")}
      </CustomButton> */}
    </div>
  );
};

export default PrivacyAndTerms;
