import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NormalTitle } from "../../../../components";
import styles from "./style.module.scss";

const Detail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: tutorial } = useLocation();

  useEffect(() => {
    if (!tutorial) navigate("/tutorial");
  }, [tutorial, navigate]);

  useEffect(() => {
    console.log("tutorialDetail", tutorial);
  }, [tutorial]);

  return (
    <div className={styles.detail}>
      <NormalTitle title={tutorial?.title} />
      <div className={styles.main}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: tutorial?.article_content }}
        />
        {/* {tutorial && (
          <div className={styles.imgContainer}>
            <Image src={tutorial?.article_image} alt="img" preview={false} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Detail;
