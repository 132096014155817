import { DownOutlined } from "@ant-design/icons";
import { Col, Drawer, Dropdown, Image, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LangSelectorPopover } from "../../../../../components";
import CustomButton from "../../../../../components/CustomButton";
import { useAuth } from "../../../../../context/AuthContext";
import { useLoading } from "../../../../../context/LoadingContext";
import { useMobile } from "../../../../../context/MobileContext";
import localStorageKey from "../../../../../enumerations/localStorageKey";
import { useGetApp } from "../../../../../hooks/download/download.hook";
import { envChangeType } from "../../../../../plugins/utils";
import { isActive } from "../../../../utils";
import styles from "./style.module.scss";
import "./style.scss";

const CenterGroup = ({ span, title, titleEllipsis, hasBack, backUrl, rightText, rightUrl }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { pathname } = useLocation();
  const { isLogin, setToken } = useAuth();
  const { setMainLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const [dashboardMode, setDashboardMode] = useState(false);
  const [currentOpenDropDown, setCurrentOpenDropDown] = useState("");

  const { refetch, isFetching } = useGetApp({ enabled: false });

  const handleClickLogOut = () => {
    setOpen(false);
    setToken(null);
    localStorage.removeItem(localStorageKey.TOKEN);
    window.location.href = "/";
  };

  const handleClickSignIn = () => {
    window.location.href = "/signin";
  };

  const handleClickSignUp = () => {
    window.location.href = "/signup";
  };

  const handleClickHamburger = () => {
    setOpen(!open);
  };

  const handleClickItem = (_item) => {
    if (_item.onClick) {
      _item.onClick();
    } else {
      setOpen(false);
      window.location.href = _item.path;
    }
  };

  const navEnum = [
    { type: "text", content: t("nav.home"), icon: null, path: "/", show: true, onClick: null },
    {
      type: "text",
      content: t("nav.project"),
      icon: null,
      path: "/project",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: t("nav.about"),
      icon: null,
      path: "/about",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: (
        <div className={`${styles.welfareCenter} welfare-center`}>{t("nav.welfareCenter")}</div>
      ),
      icon: null,
      path: "/share",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: t("home.faq"),
      icon: null,
      path: "/faq",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: t("normal.tutorial"),
      icon: null,
      path: "/tutorial",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: t("nav.app"),
      icon: null,
      path: "/app-download",
      show: true,
      onClick: null,
    },
    { type: "text", content: t("nav.blog"), icon: null, path: "/blog", show: true, onClick: null },
    {
      type: "button",
      content: (
        <div className={styles.buttonGroup}>
          <CustomButton buttonStyle={styles.customButtonStyle} onClick={handleClickSignUp}>
            {t("nav.signUp")}
          </CustomButton>
          <CustomButton
            buttonStyle={`${styles.customButtonStyle} ${styles.signIn}`}
            onClick={handleClickSignIn}
          >
            {t("nav.signIn")}
          </CustomButton>
        </div>
      ),
      icon: null,
      path: null,
      show: !isLogin && !isMobile,
      onClick: null,
    },
    {
      type: "button",
      content: (
        <div className={styles.buttonGroup}>
          <CustomButton
            buttonStyle={styles.customButtonStyle}
            onClick={() => (window.location.href = "/dashboard")}
          >
            {t("nav.user")}
          </CustomButton>
          <CustomButton buttonStyle={`${styles.customButtonStyle}`} onClick={handleClickLogOut}>
            {t("nav.signOut")}
          </CustomButton>
        </div>
      ),
      icon: null,
      path: null,
      show: isLogin && !isMobile,
      onClick: null,
    },
    {
      type: "text",
      content: <CustomButton buttonStyle="drawer-btn">{t("nav.user")}</CustomButton>,
      icon: null,
      path: "/dashboard",
      show: isLogin && isMobile,
      onClick: null,
    },
    {
      type: "text",
      content: <CustomButton buttonStyle="drawer-btn">{t("nav.signOut")}</CustomButton>,
      icon: null,
      path: null,
      show: isLogin && isMobile,
      onClick: handleClickLogOut,
    },
    {
      type: "text",
      content: (
        <CustomButton buttonStyle="drawer-btn" onClick={handleClickSignUp}>
          {t("nav.signUp")}
        </CustomButton>
      ),
      icon: null,
      path: "/signup",
      show: !isLogin && isMobile,
      onClick: null,
    },
    {
      type: "text",
      content: (
        <CustomButton buttonStyle="drawer-btn signIn" onClick={handleClickSignIn}>
          {t("nav.signIn")}
        </CustomButton>
      ),
      icon: null,
      path: "/signin",
      show: !isLogin && isMobile,
      onClick: null,
    },
    {
      type: "button",
      content: (
        <div className={styles.buttonGroup}>
          <CustomButton buttonStyle={styles.customButtonStyle} onClick={handleClickHamburger}>
            {t("normal.menu").toUpperCase()}
          </CustomButton>
        </div>
      ),
      icon: null,
      path: null,
      show: isMobile,
      onClick: null,
    },
    {
      type: "button",
      content: <LangSelectorPopover />,
      icon: null,
      path: null,
      show: true,
      onClick: null,
    },
  ];

  const dashboardNavEnum = [
    {
      type: "text",
      content: t("nav.message"),
      icon: null,
      path: "/message",
      show: true,
      onClick: null,
    },
    {
      type: "text",
      content: t("nav.user"),
      icon: null,
      path: "/dashboard",
      show: true,
      onClick: null,
    },
    {
      type: "dropdown",
      content: t("nav.project"),
      menu: [
        {
          label: (
            <div onClick={() => (window.location.href = "/project")}>{t("nav.allContract")}</div>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/my-contract")}>{t("nav.myProject")}</div>
          ),
          key: "1",
        },
      ],
      key: "contract",
      icon: null,
      path: "/project",
      show: true,
      onClick: null,
    },
    {
      type: "dropdown",
      content: t("normal.recharge"),
      menu: [
        {
          label: (
            <div onClick={() => (window.location.href = "/deposit")}>{t("nav.depositMoney")}</div>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/my-deposits?radioValue=record")}>
              {t("nav.myDeposits")}
            </div>
          ),
          key: "1",
        },
      ],
      key: "deposit",
      icon: null,
      path: "/deposit",
      show: true,
      onClick: null,
    },
    {
      type: "dropdown",
      content: t("normal.withdraw"),
      menu: [
        {
          label: (
            <div onClick={() => (window.location.href = "/withdraw")}>{t("nav.withdrawMoney")}</div>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/my-withdrawals?radioValue=record")}>
              {t("nav.myWithdrawals")}
            </div>
          ),
          key: "1",
        },
      ],
      key: "withdraw",
      icon: null,
      path: "/withdraw",
      show: true,
      onClick: null,
    },
    {
      type: "dropdown",
      content: t("nav.bills"),
      menu: [
        {
          label: (
            <div onClick={() => (window.location.href = "/transactions?tab=my-income")}>
              {t("nav.transactions")}
            </div>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/commissions?tab=my-team")}>
              {t("nav.commissions")}
            </div>
          ),
          key: "1",
        },
      ],
      key: "bills",
      icon: null,
      path: "/bills",
      show: true,
      onClick: null,
    },
    {
      type: "dropdown",
      content: t("nav.settings"),
      menu: [
        {
          label: (
            <div onClick={() => (window.location.href = "/wallet-address?tab=withdrawAddress")}>
              {t("nav.walletAddress")}
            </div>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/login-password?tab=safe")}>
              {t("nav.loginPassword")}
            </div>
          ),
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/payment-password")}>
              {t("nav.paymentPassword")}
            </div>
          ),
          key: "2",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={() => (window.location.href = "/referrals?tab=my-team")}>
              {t("nav.referrals")}
            </div>
          ),
          key: "3",
        },
        {
          type: "divider",
        },
        {
          label: <div onClick={handleClickLogOut}>{t("nav.signOut")}</div>,
          key: "4",
        },
      ],
      key: "settings",
      icon: null,
      path: "/settings",
      show: true,
      onClick: null,
    },
    {
      type: "button",
      content: (
        <div className={styles.buttonGroup}>
          <CustomButton buttonStyle={`${styles.customButtonStyle}`} onClick={handleClickLogOut}>
            {t("nav.signOut")}
          </CustomButton>
        </div>
      ),
      icon: null,
      path: null,
      show: isLogin && !isMobile,
      onClick: null,
    },
    {
      type: "text",
      content: <CustomButton buttonStyle="drawer-btn">{t("nav.signOut")}</CustomButton>,
      icon: null,
      path: null,
      show: isLogin && isMobile,
      onClick: handleClickLogOut,
    },
    {
      type: "button",
      content: (
        <div className={styles.buttonGroup}>
          <CustomButton buttonStyle={styles.customButtonStyle} onClick={handleClickHamburger}>
            {t("normal.menu").toUpperCase()}
          </CustomButton>
        </div>
      ),
      icon: null,
      path: null,
      show: isMobile,
      onClick: null,
    },
    {
      type: "button",
      content: <LangSelectorPopover />,
      icon: null,
      path: null,
      show: true,
      onClick: null,
    },
  ];

  const renderContent = (_item) => {
    return (
      <>
        {_item.content}
        {_item.icon && (
          <Image
            src={_item.icon}
            alt="icon"
            preview={false}
            width={18}
            className={`${styles.icon} icon`}
          />
        )}
      </>
    );
  };

  const renderNavBar = () => {
    const renderNav = (target) => {
      return target.map((item, index) => {
        if (!item.show) return null;
        switch (item.type) {
          case "text":
            return (
              <div
                key={index}
                className={`${styles.navTitle} ${
                  isActive(pathname, item.path) ? styles.active : ""
                }`}
                onClick={() => handleClickItem(item)}
              >
                {renderContent(item)}
              </div>
            );
          case "button":
            return <div key={index}>{item.content}</div>;
          case "dropdown":
            return (
              <Dropdown
                key={index}
                menu={{ items: item.menu }}
                trigger={["click", "hover"]}
                className="nav-dropdown"
                overlayClassName="nav-overlay-dropdown"
                onOpenChange={(e) => setCurrentOpenDropDown(e ? item.key : "")}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div
                      className={`${styles.navTitle} ${
                        isActive(pathname, item.path) ? styles.active : ""
                      }`}
                    >
                      {item.content}
                      <DownOutlined rotate={currentOpenDropDown === item.key ? 180 : 0} />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            );
          default:
            return null;
        }
      });
    };
    return (
      <div className={`${styles.navBarTitleBox}`}>
        <span
          className={`${styles.navBarTitle} ${titleEllipsis ? styles.navBarTitleEllipsis : ""}`}
        >
          <div className={styles.logoContainer}>
            <Image
              src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/logo/logo-long.png`}
              alt="logo"
              preview={false}
              onClick={() => (window.location.href = "/")}
              className={styles.logo}
            />
          </div>
          {renderNav(dashboardMode ? dashboardNavEnum : navEnum)}
        </span>
      </div>
    );
  };

  const renderDrawer = (target) => {
    return target.map((item, index) => {
      if (item.type === "button" || !item.show) return null;
      switch (item.type) {
        case "text":
          return (
            <div
              key={index}
              className={`content ${isActive(pathname, item.path) ? "active" : ""}`}
              onClick={() => handleClickItem(item)}
            >
              {renderContent(item)}
            </div>
          );
        case "dropdown":
          return (
            <Dropdown
              key={index}
              menu={{ items: item.menu }}
              placement="bottomRight"
              trigger={["click", "hover"]}
              className="drawer-dropdown"
              overlayClassName="drawer-overlay-dropdown"
              onOpenChange={(e) => setCurrentOpenDropDown(e ? item.key : "")}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <div
                    className={`${styles.navTitle} ${
                      isActive(pathname, item.path) ? "active" : ""
                    }`}
                  >
                    {item.content}
                    <DownOutlined rotate={currentOpenDropDown === item.key ? 180 : 0} />
                  </div>
                </Space>
              </a>
            </Dropdown>
          );
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    setMainLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    const isDashboardModePath = [
      "/message",
      "/dashboard",
      "/user/gift",
      "/my-contract",
      "/my-deposits",
      "/withdraw",
      "/my-withdrawals",
      "/transactions",
      "/earnings",
      "/commissions",
      "/commissions/details",
      "/wallet-address",
      "/login-password",
      "/payment-password",
      "/referrals",
      "/referrals/affiliate",
    ];
    setDashboardMode(isDashboardModePath.includes(pathname) || pathname.includes("/deposit"));
  }, [pathname]);

  return (
    <>
      <Col span={span}>{renderNavBar()}</Col>
      <Drawer
        // placement={"top"}
        width={"auto"}
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
        height={"auto"}
        rootClassName="navbar-drawer"
      >
        {renderDrawer(dashboardMode ? dashboardNavEnum : navEnum)}
      </Drawer>
    </>
  );
};

export default CenterGroup;
