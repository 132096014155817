import styles from "./style.module.scss";

const TextLinearGradient = ({
  children,
  style,
  className,
  animationRangeStart,
  animationRangeEnd,
  backgroundColor1,
  backgroundColor2,
}) => {
  return (
    <span
      className={`${styles.textLinearGradient} ${className}`}
      style={{
        ...style,
        animationRangeStart: `cover ${animationRangeStart}`,
        animationRangeEnd: `cover ${animationRangeEnd}`,
        backgroundImage: `linear-gradient(90deg, ${backgroundColor1}, ${backgroundColor2})`,
      }}
    >
      {children}
    </span>
  );
};

export default TextLinearGradient;
