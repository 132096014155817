import { useTranslation } from "react-i18next";
import { RecordCards } from "../../../../../../components";
import { columns, epcColumns } from "../../../../enumerations/columns.js";

const Details = ({ data, hasNextPage, fetchNextPage, isFetchingNextPage, radioValue }) => {
  const { t } = useTranslation();

  return (
    <RecordCards
      data={data}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      columns={radioValue === "4" ? epcColumns(t) : columns(t)}
    />
  );
};

export default Details;
