import { Pagination } from "antd";
import { InfiniteScroll } from "antd-mobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlogCards, InfiniteScrollContent, NormalTitle } from "../../components";
import NoData from "../../components/NoData";
import { useLoading } from "../../context/LoadingContext";
import { useMobile } from "../../context/MobileContext";
import { getNextPageParam, infiniteDataProcess, loadMore } from "../../plugins/utils";
import { useGetBlog, useGetBlogInfinite } from "./hooks/index.hook";
import styles from "./style.module.scss";
import "./style.scss";

const Blog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const { setMainLoading } = useLoading();
  const [localData, setLocalData] = useState([]);
  const [firstPageData, setFirstPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useGetBlog({
    params: { page: currentPage },
    options: { enabled: !isMobile },
  });
  const {
    data: dataInfinite,
    isFetching: isFetchingInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetBlogInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
  });

  const handleReadMore = (targetData) => {
    navigate(`/blog/${targetData.id}`, { state: firstPageData });
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
    const contentContainer = document.getElementById("contentContainer");
    contentContainer?.scrollTo(0, 0);
  };

  const renderCards = () => {
    if (isMobile) {
      return localData?.length > 0 ? (
        <>
          <BlogCards data={localData} handleReadMore={handleReadMore} />
          <InfiniteScroll
            threshold={20}
            loadMore={() => loadMore(isFetchingNextPage, localData, fetchNextPage)}
            hasMore={hasNextPage && localData.length > 0}
          >
            <InfiniteScrollContent hasMore={hasNextPage && localData.length > 0} />
          </InfiniteScroll>
        </>
      ) : (
        <NoData title={t("normal.noData")} />
      );
    } else {
      return data?.data.length > 0 ? (
        <>
          <BlogCards data={data?.data} handleReadMore={handleReadMore} />
          <Pagination
            defaultCurrent={currentPage}
            pageSize={data?.per_page}
            total={data?.total}
            className="blogs-pagination"
            onChange={handleChangePage}
          />
        </>
      ) : (
        <NoData title={t("normal.noData")} />
      );
    }
  };

  useEffect(() => {
    if (dataInfinite) {
      infiniteDataProcess(dataInfinite, setLocalData);
    }
  }, [dataInfinite]);

  useEffect(() => {
    if (!data && !dataInfinite) return;
    console.log("blogData", data);
    if (currentPage === 1) setFirstPageData(data?.data);
    console.log("blogDataInfinite", dataInfinite);
  }, [data, dataInfinite]);

  useEffect(() => {
    setMainLoading(isFetchingInfinite || isFetching);
  }, [isFetchingInfinite, isFetching]);

  return (
    <div className={styles.blog}>
      <NormalTitle title={t("normal.blog")} />
      {renderCards()}
    </div>
  );
};

export default Blog;
