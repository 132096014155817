import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const AboutArea = () => {
  const { t } = useTranslation();

  const sectionEnum = [
    {
      title: t("about.aboutArea.title1", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
      }),
      content: t("about.aboutArea.content1", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
        year: envChangeType("REACT_APP_YEAR_FOUNDED"),
      }),
      customTitle: styles.mainTitle,
    },
    {
      title: t("about.aboutArea.title2").toUpperCase(),
      content: t("about.aboutArea.content2", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
      }),
      customTitle: "",
    },
    {
      title: t("about.aboutArea.title3").toUpperCase(),
      content: t("about.aboutArea.content3", {
        logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
      }),
      customTitle: "",
    },
  ];

  return (
    <div className={styles.aboutArea}>
      <div className={styles.top}>
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/tree.png`}
          alt="tree"
          preview={false}
          className={styles.img}
        />
      </div>
      <div className={styles.left}>
        {sectionEnum.map((item, index) => {
          return (
            <section key={index} className={styles.section}>
              <div className={`${styles.title} ${item.customTitle}`}>{item.title}</div>
              <div className={styles.content}>{item.content}</div>
            </section>
          );
        })}
      </div>
      <div className={styles.right}>
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/tree.png`}
          alt="tree"
          preview={false}
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default AboutArea;
