import styles from "./style.module.scss";

const NormalContainer = ({ children, customNormalContainerStyle, style }) => {
  return (
    <div
      className={`${styles.normalContainer} ${customNormalContainerStyle || ""}`}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default NormalContainer;
