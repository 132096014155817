import { CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import "./style.scss";

const CustomRadio = ({
  defaultValue,
  items,
  value,
  onClick,
  onClickAdd,
  buttonStyle = "solid",
  type = "button",
  showCheck = false,
}) => {
  const renderRadio = (item, index) => {
    switch (type) {
      case "button":
        return (
          <Radio.Button key={index} value={item.value} onClick={onClick}>
            {item.text}
          </Radio.Button>
        );
      case "radio":
        return item.value === "Add" ? (
          <Radio key={index} value={item.value} onClick={onClickAdd} className="add-radio">
            {showCheck && <PlusCircleOutlined className="add-icon" />}
            {item.text}
          </Radio>
        ) : (
          <Radio key={index} value={item.value} onClick={onClick}>
            {showCheck && <CheckOutlined className="check-icon" />}
            {item.text}
          </Radio>
        );
      default:
        return (
          <Radio.Button key={index} value={item.value} onClick={onClick}>
            {item.text}
          </Radio.Button>
        );
    }
  };

  return (
    <div className={`custom-radio`}>
      <Radio.Group defaultValue={defaultValue} buttonStyle={buttonStyle} value={value}>
        {items.map((item, index) => renderRadio(item, index))}
      </Radio.Group>
    </div>
  );
};

export default CustomRadio;
