import { Tabs } from "antd";
import "./style.scss";

const CustomTabs = ({
  defaultActiveKey,
  activeKey,
  type,
  size,
  tabPosition,
  items,
  onTabClick,
}) => {
  return (
    <div className="custom-tab">
      <Tabs
        defaultActiveKey={defaultActiveKey}
        activeKey={activeKey}
        type={type}
        size={size}
        tabPosition={tabPosition}
        items={items}
        onTabClick={onTabClick}
      />
    </div>
  );
};

export default CustomTabs;
