/* eslint-disable no-irregular-whitespace */
import { Image } from "antd";
import { useEffect, useState } from "react";
import FastMarquee from "react-fast-marquee";
import { envChangeType } from "../../../../plugins/utils";
import { useApiData } from "../../context/ApiDataContext";
import styles from "./style.module.scss";

function Marquee({}) {
  const [notice, setNotice] = useState("");

  const { data } = useApiData();

  const getMarquee = (list) => {
    let processNotice = list.map((item) => {
      return item.content;
    });
    const spaceCode =
      "\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0";
    if (processNotice.length === 1) {
      setNotice(`${processNotice[0]}${spaceCode}`);
    } else {
      setNotice(processNotice?.join(spaceCode) + spaceCode);
    }
  };

  useEffect(() => {
    if (data && data.notices_list) {
      getMarquee(data.notices_list);
    }
  }, [data]);

  return (
    <div className={`${styles.marquee}`}>
      <div className={`${styles.marqueeTextContainer}`}>
        <div className={styles.icon}>
          <Image
            src={`/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/home/marquee/notice.png`}
            alt="notice"
            preview={false}
            className={styles.img}
          />
        </div>
        <FastMarquee delay={2} speed={80}>
          {notice}
        </FastMarquee>
      </div>
    </div>
  );
}

export default Marquee;
