import { Card, Image } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import "./style.scss";

const NormalCards = ({
  data,
  watermark,
  vertical,
  customCardStyle = "",
  customTitleStyle = "",
  customContentStyle = "",
}) => {
  const { t } = useTranslation();

  const renderCard = (item, index) => {
    return (
      <Card
        className={`${styles.card} ${customCardStyle} normal-card`}
        key={index}
        cover={
          vertical && <Image src={item.src} alt={item.key} preview={false} className="cover-img" />
        }
      >
        <div className={styles.imgContainer}>
          {!vertical && (
            <Image src={item.src} alt={item.key} preview={false} className={styles.img} />
          )}
          <div className={`${styles.title} ${customTitleStyle}`}>{item.title}</div>
        </div>
        <div className={styles.container}>
          <div className={`${styles.content} ${customContentStyle}`}>{item.content}</div>
        </div>
        {watermark && (
          <div className="watermark">
            <Image
              src={item.watermarkSrc}
              alt={item.key}
              preview={false}
              className="watermark-img"
            />
          </div>
        )}
      </Card>
    );
  };

  return data.map((item, index) => renderCard(item, index));
};

export default NormalCards;
