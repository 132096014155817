import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomButton, NormalTitle } from "../../../../components";
import styles from "./style.module.scss";

const Partner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.partner}>
      <NormalTitle title={t("affiliate.partner.title")} />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: t("affiliate.partner.content") }}
      />
      <CustomButton onClick={() => navigate("/signin")}>{t("affiliate.partner.btn")}</CustomButton>
    </div>
  );
};

export default Partner;
