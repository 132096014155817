import { DotLoading } from "antd-mobile";
import { useTranslation } from "react-i18next";

const InfiniteScrollContent = ({ hasMore }) => {
  const { t } = useTranslation();
  return (
    <>
      {hasMore ? (
        <>
          <DotLoading />
        </>
      ) : (
        <span>{t("normal.endofdata")}</span>
      )}
    </>
  );
};

export default InfiniteScrollContent;
