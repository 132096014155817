import { CopyOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ComputerButton,
  CustomTable,
  FillModal,
  NormalTitle,
  RecordCards,
} from "../../../../../../components";
import { useMobile } from "../../../../../../context/MobileContext";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import {
  copyText,
  envChangeType,
  formatDate,
  getNextPageParam,
} from "../../../../../../plugins/utils";
import { useGetRechargeRecord, useGetRechargeRecordInfinite } from "../../../../hooks/index.hook";
import styles from "./style.module.scss";

const Record = ({ items, radioValue }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const { data: rechargeRecordData, isFetching: isFetchingRechargeRecord } = useGetRechargeRecord({
    params: { status: 0, page: currentPage },
    options: { enabled: radioValue === items[1].value && !isMobile },
  });
  const {
    data: rechargeRecordDataInfinite,
    isFetching: isFetchingRechargeRecordInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetRechargeRecordInfinite({
    options: {
      getNextPageParam,
      enabled: radioValue === items[1].value && isMobile,
    },
    params: {
      status: 0,
      page_size: 20,
    },
  });

  const handleClickDetail = (record) => {
    setOpenModal(true);
    setModalData({
      title: t("label.details"),
      content: (
        <div className={styles.content}>
          {columns.map((item, index) => {
            if (item.hideInDetail) return null;
            return (
              <div className={styles.row} key={index}>
                <div>{`${item.title} : `}</div>
                <div>
                  {item.render ? item.render(record[item.dataIndex]) : record[item.dataIndex]}
                </div>
              </div>
            );
          })}
        </div>
      ),
      isHtml: false,
      buttonText: t("normal.close"),
      showCloseIcon: true,
    });
  };

  const columns = [
    {
      title: t("label.txid").toUpperCase(),
      dataIndex: "sn",
      key: "sn",
      hideInDetail: false,
      render: (dom) => (
        <div className={styles.copyContainer} onClick={() => copyText(dom)}>
          {dom}
          <span className={styles.copyIcon}>
            <CopyOutlined />
          </span>
        </div>
      ),
    },
    {
      title: t("label.depositMethod").toUpperCase(),
      dataIndex: "name",
      key: "name",
      hideInDetail: false,
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "money",
      dataIndex: "money",
      hideInDetail: false,
      render: (dom) =>
        `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(dom)}`,
    },
    {
      title: t("label.status").toUpperCase(),
      dataIndex: "audit_status_text",
      key: "audit_status_text",
      hideInDetail: false,
    },
    {
      title: t("label.time").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      hideInDetail: false,
      render: (dom) => formatDate(dom),
    },
    {
      title: t("label.remark").toUpperCase(),
      dataIndex: "memo",
      key: "memo",
      hideInDetail: false,
      render: (dom) => dom || "-",
    },
    {
      title: t("label.details").toUpperCase(),
      key: "details",
      hideInDetail: true,
      fixed: "right",
      render: (_, record) => (
        <ComputerButton record={record} onClickRead={() => handleClickDetail(record)} />
      ),
    },
  ];

  useEffect(() => {
    if (!rechargeRecordDataInfinite && !rechargeRecordData) return;
    console.log("rechargeRecordDataInfinite", rechargeRecordDataInfinite);
    console.log("rechargeRecordData", rechargeRecordData);
  }, [rechargeRecordDataInfinite, rechargeRecordData]);

  return (
    <div className={styles.record}>
      <NormalTitle title={t("nav.myDeposits")} />
      {isMobile ? (
        <RecordCards
          data={rechargeRecordDataInfinite}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loading={isFetchingRechargeRecordInfinite}
          columns={columns}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={rechargeRecordData?.data}
          setCurrentPage={setCurrentPage}
          loading={isFetchingRechargeRecord}
          scroll={{
            x: "max-content",
          }}
        />
      )}

      <FillModal open={openModal} setOpen={setOpenModal} data={modalData} />
    </div>
  );
};

export default Record;
