import { Collapse, Image } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "../../../../components";
import { useMobile } from "../../../../context/MobileContext";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import { columns, epcColumns } from "../../enumerations/columns";
import { useGetTeamListDetail } from "../../hooks/index.hook";
import eqc from "../../images/eqc.svg";
import lv1 from "../../images/lv1.svg";
import lv2 from "../../images/lv2.svg";
import lv3 from "../../images/lv3.svg";
import styles from "./style.module.scss";
import "./style.scss";

const TeamCollapse = ({ data = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [activeKey, setActiveKey] = useState([]);
  const [targetKey, setTargetKey] = useState("1");
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const collapseKeyEnum = {
    LV1: 1,
    LV2: 2,
    LV3: 3,
    EQC: 4,
  };

  const itemsEnum = {
    [collapseKeyEnum.LV1]: {
      key: 1,
      src: lv1,
      name: "lv1",
      needText: true,
      extraValue: data?.one || 0,
      columns: columns(t),
      page: 1,
    },
    [collapseKeyEnum.LV2]: {
      key: 2,
      src: lv2,
      name: "lv2",
      needText: true,
      extraValue: data?.two || 0,
      columns: columns(t),
      page: 1,
    },
    [collapseKeyEnum.LV3]: {
      key: 3,
      src: lv3,
      name: "lv3",
      needText: true,
      extraValue: data?.three || 0,
      columns: columns(t),
      page: 1,
    },
    [collapseKeyEnum.EQC]: {
      key: 4,
      src: eqc,
      name: "eqc",
      needText: false,
      extraValue:
        t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`) +
        " " +
        moneyFormat(data?.count_price || 0),
      columns: epcColumns(t),
      page: currentPage,
    },
  };

  const {
    data: detail,
    refetch,
    isFetching,
  } = useGetTeamListDetail({
    options: { enabled: false },
    params: { type: activeKey[0], page: itemsEnum[Number(activeKey[0])]?.page },
  });

  const items = () => {
    let result = [];
    const collapseSort = [
      collapseKeyEnum.EQC,
      collapseKeyEnum.LV1,
      collapseKeyEnum.LV2,
      collapseKeyEnum.LV3,
    ];
    collapseSort.map((sort) => {
      result.push({
        key: sort,
        label: (
          <div className={styles.label}>
            <Image
              src={itemsEnum[sort].src}
              alt={itemsEnum[sort].name}
              preview={false}
              className={styles.img}
            />
            <div className={styles.extra}>
              <div>{itemsEnum[sort].extraValue}</div>
              {itemsEnum[sort].needText && (
                <div className={styles.text}>{t("myTeam.recharge.member")}</div>
              )}
            </div>
          </div>
        ),
        children: (
          <CustomTable
            columns={itemsEnum[sort].columns}
            data={detail}
            loading={isFetching}
            setCurrentPage={setCurrentPage}
            className={"commissions-table"}
          />
        ),
      });
      return true;
    });
    return result;
  };

  const handleChangeTab = async (key) => {
    if (isMobile) {
      navigate("/commissions/details", { state: key[0] });
    } else {
      await setActiveKey(key);
      if (key.length > 0) refetch();
    }
  };

  useEffect(() => {
    console.log(detail);
  }, [detail]);

  useEffect(() => {
    if (isMobile) setActiveKey([]);
  }, [isMobile]);

  return (
    <div className={`${styles.teamCollapse} team-collapse`}>
      <Collapse
        accordion
        size="large"
        items={items()}
        bordered={false}
        activeKey={activeKey}
        onChange={(e) => handleChangeTab(e)}
        expandIconPosition="end"
      />
    </div>
  );
};

export default TeamCollapse;
