import { useTranslation } from "react-i18next";
import { envChangeType } from "../../../../plugins/utils";
import ShareTable from "../ShareTable";
import styles from "./style.module.scss";

const Rewards = () => {
  const { t } = useTranslation();

  const data = {
    okaliomining: [
      {
        type: "label",
        invite: t("label.invite"),
        vipGrade: t("label.vipGrade"),
        monthlyRewards: t("label.monthlyRewards"),
      },
      {
        type: "cell",
        invite: 10,
        vipGrade: 1,
        monthlyRewards: 100,
      },
      {
        type: "cell",
        invite: 20,
        vipGrade: 1,
        monthlyRewards: 200,
      },
      {
        type: "cell",
        invite: 30,
        vipGrade: 1,
        monthlyRewards: 300,
      },
      {
        type: "cell",
        invite: 50,
        vipGrade: 1,
        monthlyRewards: 600,
      },
      {
        type: "cell",
        invite: 100,
        vipGrade: 1,
        monthlyRewards: 1200,
      },
      {
        type: "cell",
        invite: 300,
        vipGrade: 1,
        monthlyRewards: 3500,
      },
      {
        type: "cell",
        invite: 500,
        vipGrade: 1,
        monthlyRewards: 6000,
      },
    ],
    default: [
      {
        type: "label",
        invite: t("label.invite"),
        vipGrade: t("label.vipGrade"),
        monthlyRewards: t("label.monthlyRewards"),
      },
      {
        type: "cell",
        invite: 8,
        vipGrade: 1,
        monthlyRewards: 1000,
      },
      {
        type: "cell",
        invite: 22,
        vipGrade: 1,
        monthlyRewards: 2000,
      },
      {
        type: "cell",
        invite: 45,
        vipGrade: 1,
        monthlyRewards: 8000,
      },
      {
        type: "cell",
        invite: 88,
        vipGrade: 1,
        monthlyRewards: 20000,
      },
      {
        type: "cell",
        invite: 165,
        vipGrade: 1,
        monthlyRewards: 60000,
      },
    ],
  };

  const columns = [
    {
      title: t("label.invite"),
      dataIndex: "invite",
      key: "invite",
      width: "33%",
      render: (dom) => t("share.rewards.invite", { num: dom }),
    },
    {
      title: t("label.vipGrade"),
      dataIndex: "vipGrade",
      key: "vipGrade",
      width: "33%",
      render: (dom) => {
        return `VIP${dom}`;
      },
    },
    {
      title: t("label.monthlyRewards"),
      dataIndex: "monthlyRewards",
      key: "monthlyRewards",
      width: "34%",
      render: (dom) => {
        return (
          <div className={styles.specialTips}>{`${t(
            `currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`,
          )}${dom}`}</div>
        );
      },
    },
  ];

  return (
    <div className={styles.rewards}>
      <div
        className={styles.subTitle}
        dangerouslySetInnerHTML={{ __html: t("share.rewards.subTitle") }}
      />
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: t("share.rewards.content") }}
      />
      <ShareTable
        data={
          data[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")]
            ? data[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")]
            : data["default"]
        }
        columns={columns}
      />
    </div>
  );
};

export default Rewards;
