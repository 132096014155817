import { CopyOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomTable, NormalTitle, RecordCards } from "../../../../../../components";
import { useMobile } from "../../../../../../context/MobileContext";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import {
  copyText,
  envChangeType,
  formatDate,
  getNextPageParam,
} from "../../../../../../plugins/utils";
import { useGetWithdrawRecord, useGetWithdrawRecordInfinite } from "../../../../hooks/index.hook";
import styles from "./style.module.scss";

const Record = ({ items, radioValue }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: withdrawRecordData, isFetching: isFetchingWithdrawRecord } = useGetWithdrawRecord({
    params: { status: 0, page: currentPage },
    options: { enabled: radioValue === items[1].value },
  });

  const {
    data: withdrawRecordDataInfinite,
    isFetching: isFetchingWithdrawRecordInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetWithdrawRecordInfinite({
    options: {
      getNextPageParam,
      enabled: radioValue === items[1].value && isMobile,
    },
    params: {
      status: 0,
      page_size: 20,
    },
  });

  const columns = [
    {
      title: t("label.txid").toUpperCase(),
      dataIndex: "sn",
      key: "sn",
      render: (dom) => (
        <div className={styles.copyContainer} onClick={() => copyText(dom)}>
          {dom}
          <span className={styles.copyIcon}>
            <CopyOutlined />
          </span>
        </div>
      ),
    },
    {
      title: t("label.depositMethod").toUpperCase(),
      dataIndex: "bankname",
      key: "bankname",
    },
    {
      title: t("nav.walletAddress").toUpperCase(),
      dataIndex: "user_address",
      key: "user_address",
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "money",
      dataIndex: "money",
      render: (dom) =>
        `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(dom)}`,
    },
    {
      title: t("label.status").toUpperCase(),
      dataIndex: "audit_status_text",
      key: "audit_status_text",
    },
    {
      title: t("label.time").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => formatDate(dom),
    },
    {
      title: t("label.remark").toUpperCase(),
      dataIndex: "memo",
      key: "memo",
      render: (dom) => dom || "-",
    },
  ];

  useEffect(() => {
    if (!withdrawRecordDataInfinite && !withdrawRecordData) return;
    console.log("withdrawRecordDataInfinite,withdrawRecordDataInfinite");
    console.log("withdrawRecordData", withdrawRecordData);
  }, [withdrawRecordDataInfinite, withdrawRecordData]);

  return (
    <div className={styles.record}>
      <NormalTitle title={t("nav.myWithdrawals")} />
      {isMobile ? (
        <RecordCards
          data={withdrawRecordDataInfinite}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loading={isFetchingWithdrawRecordInfinite}
          columns={columns}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={withdrawRecordData?.data}
          setCurrentPage={setCurrentPage}
          loading={isFetchingWithdrawRecord}
        />
      )}
    </div>
  );
};

export default Record;
