import { Radio } from "antd-mobile";
import styles from "./style.module.scss";
import "./style.scss";

const NormalRadio = ({ checked, setChecked, content, customRadioContainerStyle = "" }) => {
  return (
    <div className={`${styles.radioContainer} ${customRadioContainerStyle} radio-container`}>
      <Radio checked={checked} onClick={() => setChecked(!checked)}>
        {content}
      </Radio>
    </div>
  );
};

export default NormalRadio;
