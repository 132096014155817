import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ComputerButton,
  CustomTable,
  FillModal,
  NormalTitle,
  RecordCards,
} from "../../../../components";
import { useMobile } from "../../../../context/MobileContext";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType, formatDate } from "../../../../plugins/utils";
import { useGetMyProjectDetail } from "../../hooks/index.hook";
import styles from "./style.module.scss";

const MyProject = ({
  data,
  setCurrentPage,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  loading = false,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [targetData, setTargetData] = useState({});

  const { data: detailData, isFetching } = useGetMyProjectDetail({
    options: { enabled: !!targetData && openModal },
    params: { user_facility_id: targetData?.id },
  });

  const handleClickDetail = (record) => {
    setOpenModal(true);
    setTargetData(record);
  };

  const columns = [
    {
      title: t("label.txidTime").toUpperCase(),
      dataIndex: "buytiime",
      key: "buytiime",
      render: (dom) => formatDate(dom),
    },
    {
      title: t("nav.project").toUpperCase(),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("label.status").toUpperCase(),
      dataIndex: "status",
      key: "status",
      render: (dom) => t(`myContract.status${dom}`),
    },
    {
      title: t("label.return").toUpperCase(),
      dataIndex: "way_charge",
      key: "way_charge",
      render: (dom) =>
        `${moneyFormat(dom)} ${t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}`,
    },
    {
      title: t("project.detail.totalIncome").toUpperCase(),
      dataIndex: "totalIncome",
      key: "totalIncome",
      render: (_, record) =>
        `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
          record.money,
        )} + ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
          record.way_charge * (record.cycle / 24),
        )}`,
    },
    {
      title: t("project.detail.cycle").toUpperCase(),
      key: "cycle",
      dataIndex: "cycle",
      render: (dom) => dom / 24 + " " + t("project.card.days"),
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "money",
      dataIndex: "money",
      render: (dom) =>
        `${moneyFormat(dom)} ${t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}`,
    },
    {
      title: t("label.productDetails").toUpperCase(),
      fixed: "right",
      key: "details",
      render: (_, record) => (
        <ComputerButton record={record} onClickRead={() => handleClickDetail(record)} />
      ),
    },
  ];

  const detailColumns = [
    {
      title: t("nav.project").toUpperCase(),
      dataIndex: "name",
      key: "name",
      render: () => targetData?.name,
    },
    {
      title: t("label.amount").toUpperCase(),
      key: "buy_money",
      dataIndex: "buy_money",
      render: (dom) =>
        `${moneyFormat(dom)} ${t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}`,
    },
    {
      title: t("label.id").toUpperCase(),
      key: "no",
      dataIndex: "no",
    },
    {
      title: t("label.status").toUpperCase(),
      dataIndex: "bonus_status_text",
      key: "bonus_status_text",
    },
    {
      title: t("label.return").toUpperCase(),
      dataIndex: "total_bonus",
      key: "total_bonus",
      render: (dom) =>
        `${moneyFormat(dom)} ${t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}`,
    },
    {
      title: t("label.return").toUpperCase(),
      dataIndex: "contract",
      key: "contract",
      isHtml: true,
    },
  ];

  useEffect(() => {
    if (!data) return;
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (isFetching || !detailData) return;
    setModalData({
      title: t("label.details"),
      content: (
        <div className={styles.content}>
          <img src={targetData.pictureimage} alt="img" className={styles.img} />
          {detailColumns.map((item, index) => {
            if (item.hideInDetail) return null;
            if (item.isHtml) {
              return (
                <div
                  key={index}
                  className={styles.row}
                  dangerouslySetInnerHTML={{ __html: detailData[item.dataIndex] }}
                />
              );
            } else {
              return (
                <div key={index} className={styles.row}>{`${item.title} : ${
                  item.render
                    ? item.render(detailData[item.dataIndex], detailData)
                    : detailData[item.dataIndex]
                }`}</div>
              );
            }
          })}
        </div>
      ),
      isHtml: false,
      buttonText: t("normal.close"),
      showCloseIcon: true,
    });
  }, [isFetching]);

  return (
    <div className={styles.project}>
      <NormalTitle title={t("nav.myProject")} />
      {isMobile ? (
        <RecordCards
          data={data}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loading={loading}
          columns={columns}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          setCurrentPage={setCurrentPage}
          scroll={{
            x: "max-content",
          }}
        />
      )}

      <FillModal open={openModal} setOpen={setOpenModal} data={modalData} loading={isFetching} />
    </div>
  );
};

export default MyProject;
