import { Image, Space } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetApp } from "../../../hooks/download/download.hook";
import { useGetData } from "../../../hooks/indexData/indexData.hook";
import { envChangeType, handleClickApp } from "../../../plugins/utils";
import styles from "./style.module.scss";
const Footer = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetData();

  const { refetch, isFetching } = useGetApp({ enabled: false });

  const itemEnum = [
    {
      content: t("footer.item.site"),
      subContent: [
        { title: t("footer.item.project"), type: "navigate", path: "/project", show: true },
        { title: t("nav.about"), type: "navigate", path: "/about", show: true },
        { title: t("nav.welfareCenter"), type: "navigate", path: "/share", show: true },
        { title: t("normal.blog"), type: "navigate", path: "/blog", show: true },
        { title: t("normal.tutorial"), type: "navigate", path: "/tutorial", show: true },
        { title: t("home.faq"), type: "navigate", path: "/faq", show: true },
      ],
    },
    {
      content: t("footer.item.important"),
      subContent: [
        { title: t("footer.item.privacy"), type: "windowOpen", path: "/privacy", show: true },
        { title: t("footer.item.terms"), type: "windowOpen", path: "/terms", show: true },
        // {  title: t("footer.item.cs"), type: "windowOpen", path: "/project", show: true },
      ],
    },
    {
      content: t("nav.app"),
      subContent: [
        {
          title: (
            <Space>
              <img src={"/images/utils/footer/googlePlay.png"} alt="googlePlay" width={110} />
              <img src={"/images/utils/footer/appleStore.png"} alt="appleStore" width={110} />
            </Space>
          ),
          type: "app-download",
          path: null,
          show: true,
        },
        {
          title: (
            <Space className={styles.marginTop} size={"middle"}>
              <img src={"/images/utils/footer/androidf.png"} alt="android" width={100} />
              <img src={"/images/utils/footer/iphonef.png"} alt="iphone" width={100} />
            </Space>
          ),
          type: "app",
          path: null,
          show: true,
        },
      ],
    },
  ];

  const handleClickItem = (subItem) => {
    switch (subItem.type) {
      case "navigate":
        navigate(subItem.path);
        break;
      case "windowOpen":
        window.open(subItem.path, "_blank");
        break;
      case "app":
        handleClickApp("navigate");
        break;
      case "app-download":
        handleClickApp("app-download", refetch);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.leftContainer}>
            <Image
              src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/logo/logo-long.png`}
              alt="logo"
              preview={false}
              className={styles.logo}
            />
            {t("footer.text", { logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY") })}
          </div>
          <div className={styles.rightContainer}>
            {itemEnum.map((item, index) => {
              return (
                <div key={index} className={styles.itemGroup}>
                  <div className={styles.itemTitle}>{item.content}</div>
                  <div className={styles.itemOptions}>
                    {item.subContent.map((subItem, _index) => {
                      if (!subItem.show) return <div key={_index} />;
                      return (
                        <div
                          className={styles.itemOption}
                          key={_index}
                          onClick={() => handleClickItem(subItem)}
                        >
                          {subItem.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.bottom}>
          {t("footer.epilog", {
            logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
            start_year: envChangeType("REACT_APP_YEAR_FOUNDED"),
            end_year: dayjs().year(),
          })}
        </div>
        <div className={styles.socialMedia}>
          <div className={styles.mediaContainer}>
            <div className={styles.iconContainer}>
              <div className={styles.iconGroup}>
                <div
                  className={styles.icon}
                  onClick={() =>
                    data?.company_info.facebook && window.open(data?.company_info.facebook)
                  }
                >
                  <Image
                    src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/fb.png`}
                    alt="fb"
                    preview={false}
                    width={40}
                  />{" "}
                </div>
                <div
                  className={styles.icon}
                  onClick={() =>
                    data?.company_info.twitter && window.open(data?.company_info.twitter)
                  }
                >
                  <Image
                    src={`/images/${envChangeType(
                      "REACT_APP_PRODUCTION_NAME_DEV",
                    )}/footer/x-app.png`}
                    alt="xApp"
                    preview={false}
                    width={40}
                  />
                </div>
              </div>
              <div className={styles.address}>{data?.company_info["Registered Office"]}</div>
              <div>
                {t("footer.email") + " "}
                <a className={styles.email} href={`mailto:${data?.company_info.email}`}>
                  {data?.company_info.email}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
