import { useTranslation } from "react-i18next";
import { NormalCards } from "../../../../components";
import { envChangeType, envType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const ChooseArea = () => {
  const { t } = useTranslation();

  const cardsEnum = [
    {
      key: "card1",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-1.png`,
      title: t("about.choose.card.title1"),
      content: t("about.choose.card.content1"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-1.png`,
    },
    {
      key: "card2",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-2.png`,
      title: t("about.choose.card.title2"),
      content: t("about.choose.card.content2"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-2.png`,
    },
    {
      key: "card3",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-3.png`,
      title: t("about.choose.card.title3"),
      content: t("about.choose.card.content3"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-3.png`,
    },
    {
      key: "card4",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-4.png`,
      title: t("about.choose.card.title4"),
      content: t("about.choose.card.content4"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-4.png`,
    },
    {
      key: "card5",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-5.png`,
      title: t("about.choose.card.title5"),
      content: t("about.choose.card.content5"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-5.png`,
    },
    {
      key: "card6",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/chooseArea/card-6.png`,
      title: t("about.choose.card.title6"),
      content: t("about.choose.card.content6"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/about/chooseArea/watermark-6.png`,
    },
  ];

  return (
    <div
      className={`${styles.chooseAreaBg}`}
      style={{
        background: envChangeType("REACT_APP_ABOUT_EXTRA_BG", envType.BOOLEAN)
          ? `url('/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/about/chooseArea/choose-area-bg.png')`
          : "",
      }}
    >
      <div className={`${styles.chooseArea}`}>
        <div className={styles.title}>{t("about.choose.title")}</div>
        <div className={styles.subTitle}>{t("about.choose.subTitle")}</div>
        <div className={styles.cardsContainer}>
          <NormalCards data={cardsEnum} watermark={true} />
        </div>
      </div>
    </div>
  );
};

export default ChooseArea;
