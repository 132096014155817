import React from "react";
import { HomeContainer } from "./components";
import { ApiDataContextProvider } from "./context/ApiDataContext";

const Home = () => {
  return (
    <ApiDataContextProvider>
      <HomeContainer />
    </ApiDataContextProvider>
  );
};

export default Home;
