import { useTranslation } from "react-i18next";
import { envChangeType } from "../../plugins/utils";
import styles from "./style.module.scss";

const UserInfo = ({ userInfo }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.userInfo}>
      <div className={styles.container}>
        <div>
          <div
            className={styles.id}
            dangerouslySetInnerHTML={{
              __html: t("user.id", { id: userInfo?.uid || "PLEASE_LOGIN" }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t("user.account", { account: userInfo?.mobile || "PLEASE_LOGIN" }),
            }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: t("user.level", { level: userInfo?.level || 0 }) }}
          />
        </div>
        <div className={styles.wallet}>
          {t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`) +
            (userInfo?.money || 0)}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
