import {
  ProFormDigit,
  ProFormSelect,
  ProFormText,
  ProFormUploadButton,
} from "@ant-design/pro-components";
import { useTranslation } from "react-i18next";
import NoData from "../NoData";
import "./style.scss";

const CustomInput = ({
  type = "text",
  name,
  label,
  placeholder,
  onChange,
  rules,
  min = 0,
  max,
  readOnly = false,
  initialValue = "",
  addonAfter,
  value,
  precision = 2,
  request,
  valueEnum = [],
  className = "",
  disabled,
  customRequest,
  fileList = [],
  setFileList,
}) => {
  const { t } = useTranslation();
  const renderInput = () => {
    switch (type) {
      case "text":
        return (
          <ProFormText
            name={name}
            label={label}
            placeholder={placeholder}
            rules={rules}
            fieldProps={{
              onChange: onChange,
              readOnly,
              addonAfter,
              value,
            }}
            initialValue={initialValue}
            disabled={disabled}
          />
        );
      case "password":
        return (
          <ProFormText.Password
            name={name}
            label={label}
            placeholder={placeholder}
            rules={rules}
            fieldProps={{ onChange: onChange, readOnly, addonAfter, value }}
            initialValue={initialValue}
          />
        );
      case "number":
        return (
          <ProFormDigit
            name={name}
            label={label}
            placeholder={placeholder}
            rules={rules}
            fieldProps={{
              onChange: onChange,
              readOnly,
              addonAfter,
              value,
              precision,
              className,
              disabled,
            }}
            min={min}
            max={max}
            initialValue={initialValue}
            disabled={disabled}
          />
        );
      case "select":
        return (
          <ProFormSelect
            name={name}
            label={label}
            request={request}
            valueEnum={valueEnum}
            placeholder={placeholder}
            rules={rules}
            fieldProps={{
              allowClear: false,
              optionRender: (dom) => dom.label,
              notFoundContent: (
                <NoData
                  title={t("normal.noData")}
                  imgWidth={70}
                  padding={"10px 0 0 0"}
                  fontSize={"var(--fontSetting-normal)"}
                  color={"rgba(0, 0, 0, 0.4)"}
                />
              ),
            }}
            onChange={onChange}
          />
        );
      case "upload":
        return (
          <ProFormUploadButton
            name={name}
            label={label}
            fieldProps={{
              listType: "picture-card",
              customRequest,
              fileList,
              maxCount: 1,
              accept: ".png, .jpg, .jpeg",
              onRemove: (file) => setFileList(fileList.filter((item) => item.uid !== file.uid)),
            }}
            rules={rules}
            title={t("upload.title")}
          />
        );
      default:
        return <input type="text" />;
    }
  };

  return <div className="custom-input">{renderInput()}</div>;
};

export default CustomInput;
