import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, TextLinearGradient } from "../../../../components";
import { useMobile } from "../../../../context/MobileContext";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const Greener = ({ imageLeft, id = "greener" }) => {
  const { t } = useTranslation();
  const { isWidthMiddle } = useMobile();
  const [isShowImg1, setIsShowImg1] = useState();
  const [isShowImg2, setIsShowImg2] = useState();

  const imageEnum = {
    greener: {
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/greener/tree.png`,
      href: "/blog",
    },
    contract: {
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/greener/contract.png`,
      href: "/project",
    },
  };

  useEffect(() => {
    function debounce(func, delay) {
      let timeoutId;

      return function () {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(func, delay);
      };
    }
    const handleScroll = () => {
      const scrollTop = document.getElementById("contentContainer").scrollTop;
      const img1 = document.getElementById("img1");
      const img2 = document.getElementById("img2");
      const img1Top = img1?.getBoundingClientRect().top;
      const img2Top = img2?.getBoundingClientRect().top;
      if (img1Top < scrollTop && !isWidthMiddle) {
        setIsShowImg1(true);
      } else if (img1Top < scrollTop - 500 && isWidthMiddle) {
        setIsShowImg1(true);
      } else {
        setIsShowImg1(false);
      }

      if (img2Top < scrollTop && !isWidthMiddle) {
        setIsShowImg2(true);
      } else if (img2Top < scrollTop - 500 && isWidthMiddle) {
        setIsShowImg2(true);
      } else {
        setIsShowImg2(false);
      }
    };
    // 监听
    window.addEventListener("scroll", debounce(handleScroll, 10), true);
    // 销毁
    return () => window.removeEventListener("scroll", handleScroll, true);
  });

  const renderLeft = (
    <div className={styles.left}>
      <div className={styles.extra}>{t(`home.${id}.extra`)}</div>
      <div className={styles.title}>
        <TextLinearGradient
          animationRangeStart={isWidthMiddle ? "30vh" : "10vh"}
          animationRangeEnd={isWidthMiddle ? "40vh" : "20vh"}
          backgroundColor1={"var(--home_section_title)"}
          backgroundColor2={"var(--home_section_title)"}
        >
          {t(`home.${id}.title`)}
        </TextLinearGradient>
      </div>
      <div className={styles.subTitle}>
        <TextLinearGradient
          animationRangeStart={isWidthMiddle ? "32vh" : "12vh"}
          animationRangeEnd={isWidthMiddle ? "50vh" : "30vh"}
          backgroundColor1={"var(--text_content)"}
          backgroundColor2={"var(--text_content)"}
        >
          {t(`home.${id}.subTitle`)}
        </TextLinearGradient>
      </div>
      <CustomButton
        buttonStyle={`${styles.customButtonStyle}`}
        onClick={() => (window.location.href = imageEnum[id].href)}
      >
        {t(`home.${id}.btn`)}
      </CustomButton>
    </div>
  );

  const renderRight = (name, className, isShow) => {
    return (
      <div className={styles.right}>
        <img
          src={imageEnum[id].src}
          alt={name}
          className={`${styles.img} ${className} ${isShow ? styles.show : ""}`}
          id={name}
        />
      </div>
    );
  };

  return (
    <div className={styles.greener}>
      {imageLeft ? (
        <>
          {renderRight("img2", styles.img2, isShowImg2)}
          {renderLeft}
        </>
      ) : (
        <>
          {renderLeft}
          {renderRight("img1", styles.img1, isShowImg1)}
        </>
      )}
    </div>
  );
};

export default Greener;
