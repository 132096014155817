import { moneyFormat } from "../../../plugins/numberFormat";
import { envChangeType } from "../../../plugins/utils";

export const epcColumns = (t) => [
  {
    title: t("label.commission").toUpperCase(),
    dataIndex: "money",
    key: "money",
    render: (dom) => moneyFormat(dom),
  },
  {
    title: t("label.equipment").toUpperCase(),
    dataIndex: "buy_money",
    key: "buy_money",
    render: (dom) => moneyFormat(dom),
  },
  {
    title: t("label.note").toUpperCase(),
    key: "ext",
    dataIndex: "ext",
  },
];

export const columns = (t) => [
  {
    title: t("label.name").toUpperCase(),
    dataIndex: "mobile",
    key: "mobile",
  },
  {
    title: t("label.vip").toUpperCase(),
    dataIndex: "level",
    key: "level",
  },
  {
    title: t("label.amount").toUpperCase(),
    dataIndex: "price",
    key: "price",
    render: (dom) =>
      t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`) + moneyFormat(dom),
  },
];
