import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NormalTitle from "../../components/NormalTitle";
import { useLoading } from "../../context/LoadingContext";
import { envChangeType } from "../../plugins/utils";
import { SignUpForm } from "./components";
import styles from "./style.module.scss";

const Signup = () => {
  const { t } = useTranslation();
  const { setMainLoading } = useLoading();

  useEffect(() => {
    setMainLoading(false);
  }, []);

  return (
    <div className={styles.signup}>
      <NormalTitle title={t("normal.signUp")} />
      <div
        className={styles.mainBg}
        style={{
          backgroundImage: `url('/images/${envChangeType(
            "REACT_APP_PRODUCTION_NAME_DEV",
          )}/signup/bg.png')`,
        }}
      >
        <div className={styles.main}>
          <div className={styles.form}>
            <div className={styles.title}>{t("normal.signUp")}</div>
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
