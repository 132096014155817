import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import {
  apiGetBankInfo,
  apiGetDigitalInfo,
  apiGetGiftRecord,
  apiPostBankInfo,
  apiPostDigitalInfo,
  apiPostGift,
  apiPostPaymentPassword,
  apiPostSafe,
} from "../service";

export const useGetBankInfo = (options) => {
  return useQuery([queryKey.BANK_INFO], () => apiGetBankInfo().then((res) => res.data.data), {
    ...options,
  });
};

export const usePostBankInfo = () => {
  return useMutation((params) => apiPostBankInfo(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetDigitalInfo = (options) => {
  return useQuery([queryKey.DIGITAL_INFO], () => apiGetDigitalInfo().then((res) => res.data.data), {
    ...options,
  });
};

export const usePostDigitalInfo = () => {
  return useMutation((params) => apiPostDigitalInfo(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetGiftRecord = ({ options, params }) => {
  return useQuery(
    [queryKey.GIFT_RECORD],
    () =>
      apiGetGiftRecord(params).then((res) => {
        let { data } = res.data;
        data = data.map((item, index) => {
          return { ...item, key: index };
        });
        return { data };
      }),
    {
      ...options,
    },
  );
};

export const useGetGiftRecordInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.GIFT_RECORD, params],
    ({ pageParam = 1 }) =>
      apiGetGiftRecord({ ...params, page: pageParam }).then((res) => {
        let { data } = res.data;
        data = data.map((item, index) => {
          return { ...item, key: index };
        });
        return { data: data };
      }),
    {
      ...options,
    },
  );
};

export const usePostGift = () => {
  return useMutation((params) => apiPostGift(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const usePostSafe = () => {
  return useMutation((params) => apiPostSafe(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const usePostPaymentPassword = () => {
  return useMutation((params) => apiPostPaymentPassword(params), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
