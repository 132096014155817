import React from "react";
import { useTranslation } from "react-i18next";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const StatisticItem = ({
  image,
  title,
  isTranslate,
  isMoney,
  path,
  data,
  onViewAll = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.statItemContainer}
      style={{
        background: `url('/images/${envChangeType(
          "REACT_APP_PRODUCTION_NAME_DEV",
        )}/dashboard/background.svg') center 50px / cover no-repeat, var(--bg-color2)`,
      }}
    >
      <div className={styles.headerContainer}>
        <img className={styles.image} src={image} alt={title} />
        <div className={styles.buttonViewAll} onClick={() => onViewAll(path)}>
          {t("normal.viewall")}
        </div>
      </div>
      <div className={styles.title}>{isTranslate === true ? t(title) : title}</div>
      <div className={styles.content}>
        {isMoney
          ? `${envChangeType("REACT_APP_DEFAULT_CURRENCY")} ${moneyFormat(data)}`
          : data || 0}
      </div>
    </div>
  );
};

export default StatisticItem;
