import { useTranslation } from "react-i18next";
import { NormalCards } from "../../../../components";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const Advantages = () => {
  const { t } = useTranslation();

  const cardsEnum = [
    {
      key: "card1",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-1.png`,
      title: t("home.advantages.card.title1"),
      content: t("home.advantages.card.content1"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-1.png`,
    },
    {
      key: "card2",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-2.png`,
      title: t("home.advantages.card.title2"),
      content: t("home.advantages.card.content2"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-2.png`,
    },
    {
      key: "card3",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-3.png`,
      title: t("home.advantages.card.title3"),
      content: t("home.advantages.card.content3"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-3.png`,
    },
    {
      key: "card4",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-4.png`,
      title: t("home.advantages.card.title4"),
      content: t("home.advantages.card.content4"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-4.png`,
    },
    {
      key: "card5",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-5.png`,
      title: t("home.advantages.card.title5"),
      content: t("home.advantages.card.content5"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-5.png`,
    },
    {
      key: "card6",
      src: `/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/home/advantages/card-6.png`,
      title: t("home.advantages.card.title6"),
      content: t("home.advantages.card.content6"),
      watermarkSrc: `/images/${envChangeType(
        "REACT_APP_PRODUCTION_NAME_DEV",
      )}/home/advantages/watermark-6.png`,
    },
  ];

  return (
    <div className={styles.advantages}>
      <NormalCards data={cardsEnum} watermark={true} />
    </div>
  );
};

export default Advantages;
