import { CopyOutlined } from "@ant-design/icons";
import { ProForm } from "@ant-design/pro-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  toast,
} from "../../../../components";
import { useLoading } from "../../../../context/LoadingContext";
import { useModal } from "../../../../context/ModalContext";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { copyText, envChangeType } from "../../../../plugins/utils";
import { usePostUpImg, usePostUpload } from "../../hooks/index.hook";
import styles from "./style.module.scss";
import "./style.scss";

const Trade = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: data } = useLocation();
  const { openTipsModal } = useModal();
  const { setMainLoading } = useLoading();
  const [fileList, setFileList] = useState([]);
  const [imgUrl, setImgUrl] = useState();

  const { mutate: Upload, isLoading } = usePostUpload();
  const { mutate: UpImg, isLoading: upImgIsLoading } = usePostUpImg();

  const handleFinish = () => {
    if (fileList.length <= 0) {
      openTipsModal({
        body: t("normal.pleaseUpload"),
      });
      return;
    }

    const form = new FormData();
    form.append("sn", data.sn);
    form.append("img", imgUrl.url);

    UpImg(
      {
        params: form,
      },
      {
        onSuccess: (success) => {
          const { code, msg, data } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          navigate("/my-deposits?radioValue=record");
        },
        onError: () => {},
      },
    );
  };

  const handleUpload = (file) => {
    const form = new FormData();
    form.append("file", file.file);
    form.append("name", file.file.name);

    Upload(
      {
        params: form,
      },
      {
        onSuccess: (success) => {
          const { msg, data } = success?.data;
          let newFileList = [];
          newFileList = newFileList.concat([
            {
              name: file.file.name,
              uid: file.file.uid,
              status: "done",
              url: success.data.data.whole_url,
            },
          ]);
          setFileList(newFileList);
          setImgUrl(data);
          toast({ content: msg, type: "success" });
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    setMainLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  useEffect(() => {
    console.log("fileList", fileList);
  }, [fileList]);

  return (
    <div className={`${styles.trade} trade`}>
      <NormalTitle title={t("trade.title")} />
      <NormalContainer>
        <div
          className={styles.payHint}
          dangerouslySetInnerHTML={{
            __html: t("trade.pay.hint", {
              depositMoney: moneyFormat(data.money) || 0,
              depositCurrency: t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
              payMoney: moneyFormat(data.predict_money, 2, 6) || 0,
              payCurrency: data?.name,
            }),
          }}
        />
        <div className={styles.paySubHint}>{t("trade.pay.subHint")}</div>
        <ProForm
          onFinish={handleFinish}
          submitter={{
            render: () => (
              <CustomButton
                buttonStyle={`${styles.customButtonStyle}`}
                type={"submit"}
                loading={upImgIsLoading}
              >
                {t("normal.paid")}
              </CustomButton>
            ),
          }}
        >
          <CustomInput
            label={t("nav.walletAddress")}
            value={data?.address}
            type="text"
            readOnly
            disabled
            className={"wallet-address-input"}
            addonAfter={
              <CopyOutlined className={styles.copy} onClick={() => copyText(data?.address)} />
            }
          />
          <CustomInput
            type="upload"
            label={t("label.upload")}
            fileList={fileList}
            setFileList={setFileList}
            customRequest={handleUpload}
          />
        </ProForm>
      </NormalContainer>
    </div>
  );
};

export default Trade;
