import { Col, Image, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { envChangeType } from "../../../plugins/utils";
import { isActive } from "../../utils";
import "./style.scss";

const FooterMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuEnum = [
    {
      inActive: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/home.png`}
          alt="home"
          preview={false}
          className="img"
        />
      ),
      active: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/home-active.png`}
          alt="home"
          preview={false}
          className="img"
        />
      ),
      name: "home",
      text: t("footer.menu.home"),
      path: "/",
    },
    {
      inActive: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/project.png`}
          alt="project"
          preview={false}
          className="img"
        />
      ),
      active: (
        <Image
          src={`/images/${envChangeType(
            "REACT_APP_PRODUCTION_NAME_DEV",
          )}/footer/project-active.png`}
          alt="project"
          preview={false}
          className="img"
        />
      ),
      name: "project",
      text: t("footer.item.project"),
      path: "/project",
    },
    {
      inActive: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/user.png`}
          alt="user"
          preview={false}
          className="img"
        />
      ),
      active: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/user-active.png`}
          alt="user"
          preview={false}
          className="img"
        />
      ),
      name: "dashboard",
      text: t("nav.user"),
      path: "/dashboard",
    },
    {
      inActive: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/share.png`}
          alt="share"
          preview={false}
          className="img"
        />
      ),
      active: (
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/footer/share-active.png`}
          alt="share"
          preview={false}
          className="img"
        />
      ),
      name: "welfareCenter",
      text: t("nav.welfareCenter"),
      path: "/share",
    },
  ];
  return (
    <div className="footer-menu">
      <Row>
        {menuEnum.map((item, index) => {
          return (
            <Col key={index} className="gutter-row" span={6}>
              <div className="item" onClick={() => navigate(item.path)}>
                {isActive(pathname, item.path) ? item.active : item.inActive}
                <div className={`text ${isActive(pathname, item.path) ? "active" : ""}`}>
                  {item.text}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default FooterMenu;
