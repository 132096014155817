import { Table } from "antd";
import React from "react";
import "./style.scss";

const CustomTable = ({
  columns,
  data,
  loading,
  setCurrentPage,
  className = "custom-table",
  pagination = {
    onChange: (e) => setCurrentPage(e),
    pageSize: data?.per_page,
    total: data?.total,
  },
  scroll,
}) => {
  return (
    <div className={className}>
      <Table
        columns={columns}
        dataSource={data?.data}
        loading={loading}
        pagination={pagination}
        scroll={scroll}
      />
    </div>
  );
};

export default CustomTable;
