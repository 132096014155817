import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
  mainLoading: false,
  setMainLoading: () => {},
});

export const LoadingContextProvider = ({ children }) => {
  const [mainLoading, setMainLoading] = useState(false);

  // const loading = useMemo(() => {
  //   return (
  //     <LoadingContext.Provider value={{ mainLoading, setMainLoading }}>
  //       {children}
  //       {/* {isLoading && <LoadingView />} */}
  //     </LoadingContext.Provider>
  //   );
  // }, [mainLoading]);

  // return loading;
  return (
    <LoadingContext.Provider value={{ mainLoading, setMainLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const Value = useContext(LoadingContext);
  return Value;
};
