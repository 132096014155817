import { ProForm } from "@ant-design/pro-components";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  SettingArea,
  toast,
} from "../../../../components";
import { useLoading } from "../../../../context/LoadingContext";
import { useModal } from "../../../../context/ModalContext";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum";
import { useGetUser } from "../../../../hooks/user/user.hook";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import { useGetProjectDetail, usePostBuy } from "../../hooks/index.hook";
import styles from "./style.module.scss";
import "./style.scss";

const Detail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef();
  const { openTipsModal } = useModal();
  const { setMainLoading } = useLoading();

  const { data, isFetching } = useGetProjectDetail({
    options: { enabled: !!id },
    params: { id: id },
  });
  const { data: userData, isFetching: isFetchingUserData } = useGetUser();
  const { mutate: buy, isLoading } = usePostBuy();

  const settingEnum = [
    {
      label: t("project.detail.cycle"),
      value: data?.cycle / 24 + " " + t("project.card.days"),
    },
    {
      label: t("project.detail.rate"),
      value: `${moneyFormat((data?.bonus / data?.money) * 100)}%`,
    },
    {
      label: t("project.detail.dailyIncome"),
      value: `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
        data?.bonus,
      )}`,
    },
    {
      label: t("project.detail.totalIncome"),
      value: `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
        data?.money,
      )} + ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
        data?.bonus * (data?.cycle / 24),
      )}`,
    },
  ];

  const handleFinish = () => {
    openTipsModal({
      body: t("project.buy.tip"),
      needCancel: true,
      onOk: handleConfirm,
    });
  };

  const handleConfirm = () => {
    const params = formRef.current.getFieldsValue();
    const form = new FormData();
    form.append("facility_id", id);
    form.append("amount", params.amount);
    form.append("num", 1);

    buy(
      {
        params: form,
      },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          navigate("/my-contract");
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    setMainLoading(isFetching || isFetchingUserData);
  }, [isFetching, isFetchingUserData]);

  useEffect(() => {
    if (!data) return;
    console.log("contractDetail", data);
    formRef.current.setFieldsValue({ amount: data.money, singleAmount: data.money });
  }, [data]);

  return (
    <div className={styles.detail}>
      <NormalTitle title={t("project.detail.title")} />
      <NormalContainer>
        <ProForm
          formRef={formRef}
          onFinish={handleFinish}
          submitter={{
            render: () => (
              <CustomButton
                buttonStyle={`${styles.customButtonStyle}`}
                type={"submit"}
                loading={isLoading}
              >
                {t("normal.submit")}
              </CustomButton>
            ),
          }}
          className={`${styles.right} right`}
        >
          <CustomInput
            label={t("project.detail.amount")}
            name="singleAmount"
            placeholder={t("normal.pleaseEnter", { label: t("project.detail.amount") })}
            type="number"
            readOnly={true}
            className={"single-amount-input"}
            disabled={true}
            addonAfter={t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}
          />
          <div className={styles.inputContainer}>
            <CustomInput
              label={t("label.purchaseAmount")}
              name="amount"
              placeholder={t("normal.pleaseEnter", { label: t("label.purchaseAmount") })}
              type="number"
            />
            <div className={styles.wallet}>
              {t("project.detail.wallet", { money: userData?.money || 0 })}
            </div>
          </div>
          <SettingArea settingEnum={settingEnum} />
        </ProForm>
      </NormalContainer>
      <NormalContainer customNormalContainerStyle={styles.customNormalContainerStyle}>
        <div className={styles.introContainer}>
          <div className={styles.title}>{t("project.detail.intro")}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data?.facility_content }}
          />
        </div>
      </NormalContainer>
    </div>
  );
};

export default Detail;
