import { Col, QRCode, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../components";
import { envChangeType, handleClickApp } from "../../plugins/utils";
import FeatureItem from "./components/FeatureItem";
import styles from "./style.module.scss";

const AppDownload = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.appDownload}>
      <div className={styles.instructionContainer}>
        <Row gutter={{ lg: 100, md: 50, xs: 0 }}>
          <Col lg={14} md={12} xs={24}>
            <div className={styles.leftContainer}>
              <NormalTitle title={t("download.instruction.title")} />
              <div className={styles.subTitle}>
                {t("download.instruction.desc", {
                  logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
                })}
              </div>
              <div className={styles.imageContainer}>
                <a className={styles.downloadLinks} href={handleClickApp("url")}>
                  <img
                    src={`/images/${envChangeType(
                      "REACT_APP_PRODUCTION_NAME_DEV",
                    )}/appDownload/androidf.png`}
                    alt="android"
                    width={163}
                  />
                  <img
                    src={`/images/${envChangeType(
                      "REACT_APP_PRODUCTION_NAME_DEV",
                    )}/appDownload/iphonef.png`}
                    alt="iphone"
                    width={163}
                  />
                </a>
                <QRCode
                  className={styles.qr}
                  value={handleClickApp("url")}
                  size={126}
                  bgColor={"#fff"}
                />
              </div>
            </div>
          </Col>
          <Col className={styles.imagePhoneContainer} lg={10} md={12} xs={24}>
            <img
              className={styles.phone}
              src={`/images/${envChangeType(
                "REACT_APP_PRODUCTION_NAME_DEV",
              )}/appDownload/phone.png`}
              alt="Phone"
            />
          </Col>
        </Row>
      </div>
      <NormalTitle
        title={t("download.featurelist.title", {
          logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY"),
        })}
      />
      <Row className={styles.features}>
        <Col md={12} sm={24}>
          <FeatureItem
            title={t("download.feature.title.1")}
            desc={t("download.feature.desc.1")}
            image={`/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/appDownload/feature1.png`}
          />
        </Col>
        <Col md={12} sm={24}>
          <FeatureItem
            title={t("download.feature.title.2")}
            desc={t("download.feature.desc.2")}
            image={`/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/appDownload/feature2.png`}
          />
        </Col>
        <Col md={12} sm={24}>
          <FeatureItem
            title={t("download.feature.title.3")}
            desc={t("download.feature.desc.3")}
            image={`/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/appDownload/feature3.png`}
          />
        </Col>
        <Col md={12} sm={24}>
          <FeatureItem
            title={t("download.feature.title.4")}
            desc={t("download.feature.desc.4")}
            image={`/images/${envChangeType(
              "REACT_APP_PRODUCTION_NAME_DEV",
            )}/appDownload/feature4.png`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AppDownload;
