import { createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { useLoading } from "../../../context/LoadingContext";
import { useGetData } from "../../../hooks/indexData/indexData.hook";

const ApiDataContext = createContext({
  data: {},
});

export const ApiDataContextProvider = ({ children }) => {
  const { setMainLoading } = useLoading();
  const navigate = useNavigate();
  const { isLogin } = useAuth();

  const { data, isFetching } = useGetData();

  useEffect(() => {
    setMainLoading(isFetching);
    if (!data) return;
    console.log("aboutData", data);
  }, [data, isFetching]);

  // useEffect(() => {
  //   if (!isLogin) {
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 1000);
  //   }
  // }, [isLogin, navigate]);

  return <ApiDataContext.Provider value={{ data }}>{children}</ApiDataContext.Provider>;
};
export const useApiData = () => {
  const Value = useContext(ApiDataContext);
  return Value;
};
