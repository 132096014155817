import { Mask } from "antd-mobile";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import CloseCountdown from "./components/CloseCountdown";
import winResult from "./images/winResult.png";
import "./style.scss";

const ResultPopup = ({
  visibleResultPopup,
  setVisibleResultPopup,
  timer,
  setTimer,
  onClose,
  amount,
}) => {
  return (
    <div className="mine-result-popup">
      <Mask className={`${visibleResultPopup ? "show" : ""}`}>
        <div className={`content ${visibleResultPopup ? "show" : ""}`}>
          <img src={winResult} alt="winResult" width={306} />
          <div className="text">
            <div>{moneyFormat(amount)}</div>
          </div>
        </div>
        <CloseCountdown
          setVisibleResultPopup={setVisibleResultPopup}
          timer={timer}
          setTimer={setTimer}
          onClose={onClose}
        />
      </Mask>
    </div>
  );
};

export default ResultPopup;
