import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components";
import { envChangeType, envType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const License = ({ data }) => {
  const { t } = useTranslation();

  return data ? (
    <div
      className={styles.licenseBg}
      style={{
        background: envChangeType("REACT_APP_ABOUT_EXTRA_BG", envType.BOOLEAN)
          ? `url('/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/license-bg.png')`
          : "",
      }}
    >
      <div className={styles.license}>
        <div className={styles.left}>
          <div className={styles.text}>
            <div>{data?.name}</div>
            <div>{t("about.companyNumber") + data["Company number"]}</div>
            <div>{t("about.companyType") + data["Company type"]}</div>
            <div>{t("about.registeredOffice") + data["Registered Office"]}</div>
          </div>
          <div className={styles.buttonGroup}>
            <CustomButton
              key="check"
              buttonStyle={`${styles.customButtonStyle}`}
              onClick={() => window.open(data?.check_online)}
            >
              {t("about.checkOnline")}
            </CustomButton>
            <CustomButton
              key="download"
              buttonStyle={`${styles.customButtonStyle}`}
              onClick={() => window.open(data?.check_pdf)}
            >
              {t("about.download")}
            </CustomButton>
          </div>
        </div>
        <div className={styles.right}>
          <Image src={data?.license1} alt="license1" preview={false} className={styles.img} />
          <Image src={data?.license2} alt="license2" preview={false} className={styles.img} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default License;
