import { envChangeType, envType } from "../../../../plugins/utils";
import HorizontalSwiper from "./components/HorizontalSwiper";
import NormalImages from "./components/NormalImages";
import styles from "./style.module.scss";

const Methods = () => {
  const renderMethods = () => {
    if (envChangeType("REACT_APP_HOME_METHODS_SWIPER", envType.BOOLEAN)) {
      return <HorizontalSwiper />;
    } else {
      return <NormalImages />;
    }
  };

  return <div className={styles.methods}>{renderMethods()}</div>;
};

export default Methods;
