import About from "../pages/About";
import AndroidDownload from "../pages/AndroidDownload";
import AppDownload from "../pages/AppDownload";
import Blog from "../pages/Blog";
import BlogDetail from "../pages/Blog/components/Detail";
import Dashboard from "../pages/Dashboard";
import Earnings from "../pages/Earnings";
import FAQ from "../pages/FAQ";
import Home from "../pages/Home";
import IOSDownload from "../pages/IOSDownload";
import Message from "../pages/Message";
import MyProject from "../pages/MyProject";
import MyTeam from "../pages/MyTeam";
import MyTeamDetail from "../pages/MyTeam/components/TeamDetail";
import Privacy from "../pages/Privacy";
import Project from "../pages/Project";
import Detail from "../pages/Project/components/Detail";
import Referrals from "../pages/Referrals";
import Affiliate from "../pages/Referrals/components/Affiliate";
import Share from "../pages/Share";
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import Terms from "../pages/Terms";
import Tutorial from "../pages/Tutorial";
import TutorialDetail from "../pages/Tutorial/components/Detail";
import User from "../pages/User";
import Gift from "../pages/User/components/GiftAndRecord";
import MyIncome from "../pages/User/components/MyIncome";
import PaymentPassword from "../pages/User/components/PaymentPassword";
import Safe from "../pages/User/components/Safe";
import WithdrawAddress from "../pages/User/components/WithdrawAddress";
import Deposit from "../pages/Wallet/components/Recharge";
import Trade from "../pages/Wallet/components/Trade";
import Withdraw from "../pages/Wallet/components/Withdraw";

const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/signin",
    name: "signin",
    component: Signin,
    navType: {
      fullWidth: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    navType: {
      fullWidth: true,
    },
  },
  {
    path: "/project",
    name: "project",
    component: Project,
    navType: {
      center: {
        title: "footer.item.project",
        hasBack: false,
      },
    },
  },
  {
    path: "/project/:id",
    name: "detail",
    component: Detail,
    navType: {
      center: {
        title: "footer.item.project",
        hasBack: true,
      },
    },
  },
  {
    path: "/share",
    name: "share",
    component: Share,
    navType: {
      center: {
        title: "footer.item.share",
        hasBack: false,
      },
    },
  },
  {
    path: "/app-download",
    name: "app-download",
    component: AppDownload,
    navType: {
      center: {
        title: "footer.item.appDownload",
        hasBack: false,
      },
    },
  },
  {
    path: "/message",
    name: "message",
    component: Message,
    navType: {
      center: {
        title: "footer.item.message",
        hasBack: false,
      },
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    navType: {
      center: {
        title: "footer.item.dashboard",
        hasBack: false,
      },
    },
  },
  {
    path: "/wallet-address",
    name: "walletAddress",
    component: WithdrawAddress,
    navType: {
      center: {
        title: "user.withdrawAddress",
        hasBack: true,
      },
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: MyIncome,
    navType: {
      center: {
        title: "user.myIncome",
        hasBack: true,
      },
    },
  },
  {
    path: "/earnings",
    name: "earnings",
    component: Earnings,
    navType: {
      center: {
        title: "normal.miningEarnings",
        hasBack: true,
      },
    },
  },
  {
    path: "/commissions",
    name: "commissions",
    component: MyTeam,
    navType: {
      center: {
        title: "user.myIncome",
        hasBack: true,
      },
    },
  },
  {
    path: "/commissions/details",
    name: "commissionsDetails",
    component: MyTeamDetail,
    navType: {
      center: {
        title: "user.myIncome",
        hasBack: true,
      },
    },
  },
  {
    path: "/login-password",
    name: "loginPassword",
    component: Safe,
    navType: {
      center: {
        title: "user.safe",
        hasBack: true,
      },
    },
  },
  {
    path: "/payment-password",
    name: "paymentPassword",
    component: PaymentPassword,
    navType: {
      center: {
        title: "user.safe",
        hasBack: true,
      },
    },
  },
  {
    path: "/my-contract",
    name: "myContract",
    component: MyProject,
    navType: {
      center: {
        title: "nav.myProject",
        hasBack: true,
      },
    },
  },
  {
    path: "/referrals",
    name: "referrals",
    component: Referrals,
    navType: {
      center: {
        title: "nav.myTeam",
        hasBack: true,
      },
    },
  },
  {
    path: "/referrals/affiliate",
    name: "affiliate",
    component: Affiliate,
    navType: {
      center: {
        title: "nav.myTeam",
        hasBack: true,
      },
    },
  },
  {
    path: "/deposit",
    name: "deposit",
    component: Deposit,
    navType: {
      center: {
        title: "normal.recharge",
        hasBack: true,
        rightText: "normal.record.upper",
        rightUrl: "/user/recharge/record?tab=recharge&radioValue=record",
      },
    },
  },
  {
    path: "/my-deposits",
    name: "myDeposits",
    component: Deposit,
    navType: {
      center: {
        title: "normal.recharge",
        hasBack: true,
      },
    },
  },
  {
    path: "/deposit/:id",
    name: "trade",
    component: Trade,
    navType: {
      center: {
        title: "normal.recharge",
        hasBack: false,
      },
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: Withdraw,
    navType: {
      center: {
        title: "normal.withdraw",
        hasBack: true,
        rightText: "normal.record.upper",
        rightUrl: "/user/withdraw/record?tab=withdraw&radioValue=record",
      },
    },
  },
  {
    path: "/my-withdrawals",
    name: "myWithdrawals",
    component: Withdraw,
    navType: {
      center: {
        title: "normal.withdraw",
        hasBack: true,
      },
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
    navType: {
      center: {
        title: "normal.blog",
        hasBack: true,
      },
    },
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: BlogDetail,
    navType: {
      center: {
        title: "normal.blog",
        hasBack: true,
      },
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
    navType: {
      center: {
        title: "normal.faq",
        hasBack: true,
      },
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    navType: {
      fullWidth: true,

      center: {
        title: "normal.about",
        hasBack: true,
      },
    },
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: Tutorial,
    navType: {
      center: {
        title: "normal.tutorial",
        hasBack: true,
      },
    },
  },
  {
    path: "/tutorial/:id",
    name: "tutorial",
    component: TutorialDetail,
    navType: {
      center: {
        title: "normal.tutorial",
        hasBack: true,
      },
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    navType: {
      center: {
        title: "footer.item.privacy",
        hasBack: false,
      },
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
    navType: {
      center: {
        title: "normal.terms",
        hasBack: false,
      },
    },
  },
  {
    path: "/app-download/android",
    name: "android",
    component: AndroidDownload,
    navType: {
      hideHeader: true,
      hideFooter: true,
      backgroundColor: "#fff",
    },
  },
  {
    path: "/app-download/ios",
    name: "ios",
    component: IOSDownload,
    navType: {
      hideHeader: true,
      hideFooter: true,
      backgroundColor: "#fff",
    },
  },
  {
    path: "/user/gift",
    name: "gift",
    component: Gift,
    center: {
      title: "user.gift",
      hasBack: true,
    },
  },
  {
    path: "/user/non-use",
    name: "non-use",
    component: User,
  },
];
export default RouterList;
