import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "./style.module.scss";

const TimerCountDown = ({ data }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!data || data?.end !== 1) return;
    let currentTimer = {
      days: data?.d,
      hours: data?.h,
      minutes: data?.m,
      seconds: data?.s,
    };
    let timeInterval = null;

    const componentDidMount = () => {
      timeInterval = setInterval(() => {
        const { seconds, minutes, hours, days } = currentTimer;
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              if (days === 0) {
                clearInterval(timeInterval);
              } else {
                currentTimer = {
                  ...currentTimer,
                  days: days - 1,
                  hours: 23,
                  minutes: 59,
                  seconds: 59,
                };
              }
            } else {
              currentTimer = { ...currentTimer, hours: hours - 1, minutes: 59, seconds: 59 };
            }
          } else {
            currentTimer = { ...currentTimer, minutes: minutes - 1, seconds: 59 };
          }
        } else {
          currentTimer = { ...currentTimer, seconds: seconds - 1 };
        }
        setTime(currentTimer);
      }, 1000);
    };
    componentDidMount();
    return () => clearInterval(timeInterval);
  }, [data]);

  return (
    <div className={styles.timerCountDown}>
      <div className={styles.timerBox}>
        <div className={styles.value}>{time.days}</div>
        <div>{t("normal.day")}</div>
      </div>

      <div className={styles.timerBox}>
        <div className={styles.value}>{time.hours}</div>
        <div>{t("normal.hour")}</div>
      </div>

      <div className={styles.timerBox}>
        <div className={styles.value}>{time.minutes}</div>
        <div>{t("normal.min")}</div>
      </div>

      <div className={styles.timerBox}>
        <div className={styles.value}>{time.seconds}</div>
        <div>{t("normal.sec")}</div>
      </div>
    </div>
  );
};

export default TimerCountDown;
