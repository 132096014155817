import { useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetHomePopup, apiGetPayCaseUrl, apiGetPreferProject } from "../service";

export const useGetPreferProject = (options) => {
  return useQuery(
    [queryKey.PREFER_PROJECT],
    () => apiGetPreferProject().then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const useGetHomePopup = (options) => {
  return useQuery(
    [queryKey.HOME_POPUP],
    () => apiGetHomePopup().then((res) => res?.data?.data.length > 0 && res?.data?.data[0]),
    {
      ...options,
    },
  );
};

export const useGetPayCaseUrl = (options) => {
  return useQuery([queryKey.PAY_CASE_URL], () => apiGetPayCaseUrl().then((res) => res.data.data), {
    ...options,
  });
};
