import { USER_FIRST_TAB_PATH } from "../plugins/utils";

export const isActive = (pathname, path) => {
  if (!path) return;
  const HOME_ACTIVE = ["/", "/privacy", "/terms"];
  const CONTRACT_ACTIVE = ["/project", "/my-contract"];
  const DEPOSIT_ACTIVE = ["/deposit", "/my-deposits"];
  const WITHDRAW_ACTIVE = ["/withdraw", "/my-withdrawals"];
  const BILLS_ACTIVE = ["/transactions", "/commissions", "/commissions/details"];
  const SETTINGS_ACTIVE = [
    "/wallet-address",
    "/login-password",
    "/payment-password",
    "/referrals",
    "/referrals/affiliate",
  ];
  // console.log(pathname, path);
  switch (path) {
    case "/":
      return HOME_ACTIVE.includes(pathname);
    case "/wallet/recharge/recharge":
      return pathname.includes("/wallet");
    case "/user/bank-info":
    case USER_FIRST_TAB_PATH:
      return pathname.includes("/user");
    case "/project":
      return CONTRACT_ACTIVE.includes(pathname) || pathname.includes(path);
    case "/deposit":
      return DEPOSIT_ACTIVE.includes(pathname) || pathname.includes(path);
    case "/withdraw":
      return WITHDRAW_ACTIVE.includes(pathname);
    case "/bills":
      return BILLS_ACTIVE.includes(pathname);
    case "/settings":
      return SETTINGS_ACTIVE.includes(pathname);
    default:
      return pathname.includes(path);
  }
};
