const queryKey = {
  HOME_DATA: "home-data",
  PREFER_PROJECT: "prefer-project",
  PROJECT: "project",
  PROJECT_DETAIL: "project-detail",
  USER: "user",
  BANK_INFO: "bank-info",
  DIGITAL_INFO: "digital-info",
  GIFT_RECORD: "gift-record",
  MY_INCOME: "my-income",
  INVITATION: "invitation",
  CAPTCHA: "captcha",
  LOGIN_CS: "login-cs",
  CS: "cs",
  MESSAGES: "messages",
  RECHARGE_CONFIG: "recharge-config",
  PAY_CASE_URL: "pay-case-url",
  RECHARGE_RECORD: "recharge-record",
  RECHARGE_FIND: "recharge-find",
  WITHDRAW_CONFIG: "withdraw-config",
  WITHDRAW_RECORD: "withdraw-record",
  MY_PROJECT_LIST: "my-project-list",
  MY_PROJECT_DETAIL: "my-project-detail",
  TEAM_LIST: "team-list",
  TEAM_LIST_DETAIL: "team-list-detail",
  HOME_POPUP: "home-popup",
  APP: "app",
  BLOG: "blog",
  BLOG_DETAIL: "blog-detail",
  TUTORIAL: "tutorial",
};

export default queryKey;
