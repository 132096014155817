import { ReloadOutlined } from "@ant-design/icons";
import { ProForm } from "@ant-design/pro-components";
import { Image } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, CustomInput, NormalRadio, toast } from "../../../../components";
import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context/ModalContext";
import localStorageKey from "../../../../enumerations/localStorageKey";
import { apiCodeEnum } from "../../../../enumerations/signInUpEnum";
import { useGetCaptcha, usePostRegister } from "../../hooks/index.hook";
import styles from "./style.module.scss";

const SignUpForm = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { openTipsModal } = useModal();
  const { setToken } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const inviteCode = localStorage.getItem(localStorageKey.INVITE_CODE);

  const { data, refetch, isRefetching } = useGetCaptcha();
  const { mutate: register } = usePostRegister();

  const handleReload = () => {
    if (!isRefetching) {
      refetch();
    }
  };

  const handleFinish = async () => {
    if (!checked) {
      openTipsModal({
        body: t("signup.additional.notCheck"),
      });
      return;
    }

    const params = formRef.current.getFieldsValue();
    const form = new FormData();
    form.append("mobile", params.mobile);
    if (params.invite_code) form.append("invite_code", params.invite_code);
    if (params.email) form.append("email", params.email);
    form.append("code", params.code);
    form.append("password", params.password);
    form.append("payment_password", params.payment_password);
    form.append("_token", data._token);

    register(
      { params: form },
      {
        onSuccess: (success) => {
          const { code, msg, data } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          // setMode(signInUpEnum.SIGN_IN);
          setToken(data?.userinfo?.token);
          window.location.href = "/dashboard";
        },
        onError: () => {},
      },
    );
  };

  return (
    <ProForm
      formRef={formRef}
      submitter={{
        render: () => (
          <CustomButton
            buttonStyle={`${styles.customButtonStyle}`}
            type={"submit"}
            disabled={disabled}
          >
            {t("nav.signUp")}
          </CustomButton>
        ),
      }}
      onFinish={handleFinish}
      className={`${styles.form} sign-up-form`}
    >
      <div className={styles.formBody}>
        <CustomInput
          name="mobile"
          label={t("normal.username")}
          placeholder={t("normal.pleaseEnter.your", { label: t("normal.username") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          rules={[
            { required: true, message: t("normal.required", { name: t("normal.username") }) },
          ]}
        />
        <CustomInput
          name="email"
          label={t("normal.email")}
          placeholder={t("normal.pleaseEnter.your", { label: t("normal.email") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          rules={[
            {
              type: "email",
              message: t("normal.email.error"),
            },
            { required: true, message: t("normal.required", { name: t("normal.email") }) },
          ]}
        />
        <CustomInput
          name="password"
          label={t("normal.password")}
          placeholder={t("normal.pleaseEnter.your", { label: t("normal.password") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          type="password"
          rules={[
            { required: true, message: t("normal.required", { name: t("normal.password") }) },
          ]}
        />
        <CustomInput
          name="confirm"
          label={t("normal.confirm")}
          placeholder={t("normal.pleaseEnter", { label: t("normal.confirm") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          type="password"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("normal.confirmPasswordError")));
              },
            }),
            { required: true, message: t("normal.required", { name: t("normal.confirm") }) },
          ]}
        />
        <CustomInput
          name="payment_password"
          label={t("nav.paymentPassword")}
          placeholder={t("normal.pleaseEnter.your", { label: t("nav.paymentPassword") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          type="password"
          rules={[
            { required: true, message: t("normal.required", { name: t("nav.paymentPassword") }) },
          ]}
        />
        <CustomInput
          name="payment_password_confirm"
          label={t("normal.paymentPassword.confirm")}
          placeholder={t("normal.pleaseEnter", { label: t("normal.paymentPassword.confirm") })}
          // onChange={() => checkInputHasNull(formRef, setDisabled)}
          type="password"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("payment_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("normal.paymentPassword.error")));
              },
            }),
            {
              required: true,
              message: t("normal.required", { name: t("normal.paymentPassword.confirm") }),
            },
          ]}
        />
        <div className={styles.inviteCode}>
          <CustomInput
            name="invite_code"
            label={t("label.referralCode")}
            placeholder={t("normal.pleaseEnter.your", { label: t("normal.code") })}
            // onChange={() => checkInputHasNull(formRef, setDisabled)}
            initialValue={inviteCode ? inviteCode : ""}
          />
        </div>
        <div className={styles.verification}>
          <CustomInput
            name="code"
            label={t("normal.verification")}
            placeholder={t("normal.pleaseEnter", { label: t("normal.verification") })}
            // onChange={() => checkInputHasNull(formRef, setDisabled)}
            rules={[
              { required: true, message: t("normal.required", { name: t("normal.verification") }) },
            ]}
          />
          <div className={styles.captcha}>
            {data && (
              <div className={styles.image}>
                <Image
                  src={data?.captcha}
                  alt="captcha"
                  preview={false}
                  onClick={handleReload}
                  className={styles.image}
                />
              </div>
            )}
            <ReloadOutlined className={styles.reload} onClick={handleReload} spin={isRefetching} />
          </div>
          <div className={styles.additional}>
            <NormalRadio
              content={
                <div>
                  {t("signup.additional") + " "}
                  <span className={styles.light} onClick={() => window.open("/privacy", "_blank")}>
                    {t("normal.privacy") + " , "}
                  </span>
                  <span className={styles.light} onClick={() => window.open("/terms", "_blank")}>
                    {t("normal.terms")}
                  </span>
                </div>
              }
              checked={checked}
              setChecked={setChecked}
            />
          </div>
        </div>
      </div>
    </ProForm>
  );
};

export default SignUpForm;
