import { useTranslation } from "react-i18next";
import Add from "./icons/add";
import Export from "./icons/export";
import step1 from "./images/step1.png";
import step2 from "./images/step2.png";
import "./style.scss";

const IOSModalContent = () => {
  const { t } = useTranslation();

  return (
    <div className="app-ios-modal-content">
      <div className="description">{t("app.ios.addToHome.desc")}</div>
      <div className="step">
        <div className="text">
          <div className="icon-box">
            <Export />
          </div>
          <div
            className="step-hint"
            dangerouslySetInnerHTML={{ __html: t("app.ios.addToHome.step1") }}
          />
        </div>
        <img src={step1} alt="step1" className="img" />
      </div>
      <div className="step">
        <div className="text">
          <div className="icon-box">
            <Add />
          </div>
          <div
            className="step-hint"
            dangerouslySetInnerHTML={{ __html: t("app.ios.addToHome.step2") }}
          />
        </div>
        <img src={step2} alt="step2" className="img" />
      </div>
    </div>
  );
};

export default IOSModalContent;
