import { envChangeType, envType } from "../../plugins/utils";
import Carousel from "./components/Carousel";
import Slogan from "./components/Slogan";
import styles from "./style.module.scss";
import "./style.scss";

const TopArea = ({ data, showButton }) => {
  const renderContent = () => {
    if (envChangeType("REACT_APP_HOME_TOPAREA_SWIPER", envType.BOOLEAN)) {
      return <Carousel data={data} />;
    } else {
      return (
        <>
          <div id="stars" />
          <div id="stars2" />
          <div id="stars3" />
        </>
      );
    }
  };

  return (
    <div
      className={styles.topArea}
      style={{
        backgroundImage: `url('/images/${envChangeType(
          "REACT_APP_PRODUCTION_NAME_DEV",
        )}/home/top-bg.jpg')`,
      }}
    >
      <div className={styles.left}>
        <Slogan showButton={showButton} />
      </div>
      {renderContent()}
    </div>
  );
};

export default TopArea;
