import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import typeEnum from "../enumerations/typeEnum.js";
import { apiGetTeamList, apiGetTeamListDetail } from "../service";

export const useGetTeamList = (options) => {
  return useQuery([queryKey.TEAM_LIST], () => apiGetTeamList().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetTeamListDetail = ({ options, params }) => {
  return useQuery(
    [queryKey.TEAM_LIST_DETAIL, params],
    () =>
      apiGetTeamListDetail(params).then((res) => {
        let { data } = res.data;
        if (params.type === typeEnum.EQC) {
          data.data = data.data.map((item, index) => {
            return { ...item, key: index };
          });
          return data;
        } else {
          data = data.map((item, index) => {
            return { ...item, key: index };
          });
          return { data };
        }
      }),
    {
      ...options,
    },
  );
};

export const useGetTeamListDetailInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.TEAM_LIST_DETAIL, params],
    ({ pageParam = 1 }) =>
      apiGetTeamListDetail({ ...params, page: pageParam }).then((res) => {
        let { data } = res.data;
        if (params.type === typeEnum.EQC) {
          data.data = data.data.map((item, index) => {
            return { ...item, key: index };
          });
          return { data: data.data, last_page: data.last_page };
        } else {
          data = data.map((item, index) => {
            return { ...item, key: index };
          });
          return { data: data };
        }
      }),
    {
      ...options,
    },
  );
};
