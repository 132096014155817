import { createContext, useContext, useEffect, useState } from "react";
import localStorageKey from "../enumerations/localStorageKey";
import { envChangeType } from "../plugins/utils";
import useLocalStorage from "./useLocalStorage";

const AuthContext = createContext({
  token: null,
  setToken: () => {},
  currency: envChangeType("REACT_APP_DEFAULT_CURRENCY"),
  isLogin: true,
});

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage(localStorageKey.TOKEN);
  const [currency, setCurrency] = useLocalStorage(localStorageKey.CURRENCY);

  const [isLogin, setIsLogin] = useState(true);

  useEffect(() => {
    if (!token) {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        setToken,
        token,
        currency,
        isLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const AuthValue = useContext(AuthContext);
  return AuthValue;
};
