import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import {
  apiGetRechargeConfig,
  apiGetRechargeRecord,
  apiGetWithdrawConfig,
  apiGetWithdrawRecord,
  apiPostRecharge,
  apiPostUpImg,
  apiPostUpload,
  apiPostWithdraw,
} from "../service";

export const useGetRechargeConfig = (options) => {
  return useQuery(
    [queryKey.RECHARGE_CONFIG],
    () => apiGetRechargeConfig().then((res) => res.data),
    {
      ...options,
    },
  );
};

export const usePostRecharge = () => {
  return useMutation(({ params }) => apiPostRecharge({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetRechargeRecord = ({ options, params }) => {
  return useQuery(
    [queryKey.RECHARGE_RECORD, params],
    () =>
      apiGetRechargeRecord(params).then((res) => {
        const { data, msg } = res.data;
        data.data = data.data.map((item) => {
          return { ...item, key: item.id };
        });
        return { data: data, all: msg.all };
      }),
    {
      ...options,
    },
  );
};

export const useGetRechargeRecordInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.RECHARGE_RECORD, params],
    ({ pageParam = 1 }) =>
      apiGetRechargeRecord({ ...params, page: pageParam }).then((res) => {
        const { data, msg } = res.data;
        data.data = data.data.map((item) => {
          return { ...item, key: item.id };
        });
        return { data: data.data, all: msg.all, last_page: data.last_page };
      }),
    {
      ...options,
    },
  );
};

export const useGetWithdrawConfig = (options) => {
  return useQuery(
    [queryKey.WITHDRAW_CONFIG],
    () => apiGetWithdrawConfig().then((res) => res.data.data),
    {
      ...options,
    },
  );
};

export const usePostWithdraw = () => {
  return useMutation(({ params }) => apiPostWithdraw({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const useGetWithdrawRecord = ({ options, params }) => {
  return useQuery(
    [queryKey.WITHDRAW_RECORD, params],
    () =>
      apiGetWithdrawRecord(params).then((res) => {
        const { data, msg } = res.data;
        data.data = data.data.map((item) => {
          return { ...item, key: item.id };
        });
        return { data: data, all: msg.all };
      }),
    {
      ...options,
    },
  );
};

export const useGetWithdrawRecordInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.WITHDRAW_RECORD, params],
    ({ pageParam = 1 }) =>
      apiGetWithdrawRecord({ ...params, page: pageParam }).then((res) => {
        const { data, msg } = res.data;
        data.data = data.data.map((item) => {
          return { ...item, key: item.id };
        });
        return { data: data.data, all: msg.all, last_page: data.last_page };
      }),
    {
      ...options,
    },
  );
};

export const usePostUpload = () => {
  return useMutation(({ params }) => apiPostUpload({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};

export const usePostUpImg = () => {
  return useMutation(({ params }) => apiPostUpImg({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
