import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../../../components";
import coin from "../../images/top-area-coin.png";
import styles from "./style.module.scss";

const TopArea = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.topArea}>
      <NormalTitle title={t("referral.affiliate.title")} />
      <div className={styles.main}>
        <img src={coin} alt="coin" className={styles.img} />
        <div className={styles.text}>
          <div className={styles.title}>{t("affiliate.topArea.title")}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: t("affiliate.topArea.content") }}
          />
        </div>
      </div>
    </div>
  );
};

export default TopArea;
