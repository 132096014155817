import React from "react";
import styles from "./style.module.scss";

const FeatureItem = ({ image, title, desc }) => {
  return (
    <div className={styles.featureItemContainer}>
      <div className={styles.imageFrame}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.featureItemText}>
        <div className={styles.featureItemTitle}>{title}</div>
        <div className={styles.featureItemDesc}>{desc}</div>
      </div>
    </div>
  );
};

export default FeatureItem;
