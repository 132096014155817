import { Card } from "antd";
import "./style.scss";

const CustomCard = ({ title, children, style }) => {
  return (
    <div className="custom-card">
      <Card title={title} style={{ ...style }}>
        {children}{" "}
      </Card>
    </div>
  );
};

export default CustomCard;
