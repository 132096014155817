import { Image } from "antd";
import { envChangeType } from "../../../../plugins/utils";
import { useApiData } from "../../context/ApiDataContext";
import AboutArea from "../AboutArea";
import ChooseArea from "../ChooseArea";
import DashboardArea from "../DashboardArea";
import License from "../License";
import styles from "./style.module.scss";

const AboutContainer = () => {
  const { data } = useApiData();

  return (
    <div className={`${styles.about} about`}>
      <div className={styles.container}>
        <div className={styles.banner}>
          <Image
            src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/about/banner.png`}
            alt="banner"
            preview={false}
          />
        </div>
        <AboutArea />
        <DashboardArea />
        <License data={data?.company_info} />
        <ChooseArea />
      </div>
      {/* <div className={styles.star}>
        <Image src={star} alt="star" preview={false} width={100} height={100} />
      </div>
      <div className={styles.candy}>
        <Image src={candy} alt="candy" preview={false} width={85} height={86} />
      </div> */}
    </div>
  );
};

export default AboutContainer;
