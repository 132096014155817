import { Spin } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "../context/LoadingContext";
import { useMobile } from "../context/MobileContext";
import Background from "./components/Background";
import Footer from "./components/Footer";
import FooterMenu from "./components/FooterMenu";
import Navigation from "./components/Navigation";
import styles from "./style.module.scss";
import "./style.scss";

const Layout = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { mainLoading } = useLoading();
  const { isMobile } = useMobile();

  const { navType } = props;
  // console.log(navType);
  const renderHeader = () => {
    if (navType.hideHeader) {
      return null;
    }
    return (
      <Navigation
        {...navType}
        goBack={(backURL) => {
          navigate(backURL, {
            replace: true,
          });
        }}
      />
    );
  };

  const renderFooter = () => {
    if (navType.hideFooter) return;

    if (isMobile) {
      return (
        <>
          <Footer {...navType} />
          <FooterMenu {...navType} />;
        </>
      );
    } else {
      return <Footer {...navType} />;
    }
  };

  useEffect(() => {
    const contentContainer = document.getElementById("contentContainer");
    contentContainer?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Background background={navType.backgroundColor}>
      <div id="copy-group" />
      {renderHeader()}
      <div
        className={`${styles.main} main ${navType.hideHeader ? styles.noHeader : ""}`}
        id="contentContainer"
      >
        <Spin spinning={mainLoading}>
          <div
            className={styles.container}
            style={{ margin: navType.fullWidth && 0, width: navType.fullWidth && "100%" }}
          >
            {props.children}
          </div>
        </Spin>
        {renderFooter()}
      </div>
    </Background>
  );
};
export default Layout;
