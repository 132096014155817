import { CopyOutlined } from "@ant-design/icons";
import { ProForm } from "@ant-design/pro-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomInput,
  CustomTable,
  NormalContainer,
  NormalTitle,
  RecordCards,
} from "../../components";
import { useLoading } from "../../context/LoadingContext";
import { useMobile } from "../../context/MobileContext";
import { useGetInvitation, useGetInvitationInfinite } from "../../hooks/user/user.hook";
import { copyText, envChangeType, formatDate, getNextPageParam } from "../../plugins/utils";
import styles from "./style.module.scss";

const Referrals = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { setMainLoading } = useLoading();

  const { data, isFetching } = useGetInvitation({
    options: { enabled: !isMobile },
  });
  const {
    data: shareInfinite,
    isFetching: isFetchingShareInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetInvitationInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      page_size: 20,
    },
  });

  const fakeData = [
    { createtime: 1742901566, username: "Leo", level: 6 },
    { createtime: 1742901566, username: "Leo", level: 6 },
    { createtime: 1742901566, username: "Leo", level: 6 },
    { createtime: 1742901566, username: "Leo", level: 6 },
    { createtime: 1742901566, username: "Leo", level: 6 },
  ];

  const columns = [
    {
      title: t("label.id").toUpperCase(),
      dataIndex: "sn",
      key: "sn",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("label.date").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => formatDate(dom),
    },
    {
      title: t("label.name").toUpperCase(),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("label.vip").toUpperCase(),
      dataIndex: "level",
      key: "level",
    },
  ];

  useEffect(() => {
    if (!data) return;
    console.log("referrals data", data);
    setMainLoading(isFetching || isFetchingShareInfinite);
  }, [data, isFetching, isFetchingShareInfinite]);

  useEffect(() => {
    if (!shareInfinite) return;
    console.log("shareInfinite", shareInfinite);
  }, [shareInfinite]);

  return (
    <div className={styles.referrals}>
      <NormalTitle title={t("referral.title")} />
      <NormalContainer customNormalContainerStyle={styles.customNormalContainerStyle}>
        <ProForm submitter={false}>
          <CustomInput
            label={t("label.referral.code")}
            placeholder={""}
            value={data?.code}
            type="text"
            readOnly
            disabled
            className={"wallet-address-input"}
            addonAfter={
              <CopyOutlined className={styles.copy} onClick={() => copyText(data?.code)} />
            }
          />
          <CustomInput
            label={t("label.referral.link")}
            placeholder={""}
            value={data?.invurl}
            type="text"
            readOnly
            disabled
            className={"wallet-address-input"}
            addonAfter={
              <CopyOutlined className={styles.copy} onClick={() => copyText(data?.invurl)} />
            }
          />
        </ProForm>
      </NormalContainer>
      <div className={styles.totalPartners}>
        {t("referral.total.partners")}
        <span className={styles.totalPartnersValue}>{data?.total || 0}</span>
      </div>
      {isMobile ? (
        <RecordCards
          data={shareInfinite}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          columns={columns}
        />
      ) : (
        <CustomTable columns={columns} data={{ data: data?.partners }} />
      )}
      <div
        className={styles.hint}
        dangerouslySetInnerHTML={{
          __html: t("referral.hint", {
            currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
          }),
        }}
      />
      <a className={styles.learnMore} href="/referrals/affiliate">
        {t("referral.learnMore")}
      </a>
    </div>
  );
};

export default Referrals;
