import { Flex, Image } from "antd";
import styles from "./style.module.scss";

const Partners = () => {
  const partnersEnum = [
    { src: "/images/utils/partners/partner1.png", name: "partner1" },
    { src: "/images/utils/partners/partner2.png", name: "partner2" },
    { src: "/images/utils/partners/partner3.png", name: "partner3" },
    { src: "/images/utils/partners/partner4.png", name: "partner4" },
    { src: "/images/utils/partners/partner5.png", name: "partner5" },
    { src: "/images/utils/partners/partner6.png", name: "partner6" },
    { src: "/images/utils/partners/partner7.png", name: "partner7" },
    { src: "/images/utils/partners/partner8.png", name: "partner8" },
    { src: "/images/utils/partners/partner9.png", name: "partner9" },
    { src: "/images/utils/partners/partner10.png", name: "partner10" },
    { src: "/images/utils/partners/partner11.png", name: "partner11" },
    { src: "/images/utils/partners/partner12.png", name: "partner12" },
  ];
  return (
    <div className={styles.partners}>
      <Flex wrap gap={30} justify="center">
        {partnersEnum.map((item, index) => {
          return (
            <Image
              key={index}
              src={item.src}
              alt={item.name}
              preview={false}
              className={styles.img}
            />
          );
        })}
      </Flex>
    </div>
  );
};

export default Partners;
