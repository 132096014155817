import "antd-mobile/bundle/style.css";
import "antd/dist/reset.css";
import { useEffect, useState } from "react";
import "./App.css";
import "./plugins/i18n";
import RouterRender from "./router";

import { SnackbarProvider } from "notistack";
import { FontSetting } from "./components";
import { AuthContextProvider } from "./context/AuthContext";
import { FocusContextProvider } from "./context/FocusContext";
import { LangContextProvider } from "./context/LangContext";
import { LoadingContextProvider } from "./context/LoadingContext";
import { MobileContextProvider } from "./context/MobileContext";
import { ModalContextProvider } from "./context/ModalContext";
import { envChangeType } from "./plugins/utils";

const currentTheme = require(`./theme/${envChangeType(
  "REACT_APP_PRODUCTION_NAME_DEV",
)}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
const finalTheme = { ...defaultTheme, ...currentTheme };

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getTitleEl() {
  return document.getElementById("head-title");
}
function App() {
  const [vh, setVh] = useState(1);
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };

  useEffect(() => {
    setTheme();
  }, [finalTheme]);

  useEffect(() => {
    const favicon = getFaviconEl();
    const titleEl = getTitleEl();
    // console.log(favicon);
    // favicon.href = `/images/versions/${process.env.REACT_APP_PRODUCTION_NAME_DEV}/favicon.ico`;
    titleEl.innerHTML = envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY");

    setTimeout(() => {
      initStyle();
    }, 100);
  }, []);

  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    setVh(_vh);
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
    // window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  /*
   * SCRIPT TO DETECT WHICH HTML ELEMENT IS BEING SCROLLED
   */

  // function isScrollable(el) {
  //   return el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
  // }

  // window.addEventListener(
  //   "scroll",
  //   function (e) {
  //     var el = e.target;
  //     while (el && el !== document && !isScrollable(el)) {
  //       el = el.parent;
  //     }
  //     console.debug("Scrolled element: " + (el.className || "document"));
  //   },
  //   true
  // );
  //

  return (
    <div
      id="app"
      className="app"
      style={
        {
          // maxWidth: "450px",
          // position: "relative",
          // height: "100vh",
          // margin: "0 auto",
        }
      }
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AuthContextProvider>
          <MobileContextProvider>
            <FocusContextProvider>
              <LangContextProvider>
                <LoadingContextProvider>
                  <ModalContextProvider>
                    <FontSetting />
                    <RouterRender />
                  </ModalContextProvider>
                </LoadingContextProvider>
              </LangContextProvider>
            </FocusContextProvider>
          </MobileContextProvider>
        </AuthContextProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
