const Background = (props) => {
  return (
    <div
      style={{
        background:
          props.background || "linear-gradient(270deg, var(--bg-color1) 0%, var(--bg-color2) 100%)",
        width: "100vw",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* <div id="stars" />
      <div id="stars2" />
      <div id="stars3" /> */}
      {props.children}
    </div>
  );
};
export default Background;
