import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { moneyFormat } from "../../plugins/numberFormat";
import { envChangeType } from "../../plugins/utils";
import styles from "./style.module.scss";

const DELAY_ENUM = {
  NOT_DELAY: 0,
  IS_DELAY: 1,
};

const ProjectCardDescribe = ({
  data,
  describes = ["cycle", "amount", "dailyIncome", "totalIncome", "settleInterests", "commission"],
  showIcon = true,
  showDivider = false,
  style,
  alreadyBuy = false,
}) => {
  const { t } = useTranslation();
  const alreadyBuyDescribes = ["amount", "cycle", "income", "revenue", "date"];

  const describeEnum = {
    amount: {
      label: t("project.card.amount"),
      needCurrency: false,
      moneyFormat: false,
      value: `${moneyFormat(data.money)} ${t(
        `currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`,
      )}`,
      styleRed: false,
      styleBold: false,
      hide: false,
    },
    cycle: {
      label: t("project.card.cycle"),
      needCurrency: false,
      moneyFormat: false,
      value: data.cycle / 24 + " " + t("project.card.days"),
      styleRed: false,
      styleBold: false,
      hide: false,
    },
    dailyIncome: {
      label: t("project.card.dailyIncome"),
      needCurrency: true,
      moneyFormat: true,
      value: data.bonus,
      styleRed: false,
      styleBold: true,
      hide: false,
    },
    totalIncome: {
      label: t("project.card.totalIncome"),
      needCurrency: false,
      moneyFormat: false,
      value: `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
        data.money,
      )} + ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
        data.bonus * (data.cycle / 24),
      )}`,
      styleRed: true,
      styleBold: true,
      hide: false,
    },
    settleInterests: {
      label: t("project.card.settleInterests"),
      needCurrency: false,
      moneyFormat: false,
      value:
        data?.is_delay === DELAY_ENUM.IS_DELAY
          ? t("project.card.settleInterests.expire")
          : t("project.card.settleInterests.value"),
      styleRed: false,
      styleBold: true,
      hide: false,
    },
    commission: {
      label: t("project.card.commission"),
      needCurrency: false,
      moneyFormat: false,
      value: data.prent_point,
      styleRed: true,
      styleBold: false,
      hide: true,
    },
  };

  const alreadyBuyDescribeEnum = {
    amount: {
      label: t("project.card.amount"),
      needCurrency: true,
      moneyFormat: true,
      value: data.buy_money,
      styleRed: false,
      hide: false,
    },
    income: {
      label: t("project.card.dailyIncome"),
      needCurrency: true,
      moneyFormat: true,
      value: (data.cycle / 24) * data.total_bonus,
      styleRed: true,
      hide: false,
    },
    cycle: {
      label: t("project.card.cycle"),
      needCurrency: false,
      moneyFormat: false,
      value: data.cycle / 24 + " " + t("project.card.days"),
      styleRed: false,
      hide: false,
    },
    revenue: {
      label: t("project.card.totalIncome"),
      needCurrency: false,
      moneyFormat: false,
      value: `${data.cycle / 24}${t("project.card.days")} * ${data.way_charge}${t(
        `currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`,
      )} `,
      styleRed: true,
      hide: false,
    },
    date: {
      label: t("project.card.date"),
      needCurrency: false,
      moneyFormat: false,
      value: data.buy_date,
      styleRed: false,
      hide: false,
    },
  };

  const targetEnum = (describe) => {
    return alreadyBuy ? alreadyBuyDescribeEnum[describe] : describeEnum[describe];
  };
  const targetDescribes = () => {
    return alreadyBuy ? alreadyBuyDescribes : describes;
  };

  return (
    <div className={styles.describeContainer} style={{ ...style }}>
      <div className={styles.perDays}>
        {`${
          t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`) +
          moneyFormat(data.money)
        }`}
        <span>{`/ ${data.cycle / 24} Days`}</span>
      </div>
      {targetDescribes().map((describe, index) => {
        if (targetEnum(describe)?.hide) return <div key={index} />;
        return (
          <div
            className={`${styles.describe} ${targetEnum(describe)?.styleRed ? styles.red : ""} ${
              targetEnum(describe)?.styleBold ? styles.bold : ""
            }`}
            key={index}
          >
            {showIcon && (
              <img
                src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/project/prefix.png`}
                alt="img"
                width={20}
              />
            )}
            <div className={styles.label}>{targetEnum(describe)?.label}</div>
            <div className={styles.value}>
              {(targetEnum(describe)?.needCurrency
                ? t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)
                : "") +
                (targetEnum(describe)?.moneyFormat
                  ? moneyFormat(targetEnum(describe)?.value)
                  : targetEnum(describe)?.value)}
            </div>
          </div>
        );
      })}
      {showDivider && <Divider className={styles.divider} />}
    </div>
  );
};

export default ProjectCardDescribe;
