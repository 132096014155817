import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetMessages, apiPostMessages } from "./service";

export const useGetMessages = ({ options, params }) => {
  return useQuery(
    [queryKey.MESSAGES],
    () =>
      apiGetMessages(params).then((res) => {
        res.data.data = res.data.data.map((item) => ({ ...item, key: item.id }));
        return res.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetMessagesInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.MESSAGES, params],
    ({ pageParam = 1 }) =>
      apiGetMessages({ ...params, page: pageParam }).then((res) => {
        res.data.data = res.data.data.map((item) => ({ ...item, key: item.id }));
        return { data: res.data.data, last_page: 1 };
      }),
    {
      ...options,
    },
  );
};

export const usePostMessages = () => {
  return useMutation(({ params }) => apiPostMessages({ params }), {
    onError: (error) => {
      console.debug(JSON.stringify("ERR", error));
    },
  });
};
