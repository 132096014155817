import { createContext, useContext, useEffect, useState } from "react";
import vars from "../assets/styles/_variables.scss";
import { USER_FIRST_TAB_PATH } from "../plugins/utils";

const MobileContext = createContext({ isMobile: false, isWidthMiddle: false });

export const MobileContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= vars.l.replace("px", ""));
  const [isWidthMiddle, setIsWidthMiddle] = useState(window.innerWidth <= vars.m.replace("px", ""));
  const { pathname } = window.location;

  window.onresize = () => {
    if (window.innerWidth <= vars.l.replace("px", "")) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (window.innerWidth <= vars.m.replace("px", "")) {
      setIsWidthMiddle(true);
    } else {
      setIsWidthMiddle(false);
    }
  };

  useEffect(() => {
    console.log("isMobile", isMobile);
    console.log("isWidthMiddle", isWidthMiddle);
    const { pathname } = window.location;
    if (!isMobile) {
      if (pathname === "/user") window.location.href = USER_FIRST_TAB_PATH;
    }
  }, [isMobile, isWidthMiddle]);

  return (
    <MobileContext.Provider value={{ isMobile, isWidthMiddle }}>{children}</MobileContext.Provider>
  );
};
export const useMobile = () => {
  const Value = useContext(MobileContext);
  return Value;
};
