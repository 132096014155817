import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomTable, NormalTitle, RecordCards } from "../../components";
import { useMobile } from "../../context/MobileContext";
import { useGetMyIncome, useGetMyIncomeInfinite } from "../../hooks/user/user.hook";
import { moneyFormat } from "../../plugins/numberFormat";
import { envChangeType, getNextPageParam } from "../../plugins/utils";
import styles from "./style.module.scss";

const Earnings = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  const { data, isFetching } = useGetMyIncome({
    options: { enabled: !isMobile },
    params: { item: 0 },
  });

  const {
    data: dataInfinite,
    isFetching: isFetchingDataInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMyIncomeInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      item: 0,
      page_size: 20,
    },
  });

  const columns = [
    {
      title: t("label.date").toUpperCase(),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => dayjs.unix(dom).format("YYYY-MM-DD"),
    },
    {
      title: t("label.investment").toUpperCase(),
      dataIndex: "ext",
      key: "ext",
    },
    {
      title: t("normal.miningEarnings").toUpperCase(),
      key: "money",
      dataIndex: "money",
      render: (dom) =>
        `+ ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${moneyFormat(
          dom,
        )}`,
    },
  ];

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <div className={styles.earnings}>
      <NormalTitle title={t("normal.miningEarnings")} />
      {isMobile ? (
        <RecordCards
          data={isMobile ? dataInfinite : data}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loading={isFetchingDataInfinite}
          columns={columns}
        />
      ) : (
        <>
          <CustomTable columns={columns} data={data} loading={isFetching} />
        </>
      )}
    </div>
  );
};

export default Earnings;
