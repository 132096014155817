import { Image } from "antd";
import { useRef } from "react";
import { Autoplay, EffectCoverflow, EffectCreative } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import bottom from "../../images/methods-bottom.png";
import top from "../../images/methods-top.png";
import styles from "./style.module.scss";
import "./style.scss";

const HorizontalSwiper = () => {
  const refTop = useRef();
  const refBottom = useRef();

  const swiperEnum = [
    {
      key: 0,
      data: [top, top, top, top, top],
      ref: refTop,
      dir: "",
    },
    {
      key: 1,
      data: [bottom, bottom, bottom, bottom, bottom],
      ref: refBottom,
      dir: "rtl",
    },
  ];

  const items = (props) => {
    return props.data.map((_item, index) => (
      <SwiperSlide key={index}>
        <div className={styles.swiperSlide}>
          <Image src={_item} alt="img" preview={false} className={styles.img} />
        </div>
      </SwiperSlide>
    ));
  };

  return swiperEnum.map((item) => {
    return (
      <div key={item.key} className={styles.horizontalSwiper}>
        <Swiper
          ref={item.ref}
          navigation={false}
          modules={[EffectCoverflow, EffectCreative, Autoplay]}
          className="methods-custom-swiper"
          spaceBetween={50}
          freeMode={true}
          slidesPerView={"auto"}
          speed={10000}
          dir={item.dir}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          allowTouchMove={false}
          pagination={false}
          loop={true}
          direction={"horizontal"}
          id="methods-swiper"
        >
          {items(item)}
        </Swiper>
      </div>
    );
  });
};

export default HorizontalSwiper;
