import { Image } from "antd";
import React from "react";
import styles from "./style.module.scss";

const NoData = ({
  title = "",
  height = "auto",
  padding = undefined,
  containerStyle,
  titleStyle,
  imgWidth = 309,
  fontSize = undefined,
  color = undefined,
}) => {
  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      style={{ height: height, padding: padding }}
    >
      <Image
        width={imgWidth}
        src={"/images/utils/noData.png"}
        alt="noData"
        preview={false}
        className={`${styles.img}`}
      />
      <div className={`${styles.title} ${titleStyle}`} style={{ fontSize: fontSize, color: color }}>
        {title}
      </div>
    </div>
  );
};

export default NoData;
