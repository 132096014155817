import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../../enumerations/queryKey";
import { apiGetMyProjectDetail, apiGetMyProjectList } from "../service";

export const useGetMyProjectList = ({ options, params }) => {
  return useQuery(
    [queryKey.MY_PROJECT_LIST, params],
    () =>
      apiGetMyProjectList(params).then((res) => {
        res.data.data.data = res.data.data.data.map((item) => ({ ...item, key: item.id }));
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetInfiniteMyProjectList = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.MY_PROJECT_LIST, params],
    ({ pageParam = 1 }) =>
      apiGetMyProjectList({ ...params, page: pageParam }).then((res) => {
        const { data } = res.data;
        // data.data = data.data.map((item) => {
        //   return { ...item, key: item.id };
        // });
        return { data: data.data, last_page: data.last_page };
      }),
    {
      ...options,
    },
  );
};

export const useGetMyProjectDetail = ({ options, params }) => {
  return useQuery(
    [queryKey.MY_PROJECT_DETAIL, params],
    () => apiGetMyProjectDetail(params).then((res) => res.data.data.data[0]),
    {
      ...options,
    },
  );
};
