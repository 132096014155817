// import { toast } from "../components";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import parseUrl from "parse-url";
import { toast } from "../components";
import localStorageKey from "../enumerations/localStorageKey";

dayjs.extend(utc);
dayjs.extend(timezone);

export const copyText = (text, successMessageContent) => {
  if (!text) return;
  // navigator.clipboard.writeText(text);
  const selectGroup = document.getElementById("copy-group");
  let tempArea = document.createElement("textarea");
  selectGroup?.appendChild(tempArea);
  tempArea.innerText = text;
  tempArea.select();
  document.execCommand("Copy");
  selectGroup?.removeChild(tempArea);

  toast({ content: successMessageContent, type: "success" });
};

export const envType = {
  NUMBER: "number",
  BOOLEAN: "boolean",
  ARRAY: "array",
  STRING: "string",
};

export const envChangeType = (target, type = envType.STRING) => {
  switch (type) {
    case envType.NUMBER:
    case envType.BOOLEAN:
      return JSON.parse(process.env[target]);
    case envType.ARRAY:
      return process.env[target]?.split(",");
    default:
      return process.env[target];
  }
};

export const getCurrency = () => {
  const storageCurrency = localStorage.getItem(localStorageKey.CURRENCY);
  const queryStringObject = parseUrl(window.location.href).query;
  const queryCurrency = queryStringObject.currency;
  return storageCurrency || queryCurrency || envChangeType("REACT_APP_DEFAULT_CURRENCY");
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getNowLang = () => {
  const storageLang = localStorage.getItem(
    localStorageKey[envChangeType("REACT_APP_DEFAULT_LANG")],
  );
  const queryStringObject = parseUrl(window.location.href).query;
  const queryLang = queryStringObject.lang;
  return storageLang || queryLang || envChangeType("REACT_APP_DEFAULT_LANG");
};

export const middleEllipsis = ({
  inputString,
  minStringLength = 35,
  firstLetterLength = 3,
  secondLetterLength = 3,
  symbol = "...",
}) => {
  if (inputString?.length > minStringLength) {
    return (
      inputString.substr(0, firstLetterLength) +
      symbol +
      inputString.substr(inputString?.length - secondLetterLength, inputString.length)
    );
  }
  return inputString;
};

export const handlePrevSwiper = (ref) => {
  if (!ref.current) return;
  ref.current.swiper.slidePrev();
};

export const handleNextSwiper = (ref) => {
  if (!ref.current) return;
  ref.current.swiper.slideNext();
};

export const reset = (ref) => {
  ref?.current.resetFields();
};

export const checkInputHasNull = (ref, setState = () => {}) => {
  const currentParams = ref.current.getFieldsValue();
  const objectKeys = Object.keys(currentParams);
  let hasNull = false;
  objectKeys.map((key) => {
    if (
      currentParams[key] === undefined ||
      currentParams[key] === null ||
      currentParams[key] === ""
    ) {
      hasNull = true;
    }
    return key;
  });
  setState(hasNull);
};

export const handleClickApp = (type, refetch, onClickIOS) => {
  let ua = navigator.userAgent;
  let android = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1; // android
  let iOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
  switch (type) {
    case "url":
      if (android) {
        return "/app-download/android";
      } else {
        return "/app-download/ios";
      }
    case "navigate":
      if (android) {
        window.location.href = "/app-download/android";
      } else {
        window.location.href = "/app-download/ios";
      }
      break;
    case "download":
      if (android) {
        refetch().then((res) => {
          window.location.href = res.data.apk;
        });
      } else {
        onClickIOS();
      }
      break;
    case "app-download":
      refetch().then((res) => {
        if (android) {
          window.location.href = res.data.google;
        } else {
          window.location.href = res.data.appstore;
        }
      });
      break;
    default:
      break;
  }
};

export const getNextPageParam = (lastPage, pages) => {
  if (pages.length < lastPage.last_page) {
    return pages.length + 1;
  } else {
    return undefined;
  }
};

export const loadMore = (isFetchingNextPage, localData, fetchNextPage) => {
  if (isFetchingNextPage === false && localData.length > 0) {
    fetchNextPage();
  }
};

export const infiniteDataProcess = (data, setLocalData) => {
  const page_length = data?.pages.length;
  if (page_length <= 1) {
    setLocalData([...data.pages[0]?.data]);
  } else {
    setLocalData((prevState) => {
      return [...prevState, ...data.pages[page_length - 1].data];
    });
  }
};

export const formatDate = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  return dayjs.unix(date).tz(dayjs.tz.guess()).format(format);
};

export const USER_FIRST_TAB_PATH = "/user/my-project?tab=my-project";
