import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Partner from "../Partner";
import TopArea from "../TopArea";
import Work from "../Work";
import styles from "./style.module.scss";

const Affiliate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.affiliate}>
      <TopArea />
      <Work />
      <Partner />
      {/* <img src={candy1} alt="candy" className={styles.candy1} width={85} /> */}
      {/* <img src={candy2} alt="candy" className={styles.candy2} width={100} /> */}
    </div>
  );
};

export default Affiliate;
