import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import queryKey from "../../enumerations/queryKey";
import { apiGetCs, apiGetInvitation, apiGetMyIncome, apiGetUser } from "./service";

export const useGetUser = (options) => {
  return useQuery([queryKey.USER], () => apiGetUser().then((res) => res.data.data), {
    ...options,
  });
};

export const useGetCs = (options) => {
  return useQuery([queryKey.CS], () => apiGetCs().then((res) => res.data), {
    ...options,
  });
};

export const useGetMyIncome = ({ options, params }) => {
  return useQuery(
    [queryKey.MY_INCOME, params],
    () =>
      apiGetMyIncome(params).then((res) => {
        let { data } = res.data;
        data.data = data.data.map((item, index) => {
          return { ...item, key: index };
        });
        return data;
      }),
    {
      ...options,
    },
  );
};

export const useGetMyIncomeInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.MY_INCOME, params],
    ({ pageParam = 1 }) =>
      apiGetMyIncome({ ...params, page: pageParam }).then((res) => {
        let { data } = res.data;
        data.data = data.data.map((item, index) => {
          return { ...item, key: index };
        });
        return { data: data.data, last_page: data.last_page };
      }),
    {
      ...options,
    },
  );
};

export const useGetInvitation = ({ options }) => {
  return useQuery(
    [queryKey.INVITATION],
    () =>
      apiGetInvitation().then((res) => {
        return res.data.data;
      }),
    {
      ...options,
    },
  );
};

export const useGetInvitationInfinite = ({ options, params }) => {
  return useInfiniteQuery(
    [queryKey.INVITATION, params],
    ({ pageParam = 1 }) =>
      apiGetInvitation({ ...params, page: pageParam }).then((res) => {
        // res.data.data = res.data.data.map((item) => ({ ...item, key: item.id }));
        return { data: res.data.data.partners, last_page: 1, other: res.data.data };
      }),
    {
      ...options,
    },
  );
};
