import { Progress as ProgressAntd } from "antd";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Progress = ({ data }) => {
  const { t } = useTranslation();

  return (
    // <div className="progress">
    //   <div
    //     className={`progress-bar progress-bar-striped progress-bar-animated ${
    //       data?.progress <= 75 ? "bg-success" : data.progress >= 100 ? "bg-danger" : "bg-warning"
    //     }`}
    //     role="progressbar"
    //     style={{ width: `${data?.progress}%` }}
    //     aria-valuenow="10"
    //     aria-valuemin="0"
    //     aria-valuemax="100"
    //   >
    //     <div className="text">{data?.progress + "%"}</div>
    //   </div>
    // </div>

    <ProgressAntd
      className="progress-antd"
      percent={data?.progress}
      percentPosition={{
        align: "end",
        type: "inner",
      }}
      format={(percent) => <div className="percent">{percent + "%"}</div>}
      strokeColor={{
        "0%": "var(--project_progress1)",
        "50%": "var(--project_progress2)",
        "100%": "var(--project_progress3)",
      }}
    />
  );
};

export default Progress;
