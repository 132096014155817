import { useTranslation } from "react-i18next";
import { useGetData } from "../../../../hooks/indexData/indexData.hook";
import { envChangeType } from "../../../../plugins/utils";
import ShareTable from "../ShareTable";
import styles from "./style.module.scss";

const Bonuses = () => {
  const { t } = useTranslation();

  const { data: indexData } = useGetData();

  const data = [
    {
      type: "label",
      bountyChannel: t("label.bountyChannel"),
      rewards: t("label.rewards"),
      specialTips: t("label.specialTips"),
    },
    {
      type: "cell",
      bountyChannel: "News Channel",
      min_reward: 20,
      max_reward: 5000,
      specialTips: t("share.bonuses.specialTips1"),
    },
    {
      type: "cell",
      bountyChannel: "Tik Tok",
      min_reward: 20,
      max_reward: 1000,
      specialTips: t("share.bonuses.specialTips2"),
    },
    {
      type: "cell",
      bountyChannel: "Youtube",
      min_reward: 20,
      max_reward: 1000,
      specialTips: t("share.bonuses.specialTips3", {
        currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
      }),
    },
    {
      type: "cell",
      bountyChannel: "Forum",
      min_reward: 20,
      max_reward: 1000,
      specialTips: t("share.bonuses.specialTips4"),
    },
    {
      type: "cell",
      bountyChannel: "Blog",
      min_reward: 20,
      max_reward: 500,
      specialTips: t("share.bonuses.specialTips5"),
    },
    {
      type: "cell",
      bountyChannel: "Social Media",
      min_reward: 3,
      max_reward: 100,
      specialTips: t("share.bonuses.specialTips6"),
    },
  ];

  const columns = [
    {
      title: t("label.bountyChannel"),
      dataIndex: "bountyChannel",
      key: "bountyChannel",
      width: "25%",
    },
    {
      title: t("label.rewards"),
      dataIndex: "rewards",
      key: "rewards",
      width: "25%",
      render: (_, record) => {
        return `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${
          record.min_reward
        } - ${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${
          record.max_reward
        }`;
      },
    },
    {
      title: t("label.specialTips"),
      dataIndex: "specialTips",
      key: "specialTips",
      width: "50%",
      render: (dom) => {
        return <div className={styles.specialTips}>{dom}</div>;
      },
    },
  ];

  return (
    <div className={styles.bonuses}>
      <div className={styles.subTitle}>{t("share.bonuses.subTitle")}</div>
      <ShareTable data={data} columns={columns} />
      <div className={styles.content}>
        <div>{t("share.bonuses.content.title")}</div>
        <ol>
          <li>{t("share.bonuses.content1")}</li>
          <li>
            {t("share.bonuses.content2")}
            <a className={styles.email} href={`mailto:${indexData?.company_info.email}`}>
              {indexData?.company_info.email}
            </a>
          </li>
          <li>{t("share.bonuses.content3")}</li>
          <li>{t("share.bonuses.content4")}</li>
          <li>{t("share.bonuses.content5")}</li>
        </ol>
      </div>
    </div>
  );
};

export default Bonuses;
