import { Image, List } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NormalTitle } from "../../../../components";
import { useLoading } from "../../../../context/LoadingContext";
import { useMobile } from "../../../../context/MobileContext";
import { envChangeType } from "../../../../plugins/utils";
import { useGetBlog, useGetBlogDetail } from "../../hooks/index.hook";
import styles from "./style.module.scss";
import "./style.scss";

const Detail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isMobile } = useMobile();
  const { setMainLoading } = useLoading();
  const { state: blogs } = useLocation();
  const [recentBlogs, setRecentBlogs] = useState([]);

  const { refetch, isFetching: isFetchingBlogs } = useGetBlog({
    params: { page: 1 },
    options: { enabled: false },
  });
  const { data, isFetching } = useGetBlogDetail({
    options: { enabled: !!id },
    params: { id },
  });

  const handleClickRecent = (targetData) => {
    navigate(`/blog/${targetData.id}`, { state: blogs });
  };

  const renderItem = (_item) => {
    return (
      <List.Item className={styles.listItem} onClick={() => handleClickRecent(_item)}>
        <div className={styles.img}>
          <Image src={_item?.article_image} alt="img" preview={false} className={styles.img} />
        </div>
        <div className={styles.subTitle}>{_item.title}</div>
      </List.Item>
    );
  };

  useEffect(() => {
    if (!blogs && !isMobile) {
      refetch().then((res) => {
        setRecentBlogs(res.data?.data);
      });
    } else {
      setRecentBlogs(blogs);
    }
  }, [blogs, navigate]);

  useEffect(() => {
    if (!data) return;
    const titleEl = document.getElementById("head-title");
    titleEl.innerHTML = `${envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")} - ${data?.title}`;
  }, [data]);

  useEffect(() => {
    setMainLoading(isFetching || isFetchingBlogs);
  }, [isFetching, isFetchingBlogs]);

  useEffect(() => {
    if (!recentBlogs && !data) return;
    console.log("recentBlogs", recentBlogs);
    console.log("data", data);
  }, [recentBlogs, data]);

  return (
    <div className={styles.detail}>
      <NormalTitle title={t("normal.blog")} />
      <div className={styles.mainContainer}>
        <div className={styles.main}>
          {data && (
            <div className={styles.imgContainer}>
              <Image src={data?.article_image} alt="img" preview={false} className={styles.img} />
            </div>
          )}
          <div className={styles.title}>{data?.title}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: data?.article_content }}
          />
        </div>
        <div className={styles.recent}>
          <List
            size="large"
            header={<div className={styles.title}>{t("blog.detail.recent")}</div>}
            bordered
            dataSource={recentBlogs?.filter((item) => item.id !== data?.id).slice(0, 6)}
            renderItem={(item) => renderItem(item)}
            className={styles.list}
          />
        </div>
      </div>
    </div>
  );
};

export default Detail;
