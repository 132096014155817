import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useMobile } from "../../context/MobileContext";
import CustomButton from "../CustomButton";
import styles from "./style.module.scss";
import "./style.scss";

// data = {
//   title,
//   content,
//   buttonText,
//   isHtml,
//   onOk,
//   showCloseIcon,
// };

const FillModal = ({ open, setOpen, loading, data }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();

  const handleClickOk = () => {
    if (data?.onOk) {
      data?.onOk();
      return;
    } else {
      setOpen(false);
    }
  };

  return (
    <Modal
      title={data?.title}
      open={open}
      onOk={handleClickOk}
      width={isMobile ? 326 : 540}
      onCancel={() => setOpen(false)}
      closeIcon={data?.showCloseIcon}
      wrapClassName={`${styles.homeModal} fill-modal`}
      destroyOnClose={true}
      loading={loading}
      footer={[
        <CustomButton
          key="button"
          buttonStyle={`${styles.customButtonStyle} `}
          onClick={handleClickOk}
        >
          {data?.buttonText ? data?.buttonText : t("normal.confirm.upper")}
        </CustomButton>,
      ]}
    >
      {data?.isHtml ? (
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: data?.content }} />
      ) : (
        data?.content
      )}
    </Modal>
  );
};

export default FillModal;
