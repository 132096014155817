import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";
import { NormalCards } from "../../../../components";
import { envChangeType } from "../../../../plugins/utils";
import card1 from "../../images/dashboard/card1.png";
import card2 from "../../images/dashboard/card2.png";
import card3 from "../../images/dashboard/card3.png";
import card4 from "../../images/dashboard/card4.png";
import card5 from "../../images/dashboard/card5.png";
import card6 from "../../images/dashboard/card6.png";
import card7 from "../../images/dashboard/card7.png";
import card8 from "../../images/dashboard/card8.png";
import styles from "./style.module.scss";

const DashboardArea = () => {
  const { t } = useTranslation();

  const statisticEnum = {
    aeon: [
      {
        value: 6.3,
        precision: 1,
        name: "about.dashboard.statistic.name1",
        background: "var(--about_dashboard_color1)",
      },
      {
        value: 180,
        precision: 0,
        name: "about.dashboard.statistic.name2",
        background: "var(--about_dashboard_color2)",
      },
      {
        value: 20,
        precision: 0,
        name: "about.dashboard.statistic.name3",
        background: "var(--about_dashboard_color3)",
      },
      {
        value: 500000,
        precision: 0,
        name: "about.dashboard.statistic.name4",
        background: "var(--about_dashboard_color4)",
      },
    ],
    alrminer: [
      {
        value: 7.9,
        precision: 1,
        name: "about.dashboard.statistic.name1",
        background: "var(--about_dashboard_color1)",
      },
      {
        value: 160,
        precision: 0,
        name: "about.dashboard.statistic.name2",
        background: "var(--about_dashboard_color2)",
      },
      {
        value: 30,
        precision: 0,
        name: "about.dashboard.statistic.name3",
        background: "var(--about_dashboard_color3)",
      },
      {
        value: 700000,
        precision: 0,
        name: "about.dashboard.statistic.name4",
        background: "var(--about_dashboard_color4)",
      },
    ],
    okaliomining: [
      {
        value: 8.8,
        precision: 1,
        name: "about.dashboard.statistic.name1",
        background: "var(--about_dashboard_color1)",
      },
      {
        value: 130,
        precision: 0,
        name: "about.dashboard.statistic.name2",
        background: "var(--about_dashboard_color2)",
      },
      {
        value: 60,
        precision: 0,
        name: "about.dashboard.statistic.name3",
        background: "var(--about_dashboard_color3)",
      },
      {
        value: 800000,
        precision: 0,
        name: "about.dashboard.statistic.name4",
        background: "var(--about_dashboard_color4)",
      },
    ],
    default: [
      {
        value: 7.9,
        precision: 1,
        name: "about.dashboard.statistic.name1",
        background: "var(--about_dashboard_color1)",
      },
      {
        value: 160,
        precision: 0,
        name: "about.dashboard.statistic.name2",
        background: "var(--about_dashboard_color2)",
      },
      {
        value: 30,
        precision: 0,
        name: "about.dashboard.statistic.name3",
        background: "var(--about_dashboard_color3)",
      },
      {
        value: 700000,
        precision: 0,
        name: "about.dashboard.statistic.name4",
        background: "var(--about_dashboard_color4)",
      },
    ],
  };

  const renderStatistic = () => {
    const _statistic =
      statisticEnum[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")] || statisticEnum["default"];
    return _statistic.map((item, index) => {
      return (
        <VisibilitySensor partialVisibility key={index}>
          {({ isVisible }) => {
            return (
              <div key={index} className={styles.statistic} style={{ background: item.background }}>
                {isVisible ? (
                  <>
                    <div className={styles.value}>
                      <CountUp start={0} end={item.value} decimals={item.precision} separator="," />
                    </div>
                    <div className={styles.name}>{t(item.name)}</div>
                  </>
                ) : null}
              </div>
            );
          }}
        </VisibilitySensor>
      );
    });
  };

  const cardsEnum = [
    {
      key: "card1",
      src: card1,
      title: t("about.dashboard.card.title1"),
      content: t("about.dashboard.card.content1", {
        count: statisticEnum[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")]
          ? statisticEnum[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")][2].value
          : statisticEnum["default"][2].value,
      }),
    },
    {
      key: "card2",
      src: card2,
      title: t("about.dashboard.card.title2"),
      content: t("about.dashboard.card.content2"),
    },
    {
      key: "card3",
      src: card3,
      title: t("about.dashboard.card.title3"),
      content: t("about.dashboard.card.content3"),
    },
    {
      key: "card4",
      src: card4,
      title: t("about.dashboard.card.title4"),
      content: t("about.dashboard.card.content4"),
    },
    {
      key: "card5",
      src: card5,
      title: t("about.dashboard.card.title5"),
      content: t("about.dashboard.card.content5"),
    },
    {
      key: "card6",
      src: card6,
      title: t("about.dashboard.card.title6"),
      content: t("about.dashboard.card.content6"),
    },
    {
      key: "card7",
      src: card7,
      title: t("about.dashboard.card.title7"),
      content: t("about.dashboard.card.content7"),
    },
    {
      key: "card8",
      src: card8,
      title: t("about.dashboard.card.title8"),
      content: t("about.dashboard.card.content8"),
    },
  ];

  return (
    <div className={`${styles.dashboardArea} dashboard-area`}>
      <div className={styles.title}>
        {t("about.dashboard.title", { logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY") })}
      </div>
      <div className={styles.statisticContainer}>{renderStatistic()}</div>
      <div className={styles.cardsContainer}>
        <NormalCards
          data={cardsEnum}
          vertical={true}
          customCardStyle={styles.customCardStyle}
          customTitleStyle={styles.customTitleStyle}
          customContentStyle={styles.customContentStyle}
        />
      </div>
    </div>
  );
};

export default DashboardArea;
