import { Col } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const RightGroup = ({ span, title, type, closeURL, goBack }) => {
  const { t } = useTranslation();

  if (closeURL) {
    return (
      <Col className={styles.rightGroup}>
        <button>close</button>
      </Col>
    );
  }

  return (
    <Col span={span}>
      <span>{title ? t(title) : ""}</span>
    </Col>
  );
};

export default RightGroup;
