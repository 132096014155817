import { Trans, useTranslation } from "react-i18next";
import { envChangeType } from "../../../../plugins/utils";
import ShareTable from "../ShareTable";
import styles from "./style.module.scss";

const Bonuses = () => {
  const { t } = useTranslation();
  const REMOVE_BIRTHDAY_BONUS = ["okaliomining"];
  const data = {
    okaliomining: [
      {
        type: "label",
        promotion: t("label.promotion"),
        invest: t("label.invest"),
        advancementBonus: t("label.advancementBonus"),
        birthdayBonus: t("label.birthdayBonus"),
        profit: t("label.profit"),
      },
      {
        type: "cell",
        promotion: 1,
        invest: 1000,
        advancementBonus: 10,
        birthdayBonus: 0,
        profit: 0.01,
      },
      {
        type: "cell",
        promotion: 2,
        invest: 5000,
        advancementBonus: 50,
        birthdayBonus: 0,
        profit: 0.03,
      },
      {
        type: "cell",
        promotion: 3,
        invest: 10000,
        advancementBonus: 100,
        birthdayBonus: 0,
        profit: 0.06,
      },
      {
        type: "cell",
        promotion: 4,
        invest: 50000,
        advancementBonus: 500,
        birthdayBonus: 0,
        profit: 0.09,
      },
      {
        type: "cell",
        promotion: 5,
        invest: 100000,
        advancementBonus: 1500,
        birthdayBonus: 0,
        profit: 0.15,
      },
      {
        type: "cell",
        promotion: 6,
        invest: 300000,
        advancementBonus: 5000,
        birthdayBonus: 0,
        profit: 0.35,
      },
      {
        type: "cell",
        promotion: 7,
        invest: 600000,
        advancementBonus: 10000,
        birthdayBonus: 0,
        profit: 0.65,
      },
      {
        type: "cell",
        promotion: 8,
        invest: 1000000,
        advancementBonus: 30000,
        birthdayBonus: 0,
        profit: 1.0,
      },
      {
        type: "cell",
        promotion: 9,
        invest: 3000000,
        advancementBonus: 100000,
        birthdayBonus: 0,
        profit: 1.5,
      },
      {
        type: "cell",
        promotion: 10,
        invest: 5000000,
        advancementBonus: 500000,
        birthdayBonus: 0,
        profit: 1.8,
      },
    ],
    default: [
      {
        type: "label",
        promotion: t("label.promotion"),
        invest: t("label.invest"),
        advancementBonus: t("label.advancementBonus"),
        birthdayBonus: t("label.birthdayBonus"),
        profit: t("label.profit"),
      },
      {
        type: "cell",
        promotion: 1,
        invest: 10000,
        advancementBonus: 100,
        birthdayBonus: 18,
        profit: 0.01,
      },
      {
        type: "cell",
        promotion: 2,
        invest: 30000,
        advancementBonus: 350,
        birthdayBonus: 58,
        profit: 0.02,
      },
      {
        type: "cell",
        promotion: 3,
        invest: 50000,
        advancementBonus: 550,
        birthdayBonus: 88,
        profit: 0.06,
      },
      {
        type: "cell",
        promotion: 4,
        invest: 100000,
        advancementBonus: 1500,
        birthdayBonus: 188,
        profit: 0.09,
      },
      {
        type: "cell",
        promotion: 5,
        invest: 300000,
        advancementBonus: 5000,
        birthdayBonus: 388,
        profit: 0.16,
      },
      {
        type: "cell",
        promotion: 6,
        invest: 500000,
        advancementBonus: 10000,
        birthdayBonus: 688,
        profit: 0.25,
      },
      {
        type: "cell",
        promotion: 7,
        invest: 1000000,
        advancementBonus: 22000,
        birthdayBonus: 1888,
        profit: 0.68,
      },
      {
        type: "cell",
        promotion: 8,
        invest: 5000000,
        advancementBonus: 220000,
        birthdayBonus: 5888,
        profit: 1.0,
      },
      {
        type: "cell",
        promotion: 9,
        invest: 10000000,
        advancementBonus: 600000,
        birthdayBonus: 15888,
        profit: 1.1,
      },
      {
        type: "cell",
        promotion: 10,
        invest: 50000000,
        advancementBonus: 3000000,
        birthdayBonus: 88888,
        profit: 1.7,
      },
    ],
  };

  const columns = [
    {
      title: t("label.promotion"),
      dataIndex: "promotion",
      key: "promotion",
      width: "20%",
      render: (dom) => {
        return `VIP${dom}`;
      },
    },
    {
      title: t("label.invest"),
      dataIndex: "invest",
      key: "invest",
      width: "20%",
      render: (dom) => {
        return `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${dom}+`;
      },
    },
    {
      title: t("label.advancementBonus"),
      dataIndex: "advancementBonus",
      key: "advancementBonus",
      width: "20%",
      render: (dom) => {
        return `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${dom}`;
      },
    },
    {
      title: t("label.birthdayBonus"),
      dataIndex: "birthdayBonus",
      key: "birthdayBonus",
      width: "20%",
      hidden: REMOVE_BIRTHDAY_BONUS.includes(envChangeType("REACT_APP_PRODUCTION_NAME_DEV")),
      render: (dom) => {
        return `${t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}${dom}`;
      },
    },
    {
      title: t("label.profit"),
      dataIndex: "profit",
      key: "profit",
      width: "20%",
      render: (dom) => {
        return <div className={styles.specialTips}>{`${dom}%`}</div>;
      },
    },
  ];

  return (
    <div className={styles.vip}>
      <ShareTable
        data={data[envChangeType("REACT_APP_PRODUCTION_NAME_DEV")] || data["default"]}
        columns={columns}
        width={500}
      />
      <ol className={styles.subTitle}>
        <li>
          {t(
            [
              `share.vip.subTitle1.${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}`,
              "share.vip.subTitle1",
            ],
            {
              currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
            },
          )}
        </li>
        {REMOVE_BIRTHDAY_BONUS.includes(envChangeType("REACT_APP_PRODUCTION_NAME_DEV")) ? null : (
          <li>
            {t("share.vip.subTitle2", {
              currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
            })}
          </li>
        )}
        <li>
          {t([
            `share.vip.subTitle3.${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}`,
            "share.vip.subTitle3",
          ])}
        </li>
      </ol>
      <div className={styles.content}>
        <Trans
          i18nKey={[
            `share.vip.content.${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}`,
            "share.vip.content",
          ]}
        />
      </div>
    </div>
  );
};

export default Bonuses;
