export const channelEnum = {
  BNB: { name: "BNB", src: "/images/utils/methods/binance.png", showAtHome: true },
  BTC: { name: "BTC", src: "/images/utils/methods/bitcoin.png", showAtHome: true },
  COINBASE: { name: "COINBASE", src: "/images/utils/methods/coinbase.png", showAtHome: true },
  ETH: { name: "ETH", src: "/images/utils/methods/eth.png", showAtHome: true },
  KRAKEN: { name: "KRAKEN", src: "/images/utils/methods/kraken.png", showAtHome: true },
  KCS: { name: "KCS", src: "/images/utils/methods/kucoin.png", showAtHome: true },
  LTC: { name: "LTC", src: "/images/utils/methods/ltc.png", showAtHome: true },
  USDT: { name: "USDT", src: "/images/utils/methods/usdt.png", showAtHome: true },
  "USDT-TRC20": { name: "USDT-TRC20", src: "/images/utils/methods/usdt.png", showAtHome: false },
  "USDT-ERC20": { name: "USDT-ERC20", src: "/images/utils/methods/usdt.png", showAtHome: false },
  "USDC-ERC20": { name: "USDC-ERC20", src: "/images/utils/methods/usdc.png", showAtHome: false },
  DOGE: { name: "DOGE", src: "/images/utils/methods/doge.png", showAtHome: false },
  SOL: { name: "SOL", src: "/images/utils/methods/sol.png", showAtHome: false },
  "XRP-Ripple": {
    name: "XRP-Ripple",
    src: "/images/utils/methods/XRP-Ripple.png",
    showAtHome: false,
  },
  "TRUMP-Solana": {
    name: "TRUMP-Solana",
    src: "/images/utils/methods/TRUMP-Solana.png",
    showAtHome: false,
  },
  BCH: {
    name: "BCH",
    src: "/images/utils/methods/bch.png",
    showAtHome: false,
  },
};
