import { QRCode } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useGetApp } from "../../hooks/download/download.hook";
import { envChangeType, handleClickApp } from "../../plugins/utils";
import AddImage from "./images/add.svg";
import Arrow from "./images/arrow.svg";
import NoDevice from "./images/device.svg";
import EveryoneImage from "./images/everyone.svg";
import InfoImage from "./images/info.svg";
import ShareImage from "./images/share.svg";
import TopLeftIcon from "./images/top_left.png";
import TopRightIcon from "./images/top_right.png";
import styles from "./style.module.scss";

const AndroidDownload = () => {
  const { t } = useTranslation();
  const { refetch, isFetching } = useGetApp({ enabled: false });

  const handleDownload = () => {
    handleClickApp("download", refetch);
  };

  return (
    <div className={styles.androidDownload}>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={TopLeftIcon} width={42} height={42} alt="secure" />
          <span>{envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}</span>
        </div>
        <img src={TopRightIcon} width={127} height={56} alt="search" />
      </div>
      <div className={styles.appContainer}>
        <img
          className={styles.logo}
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/logo.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={80}
          height={80}
        />
        <div className={styles.appTitleContainer}>
          <div className={styles.title}>{t("appdownload.android.title")}</div>
          <div className={styles.desc}>{envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}</div>
        </div>
      </div>
      <div className={styles.displayContainer}>
        <div className={styles.appInfoContainer}>
          <Trans i18nKey={"appdownload.android.downloadcounts"} />
          <div className={styles.ratingContainer}>
            <img src={EveryoneImage} alt="everyone" width={16} height={16} />
            <div>
              {t("appdownload.android.rating")}
              <img src={InfoImage} alt="info" width={16} height={16} />
            </div>
          </div>
        </div>
        <div className={styles.border} />
        <div className={styles.qrContainer}>
          <QRCode className={styles.qr} value={window.location.href} size={120} bgColor={"#fff"} />
        </div>
      </div>
      <div className={styles.button} onClick={handleDownload}>
        {t("appdownload.android.install")}
      </div>
      <div className={styles.utilsContainer}>
        <div className={styles.linkContainer}>
          <img src={ShareImage} alt="share" width={22} height={22} />
          <span>{t("appdownload.android.share")}</span>
        </div>
        <div className={styles.linkContainer}>
          <img src={AddImage} alt="wishlist" width={22} height={22} />
          <span>{t("appdownload.android.whishlist")}</span>
        </div>
      </div>
      <div className={styles.deviceContainer}>
        <img src={NoDevice} alt="no device found" width={16} height={16} />
        <span>{t("appdownload.android.nodevice")}</span>
      </div>
      <div className={styles.gallery}>
        <img
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner01.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={115}
          height={198}
        />
        <img
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner02.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={115}
          height={198}
        />
        <img
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/appDownload/banner03.png`}
          alt={envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY")}
          width={115}
          height={198}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.leftFooter}>
          <div className={styles.aboutTitle}>{t("appdownload.android.about")}</div>
          <Trans i18nKey={"appdownload.android.content"} className={styles.aboutContent} />
        </div>
        <img src={Arrow} width={16} height={16} alt="more" />
      </div>
    </div>
  );
};

export default AndroidDownload;
