import { RightOutlined } from "@ant-design/icons";
import { FloatButton, Image, List } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import { useMobile } from "../../context/MobileContext";
import "./style.scss";

const CsFloatButton = ({ setOpenModal, setModalData, userInfo, cs }) => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { isLogin } = useAuth();
  const target = document.getElementById("floatButton");

  const renderCsList = (cs) => {
    const dataSource = [
      { title: t("home.cs.tg"), csData: cs?.tme },
      { title: t("home.cs.whatsApp"), csData: cs?.wame },
    ];
    return (
      <div className="cs-list">
        <List
          size="large"
          bordered
          dataSource={dataSource}
          renderItem={(item) => {
            if (!item.csData) return;
            return (
              <List.Item extra={<RightOutlined />} onClick={() => window.open(item.csData)}>
                {item.title}
              </List.Item>
            );
          }}
        />
      </div>
    );
  };

  const handleClickTg = () => {
    window.location.href = userInfo?.zhanshi;
  };

  const handleClickCs = () => {
    if (!isLogin) {
      window.location.href = cs?.tme;
    } else {
      setOpenModal(true);
      setModalData({
        title: t("normal.announcement"),
        isHtml: false,
        content: renderCsList(cs),
      });
    }
  };

  // useEffect(() => {
  //   console.log("target", target);
  //   if (!target) return;
  //   const script = document.createElement("script");
  //   script.src = "//code.jivosite.com/widget/OHPn0guaLC";
  //   script.async = true;
  //   target?.appendChild(script);

  //   return () => {
  //     target?.removeChild(script);
  //   };
  // }, [target]);

  useEffect(() => {
    console.log("cs", cs);
  }, [cs]);

  return (
    <div className="cs-float-button" id="floatButton">
      <FloatButton.Group
        shape="circle"
        style={{
          insetInlineEnd: isMobile ? 10 : 24,
        }}
      >
        {isLogin && userInfo?.zhanshi && (
          <FloatButton
            icon={
              <Image src={"/images/utils/tg.png"} alt="tg" preview={false} className="float-img" />
            }
            className="cs-float-btn"
            shape="square"
            onClick={handleClickTg}
          />
        )}
        {((isLogin && (cs?.tme || cs?.wame)) || (!isLogin && cs?.tme)) && (
          <FloatButton
            icon={
              <Image src={"/images/utils/cs.png"} alt="cs" preview={false} className="float-img" />
            }
            className="cs-float-btn"
            onClick={handleClickCs}
          />
        )}
        {/* <FloatButton.BackTop
          target={() => document.getElementById("contentContainer")}
          // visibilityHeight={0}
        /> */}
      </FloatButton.Group>
    </div>
  );
};

export default CsFloatButton;
