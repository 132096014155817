import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../components";
import { envChangeType } from "../../plugins/utils";
import { Bonuses, Rewards, VIP } from "./components";
import styles from "./style.module.scss";

const Share = () => {
  const { t } = useTranslation();

  const sectionEnum = [
    // {
    //   title: t("share.title1", { logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY") }),
    //   content: <Benefits />,
    // },
    {
      title: t("share.title2"),
      content: <Bonuses />,
    },
    {
      title: t("share.title3"),
      content: <VIP />,
    },
    {
      title: t("share.title4"),
      content: <Rewards />,
    },
  ];
  return (
    <div className={styles.share}>
      {sectionEnum.map((section, index) => {
        return (
          <div key={index} className={styles.section}>
            <NormalTitle
              customNormalTitleStyle={styles.customNormalTitleStyle}
              title={section.title}
            />
            {section.content}
          </div>
        );
      })}

      {/* <img src={WelfareBonus} alt="Welfare Bonus" />
      <img src={VIPBenefit} alt="VIP Exclusive" />
      <img src={UserReward} alt="User Reward" /> */}
      <div className={styles.footerText}>
        {t("share.footer", { logo: envChangeType("REACT_APP_PRODUCTION_NAME_DISPLAY") })}
      </div>
    </div>
  );
};

export default Share;
