import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { infiniteDataProcess, loadMore } from "../../plugins/utils";
import { default as RecordCard } from "./components/RecordCard";
import styles from "./style.module.scss";

const RecordCards = ({
  data,
  columns,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  loading = false,
}) => {
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    if (data) {
      infiniteDataProcess(data, setLocalData);
    }
  }, [data]);

  useEffect(() => {
    console.debug("hasNextPage", hasNextPage);
  }, [hasNextPage]);

  useEffect(() => {
    console.log("localData", localData);
  }, [localData]);

  return (
    <div className={`${styles.recordCards} `}>
      <Spin spinning={loading}>
        <RecordCard
          localData={localData}
          loadMore={() => loadMore(isFetchingNextPage, localData, fetchNextPage)}
          hasMore={hasNextPage && localData.length > 0}
          columns={columns}
        />
      </Spin>
    </div>
  );
};

export default RecordCards;
