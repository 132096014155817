import { Image } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProjectCards } from "../../components";
import NoData from "../../components/NoData";
import { useLoading } from "../../context/LoadingContext";
import { envChangeType } from "../../plugins/utils";
import { useGetProject } from "./hooks/index.hook";
import styles from "./style.module.scss";

const Project = () => {
  const { t } = useTranslation();
  const { setMainLoading } = useLoading();

  const { data, isFetching } = useGetProject({ params: { type: 2 } });

  useEffect(() => {
    setMainLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!data) return;
    console.log("contractData", data);
  }, [data]);

  return (
    <div className={styles.project}>
      <div className={styles.banner}>
        <Image
          src={`/images/${envChangeType("REACT_APP_PRODUCTION_NAME_DEV")}/project/banner.png`}
          alt="banner"
          preview={false}
        />
      </div>
      {data?.length >= 0 ? <ProjectCards data={data} /> : <NoData title={t("normal.noData")} />}
    </div>
  );
};

export default Project;
