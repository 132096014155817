import { Spin } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomRadio } from "../../../../components";
import { useGetWithdrawConfig } from "../../hooks/index.hook";
import { Record, Withdraw as WithdrawComponent } from "./components";
import styles from "./style.module.scss";

const Withdraw = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const items = [
    { value: "withdraw", text: t("normal.withdraw") },
    { value: "record", text: t("normal.record") },
  ];
  const [radioValue, setRadioValue] = useState(params.get("radioValue") || items[0].value);

  const { data: withdrawConfigData, isFetching: isFetchingWithdrawConfig } = useGetWithdrawConfig({
    enabled: radioValue === items[0].value,
  });

  const renderContent = () => {
    switch (radioValue) {
      case "withdraw":
        return <WithdrawComponent data={withdrawConfigData} />;
      case "record":
        return <Record items={items} radioValue={radioValue} />;
      default:
        return <WithdrawComponent data={withdrawConfigData} />;
    }
  };

  return (
    <div className={styles.recharge}>
      <div className={styles.radio}>
        <CustomRadio
          items={items}
          defaultValue={items[0].value}
          value={radioValue}
          onClick={(e) =>
            navigate(`/user/withdraw/${e.target.value}?tab=withdraw&radioValue=${e.target.value}`)
          }
        />
      </div>
      <Spin spinning={isFetchingWithdrawConfig}>{renderContent()}</Spin>
    </div>
  );
};

export default Withdraw;
