import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toast } from "../components";
import localStorageKey from "../enumerations/localStorageKey";
import { envChangeType } from "../plugins/utils";
import useLocalStorage from "./useLocalStorage";

const LangContext = createContext({
  changeLanguage: () => {},
  getLanguage: () => {},
  showPopover: false,
  setShowPopover: () => {},
});

export const LangContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useLocalStorage(localStorageKey.LANGUAGE);
  const [showPopover, setShowPopover] = useState(false);

  const changeLanguage = (lang, reload) => {
    setLang(lang);
    i18n.changeLanguage(lang);
    if (reload) {
      toast({ content: t("lang.select.complete"), type: "success" });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const getLanguage = () => {
    return lang;
  };

  useEffect(() => {
    const langSet = window.localStorage.getItem(localStorageKey.LANGUAGE);
    if (langSet) {
      changeLanguage(langSet, false);
    } else {
      changeLanguage(envChangeType("REACT_APP_DEFAULT_LANG"), false);
    }
  }, []);

  return (
    <LangContext.Provider
      value={{
        changeLanguage,
        getLanguage,
        showPopover,
        setShowPopover,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
export const useLanguage = () => {
  const LangValue = useContext(LangContext);
  return LangValue;
};
