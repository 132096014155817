import { LeftOutlined } from "@ant-design/icons";
import { Col } from "antd";
import styles from "./style.module.scss";
const LeftGroup = ({ span, goBack, backURL }) => {
  return (
    <Col span={span} className={styles.container}>
      {backURL ? (
        <div
          onClick={() => {
            goBack(backURL);
          }}
        >
          <LeftOutlined
            style={{ color: "var(--header_text_inactive)", fontSize: "16px", padding: "0 8px" }}
          />
        </div>
      ) : null}
    </Col>
  );
};

export default LeftGroup;
