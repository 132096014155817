import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoading } from "../../../context/LoadingContext";
import localStorageKey from "../../../enumerations/localStorageKey";
import { useGetData } from "../../../hooks/indexData/indexData.hook";
import { useGetHomePopup, useGetPayCaseUrl } from "../hooks/index.hook";

const ApiDataContext = createContext({
  data: {},
  profile: {},
  faq: [],
  popupData: {},
  video: [],
});

export const ApiDataContextProvider = ({ children }) => {
  const [faq, setFaq] = useState([]);
  const { setMainLoading } = useLoading();
  const [profile, setProfile] = useState({});
  const [params] = useSearchParams();

  const { data, isFetching } = useGetData();
  const { data: popupData, isFetching: isFetchingPopupData } = useGetHomePopup();
  const { data: video, isFetching: isFetchingVideo } = useGetPayCaseUrl();

  useEffect(() => {
    setMainLoading(isFetching || isFetchingPopupData || isFetchingVideo);
    if (!data) return;

    setProfile(data.task_list[Object.keys(data.task_list)[0]]);
    const newFaq = (
      Array.isArray(data.chat_list) ? data.chat_list : Object.values(data.chat_list)
    ).map((item) => {
      return {
        key: item.id,
        label: item.title,
        children: <div dangerouslySetInnerHTML={{ __html: item.article_content }} />,
      };
    });
    setFaq(newFaq);
  }, [data, isFetching, isFetchingPopupData, isFetchingVideo]);

  useEffect(() => {
    if (!data) return;
    console.log("homeData", data);
  }, [data]);

  useEffect(() => {
    if (params.get("invcode")) {
      localStorage.setItem(localStorageKey.INVITE_CODE, params.get("invcode"));
    }
  }, [params]);

  useEffect(() => {
    if (!popupData) return;
    console.log("popupData", popupData);
  }, [popupData]);

  useEffect(() => {
    if (!video) return;
    console.log("video", video);
  }, [video]);

  return (
    <ApiDataContext.Provider value={{ data, profile, faq, popupData, video }}>
      {children}
    </ApiDataContext.Provider>
  );
};
export const useApiData = () => {
  const Value = useContext(ApiDataContext);
  return Value;
};
