import req from "./https.js";

import { envChangeType, envType, getCurrency } from "../plugins/utils.js";

// if(!CURRENCY && isAllowedWithoutCurrency(window.location.pathname) === false) {
//   toLogin();
// }

const CURRENCY = getCurrency();

export const request = (method, path, data = null, needAuth = false) =>
  req(
    method,
    `${envChangeType("REACT_APP_PROTOCOL")}://${envChangeType("REACT_APP_MAIN_API")}${path}`,
    data,
    needAuth,
  );

export const requestUpload = (method, path, data = null, needAuth = false) =>
  req(
    method,
    `${envChangeType("REACT_APP_PROTOCOL")}://${envChangeType("REACT_APP_UPLOAD_API")}${path}`,
    data,
    needAuth,
  );

export const requestMetaMain = (method, path, data = null, needAuth = true) =>
  req(
    method,
    `${envChangeType("REACT_APP_PROTOCOL")}://${
      envChangeType("REACT_APP_NEW_STRUCTURE", envType.BOOLEAN) ? "" : CURRENCY
    }${envChangeType("REACT_APP_META_MAIN_API")}${path}`,
    data,
    needAuth,
  );
