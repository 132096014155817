import { createContext, useContext, useState } from "react";
import { CustomModal } from "../components";
import { signInUpEnum } from "../enumerations/signInUpEnum";

const ModalContext = createContext({
  openTipsModal: () => {},
  mode: signInUpEnum.SIGN_IN,
  setMode: () => {},
  openSignInUpModal: () => {},
  openSignInUp: false,
});

export const ModalContextProvider = ({ children }) => {
  const [openTips, setOpenTips] = useState(false);
  const [props, setProps] = useState({ title: "", body: "" });

  const [openSignInUp, setOpenSignInUp] = useState(false);
  const [mode, setMode] = useState(signInUpEnum.SIGN_IN);

  const openTipsModal = ({
    body,
    isHtml = false,
    needCancel = false,
    onOk = () => {},
    title = "",
  }) => {
    setOpenTips(true);
    setProps({ body, isHtml, needCancel, onOk, title });
  };

  const openSignInUpModal = (_mode) => {
    setOpenSignInUp(true);
    setMode(_mode);
  };

  return (
    <ModalContext.Provider
      value={{ openTipsModal, mode, setMode, openSignInUpModal, openSignInUp }}
    >
      {children}
      <CustomModal open={openTips} setOpen={setOpenTips} props={props} />
    </ModalContext.Provider>
  );
};
export const useModal = () => {
  const Value = useContext(ModalContext);
  return Value;
};
