import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { moneyFormat } from "../../../../plugins/numberFormat";
import { envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";

const WelcomePanel = ({ account = "Please Sign In", balance = 0 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.title}>{t("normal.welcome")}</div>
      <div className={styles.content}>{account}</div>
      <div className={styles.title}>{t("dashboard.side.balance")}</div>
      <div className={styles.content}>{`${envChangeType(
        "REACT_APP_DEFAULT_CURRENCY",
      )} ${moneyFormat(balance)}`}</div>
      <div className={styles.buttonContainer}>
        <Button ghost className={styles.button} onClick={() => navigate("/deposit")}>
          {t("normal.recharge")}
        </Button>
        <Button ghost className={styles.button} onClick={() => navigate("/withdraw")}>
          {t("normal.withdraw")}
        </Button>
      </div>
    </div>
  );
};

export default WelcomePanel;
