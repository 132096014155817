import React from "react";

import { useLanguage } from "../../context/LangContext";
import LangSelector from "./components/LangSelector";
import styles from "./style.module.scss";

function LangSelectorPopover({ changeStyle, customContainerStyle = "", showIcon = true }) {
  const { showPopover, getLanguage } = useLanguage();

  return (
    <LangSelector>
      <div className={`${showPopover ? styles.active : ""} ${customContainerStyle}`}>
        {showIcon ? (
          <div className={`${changeStyle ? styles.changeStyleGroup : ""}`}>
            <div className={`${styles.imgContainer}`}>
              <img src={`/images/utils/langIcons/${getLanguage()}.png`} alt={getLanguage()} />
            </div>
          </div>
        ) : null}
      </div>
    </LangSelector>
  );
}

export default LangSelectorPopover;
