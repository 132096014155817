import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComputerButton, CustomTable, FillModal, NormalTitle, RecordCards } from "../../components";
import { useMobile } from "../../context/MobileContext";
import { formatDate, getNextPageParam } from "../../plugins/utils";
import { useGetMessages, useGetMessagesInfinite, usePostMessages } from "./hooks/message.hooks";
import styles from "./style.module.scss";

const Message = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const [openDetail, setOpenDetail] = useState(false);
  const [detailData, setDetailData] = useState({});

  const { data, refetch, isLoading, isFetching } = useGetMessages({
    options: { enabled: !isMobile },
    params: {},
  });
  const {
    data: messagesInfinite,
    isFetching: isFetchingMessagesInfinite,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMessagesInfinite({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      page_size: 20,
    },
  });
  const { mutate: read } = usePostMessages();

  const handleOpenRead = (record) => {
    setOpenDetail(true);
    setDetailData({
      title: record?.title,
      content: record?.content,
      isHtml: true,
      buttonText: t("normal.close"),
      showCloseIcon: true,
    });
  };

  const handleOpenUnread = (record) => {
    setOpenDetail(true);
    setDetailData({
      title: record?.title,
      content: record?.content,
      isHtml: true,
      buttonText: t("normal.close"),
      showCloseIcon: true,
    });

    read(
      {
        params: { id: record?.id },
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {},
      },
    );
  };

  const columns = [
    {
      title: t("message.table.date.title"),
      dataIndex: "createtime",
      key: "createtime",
      render: (dom) => formatDate(dom, "YYYY-MM-DD HH:mm"),
    },
    {
      title: t("message.table.title.title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("message.table.detail.title"),
      key: "detail",
      align: "center",
      render: (_, record) => (
        <ComputerButton
          record={record}
          onClickRead={() => handleOpenRead(record)}
          onClickUnRead={() => handleOpenUnread(record)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!data) return;
    console.log("messageData", data);
  }, [data]);

  return (
    <div className={styles.message}>
      <NormalTitle title={t("message.title")} />
      {isMobile ? (
        <RecordCards
          data={messagesInfinite}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          loading={isFetchingMessagesInfinite}
          columns={columns}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          loading={isLoading || isFetching}
          pagination={false}
        />
      )}
      <FillModal open={openDetail} data={detailData} setOpen={setOpenDetail} />
    </div>
  );
};

export default Message;
