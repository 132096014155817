import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlogCards, NormalTitle } from "../../components";
import NoData from "../../components/NoData";
import { useLoading } from "../../context/LoadingContext";
import { useGetTutorial } from "./hooks/index.hook";
import styles from "./style.module.scss";

const Tutorial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setMainLoading } = useLoading();

  const { data, isFetching } = useGetTutorial({
    options: {},
    params: {
      type: 5,
    },
  });

  const handleReadMore = (targetData) => {
    navigate(`/tutorial/${targetData.id}`, { state: targetData });
  };

  useEffect(() => {
    setMainLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!data) return;
    console.log("tutorialData", data);
  }, [data]);

  return (
    <div className={styles.tutorial}>
      <NormalTitle title={t("normal.tutorial")} />
      {data?.length > 0 ? (
        <BlogCards data={data} handleReadMore={handleReadMore} />
      ) : (
        <NoData title={t("normal.noData")} />
      )}
    </div>
  );
};

export default Tutorial;
