import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMobile } from "../../../../context/MobileContext";
import styles from "./style.module.scss";
import "./style.scss";

const Carousel = ({ data }) => {
  const { isMobile } = useMobile();
  const fakeImgEnum = [
    { src: "/images/fake/fakeImg1.png", alt: "img1" },
    { src: "/images/fake/fakeImg2.png", alt: "img2" },
    { src: "/images/fake/fakeImg3.png", alt: "img3" },
    { src: "/images/fake/fakeImg4.png", alt: "img4" },
    { src: "/images/fake/fakeImg5.png", alt: "img5" },
  ];

  return (
    <div className={`${styles.carousel} top-area-carousel`}>
      {data?.slide.length > 0 && (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={isMobile ? 1 : 2}
          // loopedslides={data?.slide.length || 3}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={false}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          {[...data?.slide.slice(-1), ...data?.slide, ...data?.slide.slice(0, -1)].map(
            (item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={item.slideimage} alt={item.name} />
                </SwiperSlide>
              );
            },
          )}
        </Swiper>
      )}
    </div>
  );
};

export default Carousel;
