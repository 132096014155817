import { Button } from "antd-mobile";
import "./style.scss";

const CustomButton = ({
  block = true,
  loading = false,
  onClick = () => {},
  buttonStyle = "",
  disabled = false,
  style = {},
  children,
  type,
}) => {
  return (
    <Button
      disabled={disabled}
      style={style}
      className={`custom-button ${buttonStyle}`}
      block={block}
      loading={loading}
      onClick={onClick}
      type={type}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
