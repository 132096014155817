import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomRadio } from "../../components";
import { useLoading } from "../../context/LoadingContext";
import { useMobile } from "../../context/MobileContext";
import { getNextPageParam } from "../../plugins/utils";
import { MyProject as MyProjectComponent } from "./components";
import { useGetInfiniteMyProjectList, useGetMyProjectList } from "./hooks/index.hook";
import styles from "./style.module.scss";

const MyProject = () => {
  const { t } = useTranslation();
  const { isMobile } = useMobile();
  const { setMainLoading } = useLoading();
  const items = [
    { value: "myProject", text: t("normal.myProject") },
    { value: "recycled", text: t("normal.recycled") },
  ];
  const statusEnum = {
    [items[0].value]: 1,
    [items[1].value]: 2,
  };
  const [radioValue, setRadioValue] = useState(items[0].value);
  const [status, setStatus] = useState(statusEnum[items[0].value]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isFetching } = useGetMyProjectList({
    options: {
      enabled: !isMobile,
    },
    params: {
      // status,
      date: "All time",
      facility_type_id: 0,
      page_size: 20,
      page: currentPage,
    },
  });

  const {
    data: infiniteData,
    isFetching: isFetchingInfiniteData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetInfiniteMyProjectList({
    options: {
      getNextPageParam,
      enabled: isMobile,
    },
    params: {
      // status,
      date: "All time",
      facility_type_id: 0,
      page_size: 20,
    },
  });

  const renderContent = () => {
    switch (radioValue) {
      case "myProject":
        return (
          <MyProjectComponent
            data={isMobile ? infiniteData : data}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            loading={isFetchingInfiniteData}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
        return <MyProjectComponent data={data} />;
    }
  };

  const handleClickRadio = (e) => {
    setRadioValue(e.target.value);
    setStatus(statusEnum[e.target.value]);
  };

  useEffect(() => {
    setMainLoading(false);
  }, []);

  return (
    <div className={styles.myProject}>
      <div className={styles.radio}>
        <CustomRadio
          items={items}
          defaultValue={items[0].value}
          value={radioValue}
          onClick={(e) => handleClickRadio(e)}
        />
      </div>
      <Spin spinning={isFetching}>{renderContent()}</Spin>
    </div>
  );
};

export default MyProject;
