import { Autoplay, EffectCoverflow, EffectCreative, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./style.module.scss";
import "./style.scss";

const HorizontalSwiper = ({ item, data }) => {
  const items = (props) => {
    return props.data.map((_item, index) => (
      <SwiperSlide key={index}>
        <div className={styles.swiperSlide}>{props.slideContent(_item)}</div>
      </SwiperSlide>
    ));
  };

  return (
    <div className={styles.horizontalSwiper}>
      {data && data.fundList?.length > 0 && (
        <Swiper
          ref={item.ref}
          navigation={false}
          modules={[EffectCoverflow, EffectCreative, Autoplay, Pagination]}
          className="custom-swiper"
          spaceBetween={10}
          slidesPerView={"auto"}
          speed={10000}
          dir={item.dir}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          pagination={false}
          loop={true}
          // initialSlide={5}
          direction={"horizontal"}
        >
          {items(item)}
        </Swiper>
      )}
    </div>
  );
};

export default HorizontalSwiper;
