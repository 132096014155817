import { UserContainer } from "./components";
import { UserContextProvider } from "./context/UserContext";

const User = () => {
  return (
    <UserContextProvider>
      <UserContainer />
    </UserContextProvider>
  );
};

export default User;
