import { useTranslation } from "react-i18next";
import { ProjectCards } from "../../../../components";
import NoData from "../../../../components/NoData";
import styles from "./style.module.scss";

const PreferredProject = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.preferredProject}`}>
      {data?.s.length >= 0 ? (
        <ProjectCards data={data?.s} />
      ) : (
        <NoData title={t("normal.noData")} />
      )}
    </div>
  );
};

export default PreferredProject;
