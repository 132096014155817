import { Flex, Image } from "antd";
import { channelEnum } from "../../../../../../enumerations/channelEnum";
import styles from "./style.module.scss";

const NormalImages = () => {
  return (
    <Flex wrap gap="middle" justify="center" className={styles.normalImages}>
      {Object.keys(channelEnum).map((item, index) => {
        if (!channelEnum[item].showAtHome) return null;
        return (
          <Image
            key={index}
            src={channelEnum[item].src}
            alt={channelEnum[item].name}
            preview={false}
            className={styles.img}
          />
        );
      })}
    </Flex>
  );
};

export default NormalImages;
