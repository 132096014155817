import { CopyOutlined } from "@ant-design/icons";
import { ProForm } from "@ant-design/pro-components";
import { Col, Row, Spin } from "antd";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CustomInput } from "../../../../components";
import { useGetInvitation } from "../../../../hooks/user/user.hook";
import { copyText, envChangeType } from "../../../../plugins/utils";
import styles from "./style.module.scss";
import "./style.scss";

const VIPPanel = ({ userInfo }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const {
    data: invitation,
    isLoading: isInvitationLoading,
    isFetching: isInvitationFetching,
  } = useGetInvitation({ options: {} });

  useEffect(() => {
    console.debug("invitation", invitation);
    if (!invitation) return;
    formRef.current.setFieldsValue({
      code: invitation?.code,
      url: invitation?.invurl,
      username: userInfo?.mobile,
    });
  }, [invitation, userInfo]);

  return (
    <Spin spinning={isInvitationFetching}>
      <div
        className={`${styles.vipContainer} vip-container`}
        style={{
          background: `url("/images/${envChangeType(
            "REACT_APP_PRODUCTION_NAME_DEV",
          )}/dashboard/background.svg") no-repeat center 40px / cover`,
        }}
      >
        <div className={styles.title}>{t("dashboard.vip.level", { level: userInfo?.level })}</div>
        <ProForm formRef={formRef} submitter={false}>
          <Row gutter={[13, 38]}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <CustomInput
                type="text"
                name={"username"}
                placeholder={""}
                readOnly
                disabled
                label={t("dashboard.vip.username")}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <CustomInput
                type="text"
                name={"code"}
                placeholder={""}
                readOnly
                disabled
                label={t("dashboard.vip.referral")}
                addonAfter={
                  <CopyOutlined
                    className={styles.copy}
                    onClick={() => copyText(invitation?.code)}
                  />
                }
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <CustomInput
                type="text"
                name={"url"}
                placeholder={""}
                readOnly
                disabled
                label={t("dashboard.vip.agent")}
                addonAfter={
                  <CopyOutlined
                    className={styles.copy}
                    onClick={() => copyText(invitation?.invurl)}
                  />
                }
              />
            </Col>
          </Row>
        </ProForm>
      </div>
    </Spin>
  );
};

export default VIPPanel;
