import { useRef } from "react";
import styles from "./style.module.scss";

import { Image } from "antd";
import { envChangeType, envType } from "../../../../plugins/utils";
import { useApiData } from "../../context/ApiDataContext";
import HorizontalSwiper from "./components/HorizontalSwiper";
import TableSwiper from "./components/TableSwiper";

const Transaction = () => {
  const refTop = useRef();
  const refBottom = useRef();

  const { data } = useApiData();

  const swiperEnum = [
    {
      key: 0,
      data: data?.fundList.slice(0, Math.floor(data?.fundList.length / 2)),
      ref: refTop,
      dir: "",
      slideContent: (itemData) => (
        <>
          <Image src={itemData?.img} alt="img" preview={false} className={styles.img} />
          <div className={styles.content}>{itemData?.content}</div>
          <div>{itemData?.username}</div>
        </>
      ),
    },
    {
      key: 1,
      data: data?.fundList.slice(Math.floor(data?.fundList.length / 2)).reverse(),
      ref: refBottom,
      dir: "rtl",
      slideContent: (itemData) => (
        <>
          <div>{itemData?.username}</div>
          <div className={styles.content}>{itemData?.content}</div>
          <Image src={itemData?.img} alt="img" preview={false} className={styles.img} />
        </>
      ),
    },
  ];

  const renderSwiperArea = () => {
    if (envChangeType("REACT_APP_HOME_TRANSACTION_TABLE", envType.BOOLEAN)) {
      return <TableSwiper data={data} />;
    } else {
      return swiperEnum.map((item, index) => {
        return <HorizontalSwiper key={index} item={item} data={data} />;
      });
    }
  };

  return <div className={`${styles.swiperArea} transaction-swiper-area`}>{renderSwiperArea()}</div>;
};

export default Transaction;
