import { ProgressCircle } from "antd-mobile";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const Affiliate = ({ data }) => {
  const { t } = useTranslation();

  const progressEnum = [
    { percent: data.prent_point, subTitle: "project.card.affiliate.level1" },
    {
      percent: data.prent_point === 0 ? 0 : data.prent_point - 1,
      subTitle: "project.card.affiliate.level2",
    },
  ];

  return (
    <div className={styles.affiliate}>
      <div className={styles.title}>{t("project.card.affiliate")}</div>
      <div className={styles.progressContainer}>
        {progressEnum.map((item, index) => {
          return (
            <div key={index}>
              <ProgressCircle
                percent={data.prent_point}
                style={{ "--track-width": "6px", "--fill-color": "var(--project_affiliate)" }}
              >
                <span className={styles.small}>{item.percent + "%"}</span>
              </ProgressCircle>
              <div className={styles.subTitle}>{t(item.subTitle)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Affiliate;
