import { CloseOutline } from "antd-mobile-icons";
import React, { useMemo } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import styles from "./style.module.scss";

const CloseCountdown = ({ visible = true, timer, onClose }) => {
  const getTimeSeconds = (time) => ((timer - time + 100) | 0).toString().substring(1, 3);

  const renderContent = ({ elapsedTime }) => {
    // console.log(timer, elapsedTime, getTimeSeconds(elapsedTime));
    return <CloseOutline className={styles.closeBtn} onClick={() => onClose()} />;
  };

  const render = useMemo(() => {
    if (!visible || timer === undefined || timer <= 0) return null;
    return (
      <div className={styles.countdownContainer}>
        <div className={styles.countdown}>
          <CountdownCircleTimer
            isPlaying={visible}
            size={35}
            // initialRemainingTime={timer}
            strokeLinecap={"square"}
            strokeWidth={3}
            duration={timer}
            trailColor=""
            colors={"#999999"}
            // colorsTime={}
            rotation={"counterclockwise"}
          >
            {renderContent}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  }, [visible, timer]);
  return render;
};

export default CloseCountdown;
