import { ProForm } from "@ant-design/pro-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  CustomInput,
  NormalContainer,
  NormalTitle,
  SettingArea,
  toast,
} from "../../../../../../components";
import { useModal } from "../../../../../../context/ModalContext";
import { channelEnum } from "../../../../../../enumerations/channelEnum";
import { apiCodeEnum } from "../../../../../../enumerations/signInUpEnum";
import { moneyFormat } from "../../../../../../plugins/numberFormat";
import {
  checkInputHasNull,
  envChangeType,
  middleEllipsis,
  reset,
} from "../../../../../../plugins/utils";
import { usePostWithdraw } from "../../../../hooks/index.hook";
import styles from "./style.module.scss";

const Withdraw = ({ data }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const navigate = useNavigate();
  const { openTipsModal } = useModal();
  const [fee, setFee] = useState(NaN);
  const [actualMoney, setActualMoney] = useState("0");
  const [disabled, setDisabled] = useState(true);
  const [targetChain, setTargetChain] = useState({});
  const [valueEnum, setValueEnum] = useState({});

  const { mutate: Withdraw, isLoading } = usePostWithdraw();

  const settingEnum = [
    {
      label: t("label.handlingFee"),
      value: fee || 0,
    },
    {
      label: t("label.conversionRate"),
      value: t("wallet.recharge.rate", {
        rate: targetChain?.ratio,
        currency: t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
        transformCurrency: targetChain?.name,
      }),
    },
    {
      label: t("label.receivable"),
      value: moneyFormat(actualMoney * targetChain?.ratio, 6, 6),
    },
  ];

  const handleChangeInput = (value = 0) => {
    const newFee = value * (data?.with_free / 100);
    setFee(newFee);
    setActualMoney(formRef?.current?.getFieldValue("number") - newFee);
    formRef.current.setFieldsValue({ fee: newFee });
    checkInputHasNull(formRef, setDisabled);
  };

  const handleChangeMethod = (value) => {
    const matchCoin = data.coins.find((item) => item.name === value);
    const matchWalletAddress = data.wallet_address.find((item) => item.digital_chain === value);
    setTargetChain({ ...matchCoin, walletAddress: matchWalletAddress });
  };

  const handleConfirm = () => {
    const params = formRef.current.getFieldsValue();
    const form = new FormData();
    form.append("chain", targetChain?.name);
    form.append("address", targetChain?.walletAddress?.digital_address);
    form.append("number", params.number);
    form.append("payment_password", params.payment_password);

    Withdraw(
      {
        params: form,
      },
      {
        onSuccess: (success) => {
          const { code, msg } = success?.data;
          if (code === apiCodeEnum.ERROR) {
            openTipsModal({
              body: msg,
            });
            return;
          }
          toast({ content: msg, type: "success" });
          reset(formRef);
          navigate("/my-withdrawals?radioValue=record");
        },
        onError: () => {},
      },
    );
  };

  useEffect(() => {
    if (!data) return;
    if (!data?.digital_address) {
      openTipsModal({
        body: t("wallet.withdraw.noAddress"),
        needCancel: true,
        onOk: () => navigate("/wallet-address?tab=withdrawAddress"),
      });
    }
  }, [data]);

  useEffect(() => {
    if (!data) return;
    console.log(data);
    const { coins } = data;
    data.wallet_address.map((item, index) => {
      if (!item?.digital_chain && !item?.digital_address) return null;
      const target = coins.filter((coin) => coin.name === item.digital_chain);
      setValueEnum((prev) => {
        return {
          ...prev,
          [item.digital_chain]: (
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              {target ? (
                channelEnum[item.digital_chain]?.src ? (
                  <img src={channelEnum[item.digital_chain]?.src} alt="logo" width={20} />
                ) : (
                  <img
                    src={envChangeType("REACT_APP_MOBILE_URL") + target[0].logo}
                    alt="logo"
                    width={20}
                  />
                )
              ) : null}
              {`${item.digital_chain} ${middleEllipsis({
                inputString: item.digital_address,
                minStringLength: 0,
                firstLetterLength: 4,
                secondLetterLength: 4,
                symbol: "****",
              })}`}
            </div>
          ),
        };
      });
      if (index === 0) {
        formRef.current.setFieldValue("method", item.digital_chain);
        handleChangeMethod(item.digital_chain);
      }
      return item;
    });
  }, [data]);

  return (
    <div className={styles.withdraw}>
      <NormalTitle title={t("normal.withdraw")} />
      <NormalContainer>
        <ProForm
          formRef={formRef}
          onFinish={handleConfirm}
          submitter={{
            render: () => (
              <>
                <CustomButton
                  buttonStyle={`${styles.customButtonStyle}`}
                  type={"submit"}
                  loading={isLoading}
                  disabled={disabled}
                >
                  {t("normal.submit")}
                </CustomButton>
                <div
                  className={styles.hint}
                  dangerouslySetInnerHTML={{
                    __html: t("withdraw.hint", {
                      currency: t(`currency.symbol.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`),
                      min: data?.withdraw_lowmoney,
                    }),
                  }}
                />
              </>
            ),
          }}
          className="withdraw-from"
        >
          <div className={styles.selectContainer}>
            <CustomInput
              type="select"
              name="method"
              label={t("label.withdrawal.method")}
              placeholder={t("normal.pleaseEnter", { label: t("label.withdrawAddress.currency") })}
              valueEnum={valueEnum}
              onChange={handleChangeMethod}
            />
            <div
              className={styles.extra}
              onClick={() => navigate("/wallet-address?tab=withdrawAddress")}
            >
              {t("select.extra")}
            </div>
          </div>
          <CustomInput
            label={t("label.amount")}
            name="number"
            placeholder={t("normal.pleaseEnter", { label: t("label.amount") })}
            type="number"
            onChange={handleChangeInput}
            addonAfter={t(`currency.unit.${envChangeType("REACT_APP_DEFAULT_CURRENCY")}`)}
          />
          <CustomInput
            name="payment_password"
            label={t("label.paymentPassword")}
            placeholder={t("normal.pleaseEnter.your", { label: t("label.paymentPassword") })}
            onChange={() => checkInputHasNull(formRef, setDisabled)}
            type="password"
          />
          <SettingArea settingEnum={settingEnum} />
        </ProForm>
      </NormalContainer>
    </div>
  );
};

export default Withdraw;
