import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../components";
import { useLoading } from "../../context/LoadingContext";
import { TeamCollapse } from "./components";
import { useGetTeamList } from "./hooks/index.hook";
import styles from "./style.module.scss";

const MyTeam = () => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetTeamList();
  const { setMainLoading } = useLoading();

  useEffect(() => {
    setMainLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className={styles.myTeam}>
      <NormalTitle title={t("nav.commissions")} />
      <div className={styles.container}>
        <TeamCollapse data={data} />
      </div>
    </div>
  );
};

export default MyTeam;
