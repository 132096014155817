import { Card, Divider, Image } from "antd";
import { RightOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

const BlogCards = ({ data, handleReadMore }) => {
  const { t } = useTranslation();

  const renderCard = (_item) => {
    return (
      <Card
        key={_item?.id}
        cover={
          <div className={styles.imgContainer}>
            <Image src={_item?.article_image} alt="img" preview={false} className={styles.img} />
          </div>
        }
        className={styles.card}
      >
        <div className={styles.title}>{_item?.title}</div>
        <div className={styles.footer}>
          <Divider className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.data}>{_item?.create_date}</div>
            <div className={styles.btnText} onClick={() => handleReadMore(_item)}>
              {t("normal.readMore.upper")}
              <RightOutline />
            </div>
          </div>
        </div>
      </Card>
    );
  };

  return <div className={styles.cards}>{data.map((item) => renderCard(item))}</div>;
};

export default BlogCards;
