import { PrivacyAndTerms } from "../../components";
import { useGetData } from "../../hooks/indexData/indexData.hook";

const Terms = () => {
  const { data: indexData } = useGetData();
  return (
    <PrivacyAndTerms
      title={"terms.title"}
      content={"terms.content"}
      email={indexData?.company_info?.email}
    />
  );
};

export default Terms;
