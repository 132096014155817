import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NormalTitle } from "../../components";
import NoData from "../../components/NoData";
import { useLoading } from "../../context/LoadingContext";
import { useGetData } from "../../hooks/indexData/indexData.hook";
import styles from "./style.module.scss";
import "./style.scss";

const FAQ = () => {
  const { t } = useTranslation();
  const { setMainLoading } = useLoading();
  const [faq, setFaq] = useState([]);

  const { data, isFetching } = useGetData();

  useEffect(() => {
    setMainLoading(isFetching);
    if (!data) return;

    const newFaq = (
      Array.isArray(data.chat_list) ? data.chat_list : Object.values(data.chat_list)
    ).map((item) => {
      return {
        key: item.id,
        label: item.title,
        children: <div dangerouslySetInnerHTML={{ __html: item.article_content }} />,
      };
    });
    setFaq(newFaq);
  }, [data, isFetching]);

  useEffect(() => {
    if (!data) return;
    console.log("faqData", data);
  }, [data]);

  return (
    <div className={styles.faq}>
      <NormalTitle title={t("faq.title")} />
      {faq?.length > 0 ? (
        <div className={`faq`}>
          <Collapse
            accordion
            size="large"
            bordered={false}
            items={faq}
            defaultActiveKey={["1"]}
            expandIconPosition="end"
            expandIcon={(e) => {
              return e.isActive ? (
                <MinusCircleFilled className="custom-expand-icon" />
              ) : (
                <PlusCircleFilled className="custom-expand-icon" />
              );
            }}
          />
        </div>
      ) : (
        <NoData title={t("normal.noData")} />
      )}
    </div>
  );
};

export default FAQ;
